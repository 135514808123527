// PaymentForm.js
import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Make sure you've installed react-router-dom
import './KujiPaymentForm.css';
import NavBar from './NavBar';
import LoadingSpinner from './LoadingSpinner'; // Ensure you have this component created
import { trackEvent } from './utils/analytics';

function KujiPaymentForm() {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate(); // Hook for programmatic navigation

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
  const selectedProduct = useSelector((state) => state.product.selectedProduct);
  const ticketsBought = useSelector((state) => state.payment.ticketsBought);
  const orderId = useSelector((state) => state.payment.orderId);

  const [isLoading, setIsLoading] = useState(false); // New loading state
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFormLoading, setIsFormLoading] = useState(true); // New loading state
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentRequestAvailable, setPaymentRequestAvailable] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [totalCost, setTotalCost] = useState(null);
  const [isCostLoading, setIsCostLoading] = useState(true);

  useEffect(() => {
    const fetchReservation = async () => {
      const response = await fetch(`${backendUrl}/api/order/${orderId}/reservation`);
      const data = await response.json();
      const expiresAt = new Date(data.expiresAt);
  
      const interval = setInterval(() => {
        const remaining = Math.max(0, expiresAt - new Date());
        setTimeLeft(Math.ceil(remaining / 1000));
        if (remaining <= 0) {
          clearInterval(interval);
        }
      }, 1000);
      return () => clearInterval(interval);
    };
    console.log("fetching reservation")
    fetchReservation();
  }, [orderId]);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        setIsCostLoading(true);
        const response = await fetch(`${backendUrl}/api/order/get-total/${orderId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include'
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch order details');
        }
        
        const orderData = await response.json();
        setTotalCost(orderData.totalCost / 100); // Convert cents to dollars
      } catch (error) {
        console.error('Error fetching order details:', error);
      } finally {
        setIsCostLoading(false);
      }
    };

    const initializePaymentRequest = async () => {
      if (stripe) {
        await fetchOrderDetails();

        sessionStorage.setItem('orderState', JSON.stringify({
          orderId: orderId,
          ticketsBought: ticketsBought,
          selectedProduct: selectedProduct
        }));

        const pr = stripe.paymentRequest({
          country: 'SG',
          currency: 'sgd',
          total: {
            label: 'Total',
            amount: totalCost ? Math.round(totalCost * 100) : 0, // Ensure totalCost is in cents
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });
    
        pr.canMakePayment().then(result => {
          if (result && result.applePay) {
            setPaymentRequest(pr);
            setPaymentRequestAvailable(true);
          }
        }).catch(error => {
          console.error("Payment request error: ", error);
        });
    
        pr.on('token', async (e) => {
          // Handle the submission of payment details
          const { token, error } = e;
    
          // Process the payment using the token.id...
          // Similar to handling confirmation in your existing handleSubmit
    
          if (error) {
            console.log(`Payment failed: ${error.message}`);
            e.complete('fail');
          } else {
            console.log('Payment succeeded!');
            // Navigate or update state as needed
            // const response = await fetch(`${backendUrl}/api/draw-and-update-order/${orderId}`, {
            //   method: 'POST',
            //   headers: {
            //     'Content-Type': 'application/json',
            //   },
            //   credentials: 'include'
            // });
        
            // if (!response.ok) {
            //   throw new Error('Network response was not ok');
            // }

            e.complete('success');

            navigate(`/draw`); // Adjust as per your route
          }
        });
      }
    }

    initializePaymentRequest();

  }, [stripe, navigate, backendUrl, orderId, ticketsBought, selectedProduct, totalCost]);
  

  useEffect(() => {
    if (stripe) {
      // Assume if stripe is loaded, elements might be ready soon after
      // This is a simplification and might not accurately reflect the loaded state of the PaymentElement
      const timer = setTimeout(() => setIsFormLoading(false), 1000); // Wait for 1 second before assuming elements are loaded
      return () => clearTimeout(timer);
    }
  }, [stripe]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.setItem('orderState', JSON.stringify({
      orderId: orderId,
      ticketsBought: ticketsBought,
      selectedProduct: selectedProduct
    }));
    setIsLoading(true); // Start loading

    try {
      const response = await fetch(`${backendUrl}/api/kuji/check-tickets`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}` // Ensure yourToken is the JWT token received after login
        },
        body: JSON.stringify({
          setId: selectedProduct.setId,
          ticketsBought: ticketsBought
        })
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Error checking ticket availability');
      }

      if (!data.success) {
        setErrorMessage(data.message);
        setShowErrorPopup(true);
        setIsLoading(false);
        return;
      }
  
      if (!stripe || !elements) {
        setIsLoading(false);
        return;
      }
    
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/draw`,
        },
        redirect: 'if_required',
      });

      if (result.error) {
        // Track failed purchase
        trackEvent(
          'Purchase',
          'Purchase Failed',
          selectedProduct.name,
          totalCost
        );
        setIsLoading(false);
        console.log(result.error.message);
      } else {
        // The payment has been processed!
        if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
          // Track successful purchase
          trackEvent(
            'Purchase',
            'Purchase Complete',
            selectedProduct.name,
            totalCost
          );
          handlePaymentStatusCheck();
        }
      } 
    } catch (error) {
      // Track error in purchase
      trackEvent(
        'Purchase',
        'Purchase Error',
        selectedProduct?.name || 'Unknown Product',
        totalCost
      );
      setIsLoading(false);
      console.log('Error: ', error);
    }
  };

  const handlePaymentStatusCheck = async () => {
    try {
        const response = await fetch(`${backendUrl}/api/order/status/${orderId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        const data = await response.json();

        if (data.paymentStatus === 'succeeded') {
            navigate(`/draw`);
        } else {
            console.log('Payment not yet completed. Retrying...');
            setTimeout(handlePaymentStatusCheck, 2000); // Retry after 2 seconds
        }
    } catch (error) {
        console.error('Error checking payment status:', error);
    }
  };


  const goToProducts = () => {
    const targetUrl = '/ichiban-kuji-products';
    window.location.href = `${window.location.origin}${targetUrl}`;
  }

  useEffect(() => {
    if (showErrorPopup) {
      console.log("Error popup is now visible:", errorMessage);
      // Any additional actions when the popup is shown
    } else {
      console.log("Error popup is not visible:", errorMessage);
    }
  }, [showErrorPopup, errorMessage]);

  return (
    <div>
        <NavBar />
        {(isLoading || isCostLoading) && (
            <div className="spinner-overlay">
              <LoadingSpinner />
              <p style={{color: "white"}}>
                {isLoading ? "Processing your payment..." : "Loading payment details..."}
              </p>
            </div>
        )}
        <div className="gradient-background">
            <div className="kuji-payment-container">
                <div className="kuji-payment-info">
                    <h2 className="kuji-payment-details">Payment Details</h2>
                    {selectedProduct && (
                        <>
                            <img className="kuji-payment-product-image" src={`${backendUrl}/api/images/${selectedProduct.image}`} alt={selectedProduct.name} />
                            <p className='kuji-payment-product-name p-text'>{selectedProduct.name}</p>
                            <p className='kuji-payment-tickets-bought p-text'>Tickets: {ticketsBought}</p>
                            {!isCostLoading && totalCost !== null ? (
                                <p className='kuji-payment-cost p-text'>Total Cost: ${totalCost.toFixed(2)}</p>
                            ) : (
                                <p className='kuji-payment-cost p-text'>Calculating total...</p>
                            )}
                        </>
                    )}
                </div>
                {showErrorPopup ? (
                  <div className="tickets-error-popup-overlay popup-overlay">
                    <div className="tickets-error-popup-content">
                      <p>{errorMessage}</p>
                      <button onClick={goToProducts}>Go</button>
                    </div>
                  </div>
                ) : (
                  <>
                    {timeLeft > 0 ? (
                      <p>You have {timeLeft} seconds to complete your payment!</p>
                    ) : (
                      <p>Your reservation has expired. Please refresh and try again.</p>
                    )}
                  </>
                )}
                <div className="kuji-payment-form">
                  {/* {paymentRequestAvailable && (
                    <PaymentRequestButtonElement options={{ paymentRequest }} />
                  )} */}
                  <form className="kuji-stripe-payment-element-form" onSubmit={handleSubmit}>
                    <PaymentElement />
                    <button type="submit" className="kuji-pay-button" disabled={!stripe || isLoading}>
                      {isLoading ? 'Processing...' : 'Pay'}
                    </button>
                  </form>
                </div>
            </div>
        </div>
    </div>
  );
}

export default KujiPaymentForm;