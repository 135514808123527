import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './SelfCollectionModal.css';
import LoadingSpinner from './LoadingSpinner';

function SelfCollectionModal({ selectedItems, onClose, onConfirm }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Update available time slots when date changes
  useEffect(() => {
    if (selectedDate) {
      const day = selectedDate.getDay();
      if (day === 3) { // Wednesday
        setAvailableTimeSlots([
          '2:00 PM',
          '3:00 PM',
          '4:00 PM',
          '5:00 PM',
          '6:00 PM'
        ]);
      } else if (day === 5) { // Friday
        setAvailableTimeSlots([
          '6:00 PM',
          '7:00 PM',
          '8:00 PM',
          '9:00 PM'
        ]);
      } else {
        setAvailableTimeSlots([]);
      }
      setSelectedTimeSlot(null); // Reset time slot when date changes
    }
  }, [selectedDate]);

  // Filter for only Wednesdays and Fridays within next 2 weeks
  const filterDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const day = date.getDay();
    const twoWeeksFromNow = new Date();
    twoWeeksFromNow.setDate(today.getDate() + 14);
    
    return (
      date >= today &&
      date <= twoWeeksFromNow &&
      (day === 3 || day === 5) // 3 is Wednesday, 5 is Friday
    );
  };

  const handleConfirm = async () => {
    if (!selectedDate || !selectedTimeSlot) {
      setError('Please select both a date and time slot');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Combine date and time slot for the backend
      const collectionDateTime = new Date(selectedDate);
      const [hours, minutes] = selectedTimeSlot.match(/(\d+):(\d+)/).slice(1);
      collectionDateTime.setHours(
        parseInt(hours) + (selectedTimeSlot.includes('PM') && hours !== '12' ? 12 : 0),
        parseInt(minutes)
      );

      await onConfirm({
        items: selectedItems,
        collectionDate: collectionDateTime
      });
      
      onClose();
    } catch (error) {
      console.log("error:", error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="self-collection-modal popup-overlay">
      <div className="self-collection-modal-content">
        
        <div className="self-collection-calendar">
          <h2 className="self-collection-select-date-title">Select Collection Date</h2>
          <p className="collection-info">
            Available for collection on:
            <br />- Wednesdays (2 PM - 6 PM)
            <br />- Fridays (6 PM - 9 PM)
          </p>
          <DatePicker
            selected={selectedDate}
            onChange={date => setSelectedDate(date)}
            filterDate={filterDate}
            minDate={new Date()}
            placeholderText="Select a date"
            className="date-picker"
            dateFormat="MMMM d, yyyy"
          />
        </div>

        {selectedDate && availableTimeSlots.length > 0 && (
          <div className="time-slots">
            <h3>Select Time Slot</h3>
            <div className="time-slots-grid">
              {availableTimeSlots.map((slot) => (
                <button
                  key={slot}
                  className={`time-slot ${selectedTimeSlot === slot ? 'selected' : ''}`}
                  onClick={() => setSelectedTimeSlot(slot)}
                >
                  {slot}
                </button>
              ))}
            </div>
          </div>
        )}

        {error && <div className="self-collection-error-message">{error}</div>}

        <div className="self-collection-selected-items">
          <h3 className="self-collection-items-title">Items for Collection</h3>
          {selectedItems.map((item, index) => (
            <div key={index} className="self-collection-selected-item">
              <span>{item.setName} - {item.prizeName} (Qty: {item.quantity})</span>
            </div>
          ))}
        </div>

        <div className="self-collection-modal-actions">
          <button 
            className="self-collection-cancel-button" 
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </button>
          <button 
            className="self-collection-confirm-button" 
            onClick={handleConfirm}
            disabled={isLoading || !selectedDate || !selectedTimeSlot}
          >
            {isLoading ? <LoadingSpinner /> : 'Confirm Collection'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SelfCollectionModal; 