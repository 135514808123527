import React from 'react';
import './TokenConfirmationPopup.css';
import tokenIcon from './surprize_token.png';


function TokenConfirmationPopup({ totalTokens, onConfirm, onCancel }) {
  return (
    <div className="token-confirmation-popup-overlay popup-overlay">
      <div className="token-confirmation-popup-content">
        <h2>Confirm Purchase</h2>
        <p>Are you sure you want to use <strong>{totalTokens}</strong><img src={tokenIcon} alt="Token" className="confirmation-token-icon" /> for this purchase?</p>
        <div className="token-confirmation-popup-buttons">
          <button className="token-confirmation-button" onClick={onConfirm}>Yes</button>
          <button className="token-cancel-button" onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default TokenConfirmationPopup;