import React from 'react';
import './SelfCollectPopup.css'; // Style this popup accordingly

function SelfCollectPopup({ onConfirm, onCancel }) {
  return (
    <div className="selfcollect-popup-overlay popup-overlay">
      <div className="selfcollect-popup-content">
        <h3>Confirm Self-Collect</h3>
        <p>Are you sure you have self-collected this order?</p>
        <div className="selfcollect-popup-buttons">
          <button className="selfcollect-confirm-button" onClick={onConfirm}>Yes</button>
          <button className="selfcollect-cancel-button" onClick={onCancel}>No</button>
        </div>
      </div>
    </div>
  );
}

export default SelfCollectPopup;