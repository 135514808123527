import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import './AdminShipments.css';
import LoadingSpinner from './LoadingSpinner';

const AdminShipments = () => {
  const [shipments, setShipments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [exporting, setExporting] = useState(false);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      fetchShipments(currentPage, searchTerm, statusFilter);
    }, searchTerm ? 300 : 0);

    return () => clearTimeout(timeoutId);
  }, [currentPage, searchTerm, statusFilter]);

  const fetchShipments = async (page, search, status) => {
    try {
      const queryParams = new URLSearchParams({
        page,
        search: search || '',
        status: status || 'all'
      });

      const response = await fetch(
        `${backendUrl}/api/admin/shipments?${queryParams}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
          }
        }
      );

      if (response.status === 401 || response.status === 403) {
        navigate('/admin/login');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to fetch shipments');
      }

      const data = await response.json();
      setShipments(data.shipments);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching shipments:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setLoading(true);
    setCurrentPage(newPage);
  };

  const debouncedSearch = (value) => {
    setLoading(true);
    setCurrentPage(1);
    setSearchTerm(value);
  };

  const handleStatusFilterChange = (value) => {
    setLoading(true);
    setStatusFilter(value);
    setCurrentPage(1);
  };

  const getTrackingUrl = (awbNumber) => {
    return `https://app.easyparcel.com/sg/en/track/details/?awb=${awbNumber}&courier=TracX%20Logis`;
  };

  const handleExportPendingShipments = async () => {
    try {
      setExporting(true);
      const response = await fetch(
        `${backendUrl}/api/admin/shipments/export-pending`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
          }
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 404) {
          alert(errorData.message); // This will show "No pending shipments with AWB links found"
          return;
        }
        throw new Error('Failed to export shipments');
      }

      // Get the PDF blob from the response
      const blob = await response.blob();
      
      // Create a download link and trigger it
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'pending-shipments.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      // Refresh the shipments list
      fetchShipments(currentPage, searchTerm, statusFilter);
    } catch (error) {
      console.error('Error exporting shipments:', error);
      alert('Failed to export shipments. Please try again.');
    } finally {
      setExporting(false);
    }
  };

  if (error) return <div className="admin-shipments-error">Error: {error}</div>;

  console.log(shipments);

  return (
    <div>
      <NavBar />
      <div className="admin-shipments-container">
        <div className="admin-shipments-header">
          <div className="admin-shipments-header-left">
            <button 
              onClick={() => navigate('/admin/menu')} 
              className="admin-shipments-back-button"
            >
              <i className="fas fa-arrow-left"></i> Back to Menu
            </button>
            <h1>Shipments</h1>
          </div>
          <div className="admin-shipments-header-buttons">
            <button 
              onClick={handleExportPendingShipments}
              className="admin-shipments-export-button"
              disabled={exporting}
            >
              {exporting ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> Exporting...
                </>
              ) : (
                <>
                  <i className="fas fa-file-export"></i> Export Pending Labels
                </>
              )}
            </button>
          </div>
        </div>

        <div className="admin-shipments-controls">
          <div className="admin-shipments-search">
            <div className="admin-shipments-search-container">
              <i className="fas fa-search admin-shipments-search-icon"></i>
              <input
                type="text"
                placeholder="Search by Username or ParcelContent"
                value={searchTerm}
                onChange={(e) => debouncedSearch(e.target.value)}
                className="admin-shipments-search-input"
              />
              {searchTerm && (
                <button
                  className="admin-shipments-clear-search-button"
                  onClick={() => debouncedSearch('')}
                >
                  <i className="fas fa-times"></i>
                </button>
              )}
            </div>
          </div>
          
          <div className="admin-shipments-filters">
            <div className="admin-shipments-filter-group">
              <label>Status:</label>
              <select 
                value={statusFilter}
                onChange={(e) => handleStatusFilterChange(e.target.value)}
                className="admin-shipments-filter-select"
              >
                <option value="all">All Status</option>
                <option value="pending">Pending</option>
                <option value="shipped">Shipped</option>
              </select>
            </div>
          </div>
        </div>

        <div className="admin-shipments-table-container">
          {loading ? (
            <div className="admin-shipments-loading-container">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <table className="admin-shipments-table">
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>Parcel Content</th>
                    <th>Shipping Fee</th>
                    <th>Payment Status</th>
                    <th>Shipping Status</th>
                    <th>Tracking</th>
                    <th>AWB Link</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {shipments.map((shipment) => (
                    <tr key={shipment._id}>
                      <td>{shipment.username}</td>
                      <td>{shipment.parcelContent}</td>
                      <td>${shipment.shippingFee.toFixed(2)}</td>
                      <td>
                        <span className={`admin-shipments-status-badge ${shipment.paid ? 'paid' : 'unpaid'}`}>
                          {shipment.paid ? 'Paid' : 'Unpaid'}
                        </span>
                      </td>
                      <td>
                        <span className={`admin-shipments-status-badge ${shipment.shipped ? 'shipped' : 'pending'}`}>
                          {shipment.shipped ? 'Shipped' : 'Pending'}
                        </span>
                      </td>
                      <td>
                        {shipment.awbNumber ? (
                          <a 
                            href={getTrackingUrl(shipment.awbNumber)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="admin-shipments-tracking-link"
                          >
                            {shipment.awbNumber}
                          </a>
                        ) : (
                          'Not available'
                        )}
                      </td>
                      <td>
                        {shipment.awbLink ? (
                          <a 
                            href={shipment.awbLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="admin-shipments-awb-link"
                          >
                            View AWB
                          </a>
                        ) : 'Not available'}
                      </td>
                      <td>{new Date(shipment.createdAt).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="admin-shipments-pagination">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1 || loading}
                  className="admin-shipments-pagination-button"
                >
                  <i className="fas fa-chevron-left"></i> Previous
                </button>
                
                <span className="admin-shipments-pagination-info">
                  Page {currentPage} of {totalPages}
                </span>
                
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages || loading}
                  className="admin-shipments-pagination-button"
                >
                  Next <i className="fas fa-chevron-right"></i>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminShipments; 