import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Make sure you've installed react-router-dom
import { setSelectedPrizes } from './redux/slices/prizesSlice';
import { setSelectedProduct } from './redux/slices/productSlice';
import kujiBox from './kujibox.png'; // Path to your box image
import soundOnIcon from './sound-on.png';  // Assuming the path to your sound on icon
import soundOffIcon from './sound-off.png'; // Assuming the path to your sound off icon
import mmkAudio from './moemoekyun.mp3';
import boxShakeAudio from './box-shake.mp3';  // Assuming this is the path to your shake sound
import bgmAudio from './bgm.mp3';  // Path to your background music file
import './DrawPage.css';
import NavBar from './NavBar';
import LoadingSpinner from './LoadingSpinner';
import TicketAnimationPopup from './TicketAnimationPopup'; // Import the popup component
import PaymentSuccessPopup from './PaymentSuccessPopup'; // You'll need to create this component

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function DrawPage() {
  // const selectedProduct = useSelector((state) => state.product.selectedProduct);
  // const orderId = useSelector(state => state.payment.orderId); 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isShaking, setIsShaking] = useState(false);
  const [sliderValue, setSliderValue] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [orderState, setOrderState] = useState(null)
  const [orderId, setOrderId] = useState(null);
  const [product, setProduct] = useState(null);
  const [ticketsBought, setTicketsBought] = useState(0);
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const [showPaymentSuccessPopup, setShowPaymentSuccessPopup] = useState(false);
  const [ticketsLeftToDraw, setTicketsLeftToDraw] = useState(0);
  const [goToResults, setGoToResults] = useState(false);
  const [localSelectedPrizes, setLocalSelectedPrizes] = useState([]); // Add this state to store selected prizes
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [challengeAlphabet, setChallengeAlphabet] = useState('');
  const [challengeRemainingTickets, setChallengeRemainingTickets] = useState(0);
  const [challengeWon, setChallengeWon] = useState(false);

  const audioRef = React.useRef(new Audio(mmkAudio));
  const boxShakeAudioRef = React.useRef(new Audio(boxShakeAudio));
  const bgmAudioRef = React.useRef(new Audio(bgmAudio)); // Background music ref
  
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page

    const orderState = JSON.parse(sessionStorage.getItem('orderState'));

    if (orderState) {
      setOrderState(orderState);
      setOrderId(orderState.orderId);
      setProduct(orderState.selectedProduct);
      setTicketsBought(orderState.ticketsBought);
      setTicketsLeftToDraw(orderState.ticketsBought); // Initialize ticketsLeftToDraw
      dispatch(setSelectedProduct(orderState.selectedProduct)); // Update the selected product with new details
      verifyPayment(orderState.orderId, orderState.ticketsBought);
      // Any other state updates or actions...
    }
  }, []);

  useEffect(() => {
    if (bgmAudioRef.current) {
      bgmAudioRef.current.volume = 0.1;
      bgmAudioRef.current.loop = true;
      // Don't autoplay here
    }
  }, []);

  const toggleMusic = () => {
    if (isMusicPlaying) {
        bgmAudioRef.current.pause();
    } else {
        bgmAudioRef.current.play();
    }
    setIsMusicPlaying(!isMusicPlaying);
  };

  const verifyPayment = async (orderId) => {
    try {
      const response = await fetch(`${backendUrl}/api/order/verify/${orderId}`);
      const data = await response.json();

      if (data.success) {
        // Payment has been verified
        // Proceed with fetching prizes, updating UI, etc.
        setShowPaymentSuccessPopup(true);
        const response = await fetch(`${backendUrl}/api/draw-and-update-order/${orderId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });
         
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        if (data.selectedPrizes) {
          setLocalSelectedPrizes(data.selectedPrizes);
          if (data.challengeWon) {
            setChallengeWon(data.challengeWon);
            setChallengeAlphabet(data.challengeAlphabet);
            setChallengeRemainingTickets(data.challengeRemainingTickets);
            setTicketsLeftToDraw(data.ticketsToDraw);
          } else {
            setTicketsLeftToDraw(data.selectedPrizes.length);
          }
        }

      } else {
        // Handle verification failure
        console.error('Payment verification failed');
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
    }
  };

  const handleSliderChange = (event) => {
  setSliderValue(event.target.value);
  };

  const handleClosePaymentSuccessPopup = () => {
    setShowPaymentSuccessPopup(false);
    if (product.lottery) {
      if (product.lottery.enabled) {
        navigate('/orders');
      }
    }
    // Optionally, trigger additional actions after closing the popup
  };
  
  const handleShake = () => {
      // Define dynamic keyframes
      let dynamicStyles = document.getElementById('dynamic-styles');
      if (!dynamicStyles) {
          dynamicStyles = document.createElement('style');
          dynamicStyles.id = 'dynamic-styles';
          document.head.appendChild(dynamicStyles);
      }

      const animationDuration = 1000;  // Adjust this to match the duration of your animation
  
      dynamicStyles.textContent = `
          @keyframes shake-and-tilt {
              0%, 100% {
                  transform: translateX(0) translateY(0) rotate(0);
              }
              10%, 90% {
                  transform: translateX(${-sliderValue * 10}px) translateY(${sliderValue * 5}px) rotate(${-sliderValue * 10}deg);
              }
              20%, 80% {
                  transform: translateX(${sliderValue * 10}px) translateY(${-sliderValue * 5}px) rotate(${sliderValue * 10}deg);
              }
              30%, 50%, 70% {
                  transform: translateX(${-sliderValue * 10}px) translateY(${sliderValue * 5}px) rotate(${-sliderValue * 10}deg);
              }
              40%, 60% {
                  transform: translateX(${sliderValue * 10}px) translateY(${-sliderValue * 5}px) rotate(${sliderValue * 10}deg);
              }
          }
      `;
  
      setIsShaking(true);
      boxShakeAudioRef.current.play();  // Start playing the shake sound

      // Set a timeout to stop the audio when the animation ends
      setTimeout(() => {
        setIsShaking(false);
        boxShakeAudioRef.current.pause();  // Stop the audio
        boxShakeAudioRef.current.currentTime = 0;  // Reset the audio position to the start
      }, animationDuration);
  };

  const playSound = () => {
    audioRef.current.play();
  };

  const handleDraw = async () => {
    setIsLoading(true);
    if (ticketsLeftToDraw - 1 === 0) {
      setTicketsLeftToDraw((prevTickets) => prevTickets - 1);
      setShowPopup(true); // Show the popup when the Draw button is clicked
      setGoToResults(true);
    } else {
      setTicketsLeftToDraw((prevTickets) => prevTickets - 1);
      setShowPopup(true); // Show the popup when the Draw button is clicked
    }
  };

  const closePopup = async () => {
    document.body.style.overflow = 'unset';
    setShowPopup(false); // Function to close the popup
    if (goToResults) {
        document.body.style.overflow = 'unset';
        try {
            const response = await fetch(`${backendUrl}/api/order/get-prizes/${orderState.orderId}`, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Failed to retrieve order');
            }

            const data = await response.json();
            
            dispatch(setSelectedPrizes(data.selectedPrizes)); // Dispatch action to set selected prizes
            navigate('/results'); // Navigate to the results page
        } catch (error) {
            console.error('Failed to retrieve selected prizes:', error);
        } finally {
            setIsLoading(false);
        }
    }
  };

  return (
    <div>
      <NavBar />
      <div className="gradient-background">
        <div className="volume-container">
          <img
            src={isMusicPlaying ? soundOnIcon : soundOffIcon}
            alt="Toggle Sound"
            className="sound-toggle-icon"
            onClick={toggleMusic}
          />
        </div>
        <div className="draw">
          <div className="draw-product-title-box">
            {
              product
              ? <span className="product-title-text">{product.name}</span>
              : <span className="product-title-text">Loading...</span>
            }
          </div>
          <div className="tickets-left">
            Tickets Left To Draw: {ticketsLeftToDraw}
          </div>
          <img 
            src={kujiBox} 
            alt="Box" 
            className={`kuji-box ${isShaking ? 'shake' : ''}`}
            style={isShaking ? { animation: `shake-and-tilt 0.8s ease-in-out infinite` } : {}}
          />
          <div className="slider-container">
            <input
                type="range"
                min="1"
                max="10"
                value={sliderValue}
                onChange={handleSliderChange}
                className="slider"
            />
          </div>
          <div className="button-container">
            <button className="shake-button" onClick={handleShake}>Shake</button>
            <button className="mmk-button" onClick={playSound}>Moe Moe Kyun</button>
            <button className="draw-button" onClick={handleDraw}>Draw</button>
          </div>
        </div>
        {showPopup && <TicketAnimationPopup isOpen={showPopup} onClose={closePopup} selectedPrize={localSelectedPrizes[ticketsLeftToDraw]} ticketsLeftToDraw={ticketsLeftToDraw} challengeAlphabet={challengeAlphabet} productId={orderState.selectedProduct.productId} />}
        {showPaymentSuccessPopup && <PaymentSuccessPopup onClose={handleClosePaymentSuccessPopup} selectedPrizes={localSelectedPrizes} challengeWon={challengeWon} product={product}/>}
      </div>
    </div>
  );
}

export default DrawPage;