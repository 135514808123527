import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import NavBar from './NavBar';
import LoadingSpinner from './LoadingSpinner';
import ImagePopup from './ImagePopup';
import './TradeHistory.css';

function TradeHistory() {
  const [trades, setTrades] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPrize, setSelectedPrize] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const username = useSelector((state) => state.user?.username);

  useEffect(() => {
    const fetchTradeHistory = async () => {
        console.log("fetching trade history")
      setIsLoading(true);
      try {
        const response = await fetch(`${backendUrl}/api/trade/history`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        console.log("response", response)
        if (response.ok) {
          const data = await response.json();
          setTrades(data);
        } else {
          console.error('Failed to fetch trade history');
        }
      } catch (error) {
        console.error('Error fetching trade history:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTradeHistory();
  }, [backendUrl]);

  const handleImageClick = (prize) => {
    setSelectedPrize({
      letter: prize.name.replace('Prize ', ''),
      description: prize.description,
      src: `${backendUrl}/api/images/${prize.image}`,
    });
  };

  return (
    <div>
      <NavBar />
      <div className="gradient-background">
        <div className="trade-history">
          <h1>Trade History</h1>
          {isLoading ? (
            <LoadingSpinner />
          ) : trades.length > 0 ? (
            trades.map((trade) => (
              <div key={trade._id} className="trade-history-card">
                <div className="trade-history-header">
                  <span className="trade-history-date">
                    Completed on: {new Date(trade.completedAt).toLocaleDateString()}
                  </span>
                  <span className="trade-history-status">
                    Status: {trade.status}
                  </span>
                </div>
                <div className="trade-history-details">
                  <div className="trade-history-section">
                    <h3>Traded Away</h3>
                    <div className="trade-history-prizes">
                      {trade.prizesOffered.map((prize, index) => (
                        <div key={index} className="trade-history-prize-card">
                          <h4>{prize.prizeDetails.name}</h4>
                          <p>{prize.prizeDetails.description}</p>
                          <img
                            src={`${backendUrl}/api/images/${prize.prizeDetails.image}`}
                            alt={prize.prizeDetails.name}
                            onClick={() => handleImageClick(prize.prizeDetails)}
                          />
                          <span>Quantity: {prize.quantity}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="trade-history-section">
                    <h3>Received</h3>
                    <div className="trade-history-prizes">
                      {trade.prizesReceived.map((prize, index) => (
                        <div key={index} className="trade-history-prize-card">
                          <h4>{prize.prizeDetails.name}</h4>
                          <p>{prize.prizeDetails.description}</p>
                          <img
                            src={`${backendUrl}/api/images/${prize.prizeDetails.image}`}
                            alt={prize.prizeDetails.name}
                            onClick={() => handleImageClick(prize.prizeDetails)}
                          />
                          <span>Quantity: {prize.quantity}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="trade-history-partner">
                  Traded with: {trade.partnerUsername}
                </div>
              </div>
            ))
          ) : (
            <div className="no-trades-message">No completed trades found.</div>
          )}
        </div>
      </div>
      {selectedPrize && (
        <ImagePopup
          prize={selectedPrize}
          src={selectedPrize.src}
          onClose={() => setSelectedPrize(null)}
        />
      )}
    </div>
  );
}

export default TradeHistory; 