import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearSelectedProduct, setSelectedProduct } from './redux/slices/productSlice';
import NavBar from './NavBar';
import logo from './ichibankujilogo.png';
import howtoplaybanner from './howtoplaybanner.jpg';
import closeIcon from './closeicon.png';
import howToPlay from './howtoplay.jpg';
import PlayPopup from './PlayPopup';
import ProductCard from './ProductCard';
import LoadingSpinner from './LoadingSpinner';
import Footer from './Footer';
import './IchibanKujiProducts.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function IchibanKujiProducts() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedProduct = useSelector((state) => state.product.selectedProduct);

  const [showPopup, setShowPopup] = useState(false);
  const [showPlayPopup, setShowPlayPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAnime, setSelectedAnime] = useState('All');
  const [selectedTab, setSelectedTab] = useState('new'); // Default to "all"
  const [animeFilterOptions, setAnimeFilterOptions] = useState([]);

  const [products, setProducts] = useState({
    all: [],
    new: [],
    promotions: [],
    chance: []
  });

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${backendUrl}/api/ichiban-kuji-products`);
        const data = await response.json(); // This is the object with categorized products

        // Update the state with categorized products
        setProducts({
          all: data.all,
          new: data.newProducts,
          promotions: data.promotions,
          chance: data.chance
        });

        // Extract anime filter options from the products
        const allProducts = [...data.all];
        const animes = Array.from(
          new Set(allProducts.map((product) => product.tags[0])) // Extract the anime from the first tag
        );
        setAnimeFilterOptions(animes);
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch products', error);
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [backendUrl]);

  useEffect(() => {
    const retryProduct = sessionStorage.getItem('retryProduct');
    
    if (retryProduct) {
      const product = JSON.parse(retryProduct);
      
      // Fetch all products again
      const fetchProducts = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(`${backendUrl}/api/ichiban-kuji-products`);
          const data = await response.json();

          // Update the state with categorized products
          setProducts({
            all: data.all,
            new: data.newProducts,
            promotions: data.promotions,
            chance: data.chance
          });

          // Extract anime filter options from the products
          const allProducts = [...data.all];
          const animes = Array.from(
            new Set(allProducts.map((product) => product.tags[0]))
          );
          setAnimeFilterOptions(animes);
          
          // Find the updated version of the retry product
          const updatedProduct = data.all.find(p => p.setId === product.setId);
          if (updatedProduct) {
            dispatch(setSelectedProduct(updatedProduct));
            setShowPlayPopup(true);
          }
          
        } catch (error) {
          console.error('Failed to fetch products', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchProducts();
      sessionStorage.removeItem('retryProduct');
    }
  }, []);

  const handleTryAgain = async () => {
    if (selectedProduct && selectedProduct.setId) {
      try {
        const response = await fetch(`${backendUrl}/api/ichiban-kuji-product/${selectedProduct.setId}`);
        const updatedProductDetails = await response.json();
        dispatch(setSelectedProduct(updatedProductDetails)); // Update the selected product with new details
      } catch (error) {
        console.error('Failed to refetch product details', error);
      }
    }
    setShowPlayPopup(true); // Reopen the PlayPopup with updated product details
  };

  const showPlayPopupHandler = (product) => {
    dispatch(setSelectedProduct(product)); // Update the selected product in the global state
    setShowPlayPopup(true);
  };

  const handleClosePlayPopup = () => {
    dispatch(clearSelectedProduct());
    setShowPlayPopup(false);
  };

  const handleAnimeFilterChange = (e) => {
    setSelectedAnime(e.target.value);
  };

  // Handle tab change (all, new, promotions, chance)
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  // Filter products based on the selected anime, selected tab, and stock status (PRE-ORDER and INSTOCK)
  const filteredProducts = {
    preorder:
      selectedTab === 'all'
        ? products.all.filter(
            (product) =>
              (selectedAnime === 'All' || product.tags[0] === selectedAnime) &&
              product.preorder === true
          )
        : products[selectedTab]?.filter(
            (product) =>
              (selectedAnime === 'All' || product.tags[0] === selectedAnime) &&
              product.preorder === true
          ),
    instock:
      selectedTab === 'all'
        ? products.all.filter(
            (product) =>
              (selectedAnime === 'All' || product.tags[0] === selectedAnime) &&
              product.preorder === false
          )
        : products[selectedTab]?.filter(
            (product) =>
              (selectedAnime === 'All' || product.tags[0] === selectedAnime) &&
              product.preorder === false
          )
  };

  useEffect(() => {
    // Find all scroll containers
    const containers = document.querySelectorAll('.product-scroll-container');
    if (containers.length === 0) {
      console.log('No containers found');
      return;
    }

    // Create observer for each container
    containers.forEach((container, containerIndex) => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const containerRect = container.getBoundingClientRect();
            const containerCenter = containerRect.left + containerRect.width / 2;
            
            const cardRect = entry.target.getBoundingClientRect();
            const cardCenter = cardRect.left + cardRect.width / 2;
            
            const isCentered = Math.abs(containerCenter - cardCenter) < 50;
            
            if (isCentered) {
              // Only remove active class from cards in the same container
              container.querySelectorAll('.product-card-container').forEach(card => {
                card.classList.remove('active');
              });
              entry.target.classList.add('active');
            }
          });
        },
        {
          root: container,
          threshold: [0.1, 0.5, 0.9],
          rootMargin: '-10% 0px'
        }
      );

      // Add unique IDs to cards in this container and observe them
      const cards = container.querySelectorAll('.product-card-container');
      cards.forEach((card, index) => {
        card.id = `container-${containerIndex}-card-${index}`;
        observer.observe(card);
      });

      // Add scroll event listener for more precise control
      const handleScroll = () => {
        requestAnimationFrame(() => {
          const containerRect = container.getBoundingClientRect();
          const containerCenter = containerRect.left + containerRect.width / 2;
          
          let closestCard = null;
          let minDistance = Infinity;

          container.querySelectorAll('.product-card-container').forEach(card => {
            const cardRect = card.getBoundingClientRect();
            const cardCenter = cardRect.left + cardRect.width / 2;
            const distance = Math.abs(containerCenter - cardCenter);
            
            if (distance < minDistance) {
              minDistance = distance;
              closestCard = card;
            }
          });

          if (closestCard && minDistance < 50) {
            container.querySelectorAll('.product-card-container').forEach(c => {
              c.classList.remove('active');
            });
            closestCard.classList.add('active');
          }
        });
      };

      container.addEventListener('scroll', handleScroll);

      // Store cleanup function
      container.cleanupFunction = () => {
        observer.disconnect();
        container.removeEventListener('scroll', handleScroll);
      };
    });

    // Cleanup function for all containers
    return () => {
      containers.forEach(container => {
        if (container.cleanupFunction) {
          container.cleanupFunction();
        }
      });
    };
  }, [products, selectedTab, selectedAnime]);

  return (
    <div>
      <NavBar />
      {showPlayPopup && selectedProduct && <PlayPopup onClose={handleClosePlayPopup} />}
      <div className="gradient-background">
        <img src={howtoplaybanner} alt="how-to-play-banner" className="how-to-play-banner"></img>
        {/* <div className="products-logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <button className="how-button" onClick={() => setShowPopup(true)}>
          How to Play Ichiban Kuji
        </button> */}

        {showPopup && (
          <div className="popup popup-overlay">
            <button className="how-to-close-button" onClick={() => setShowPopup(false)}>
              <img src={closeIcon} alt="Close" />
            </button>
            <img src={howToPlay} alt="Instructions" className="popup-image" />
          </div>
        )}

        <div className="anime-filter">
          <label htmlFor="animeFilter">Filter by Anime:</label>
          <select id="animeFilter" value={selectedAnime} onChange={handleAnimeFilterChange}>
            <option value="All">All</option>
            {animeFilterOptions.map((anime) => (
              <option key={anime} value={anime}>
                {anime}
              </option>
            ))}
          </select>
        </div>

        {/* Add tabs for 'all', 'new', 'promotions', 'chance' */}
        <div className="tab-section-wrapper">
          <div className="tab-section">
            <span
              className={`tab-text ${selectedTab === 'new' ? 'active' : ''}`}
              onClick={() => handleTabChange('new')}
            >
              New
            </span>
            <div className="vertical-divider"></div>
            <span
              className={`tab-text ${selectedTab === 'promotions' ? 'active' : ''}`}
              onClick={() => handleTabChange('promotions')}
            >
              Promotions
            </span>
            <div className="vertical-divider"></div>
            <span
              className={`tab-text ${selectedTab === 'chance' ? 'active' : ''}`}
              onClick={() => handleTabChange('chance')}
            >
              Chance
            </span>
            <div className="vertical-divider"></div>
            <span
              className={`tab-text ${selectedTab === 'all' ? 'active' : ''}`}
              onClick={() => handleTabChange('all')}
            >
              All
            </span>
          </div>
        </div>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="category-container">
            {/* Show Preorder and Instock categories */}
            <CategorySection title="Preorder" products={filteredProducts.preorder} onPlayClick={showPlayPopupHandler} />
            <CategorySection title="Instock" products={filteredProducts.instock} onPlayClick={showPlayPopupHandler} />
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
}

function CategorySection({ title, products = [], onPlayClick }) {
  const scrollContainerRef = useRef(null);
  const [showPreorderInfoPopup, setShowPreorderInfoPopup] = useState(false);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', () => {});
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', () => {});
      }
    };
  }, [scrollContainerRef]);

  // Safeguard: Ensure 'products' is always an array and handle the case when it's empty
  if (!products || products.length === 0) {
    return (
      <div className="category-section">
        <h2 className="category-title">{title}</h2>
        <p className="no-products-message">There are no products in this section</p>
      </div>
    );
  }

  return (
    <div className="category-section">
      <div className="category-title-container">
        <h2 className="category-title">{title}</h2>
        {title === "Preorder" && (
          <>
            <div 
              className="preorder-info-icon" 
              onClick={() => setShowPreorderInfoPopup(true)}
            >
              i
            </div>
            {showPreorderInfoPopup && (
              <div className="preorder-info-popup popup-overlay">
                <div className="preorder-info-popup-content">
                  <button 
                    className="preorder-info-popup-close"
                    onClick={() => setShowPreorderInfoPopup(false)}
                  >
                    ×
                  </button>
                  <div className="preorder-info-popup-message">
                    You will receive an email notification when your preordered items have arrived and are ready to ship.
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      
      <div className="scroll-container-wrapper">
        <div className="product-scroll-container" ref={scrollContainerRef}>
          {products.map((product) => (
            <div key={product.setId} className="product-card-container">
              <ProductCard product={product} onPlayClick={() => onPlayClick(product)} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default IchibanKujiProducts;