// AdminMenu.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminMenu.css'; // Optional CSS file for styling

const AdminMenu = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="admin-menu-container">
      <h1>Admin Dashboard</h1>
      <div className="admin-menu-buttons">
        <button 
          className="admin-menu-button products"
          onClick={() => handleNavigation('/admin/products')}
        >
          Products
        </button>
        <button 
          className="admin-menu-button orders"
          onClick={() => handleNavigation('/admin/orders')}
        >
          Orders
        </button>
        <button 
          className="admin-menu-button selfcollect"
          onClick={() => handleNavigation('/admin/selfcollect')}
        >
          <i className="fas fa-hand-holding-box"></i>
          Self Collections
        </button>
        <button 
          className="admin-menu-button shipments"
          onClick={() => handleNavigation('/admin/shipments')}
        >
          Shipments
        </button>
        <button 
          className="admin-menu-button inventory-lookup"
          onClick={() => handleNavigation('/admin/inventory-lookup')}
        >
          Inventory Lookup
        </button>
      </div>
    </div>
  );
};

export default AdminMenu;
