import React, { useState, useEffect } from 'react';
import './ImagePopup.css';

function ImagePopup({ prize, src, onClose }) {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true); // Trigger the closing animation
    setTimeout(() => {
      onClose(); // Call the onClose callback only after the animation completes
    }, 250); // Match the duration of the closing animation
  };

  return (
    <div className={`image-popup popup-overlay ${isClosing ? 'closing' : ''}`}>
      <div className="image-popup-content">
        <button className="image-popup-close" onClick={handleClose}>✖</button>
        <div className="image-card">
          <img src={src} alt="Full Size" className="full-size-image" />
          <div className="image-name-description">
            Prize {prize.letter} ({prize.description})
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImagePopup;