import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useLocation
import NavBar from './NavBar';
import logo from './logo.png';
import LoadingSpinner from './LoadingSpinner';

function VerificationPage() {
    const location = useLocation();
    const emailFromState = location.state?.email || ''; // Retrieve email from state
    const [code, setCode] = useState('');
    const [email, setEmail] = useState(emailFromState); // Use the email from the state
    const [canResend, setCanResend] = useState(false);
    const [countdown, setCountdown] = useState(60); // 60 seconds countdown
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate(); // Hook for navigation

    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const isValidEmail = email => {
        // Simple email validation pattern
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    };

    // Handle sending of the verification code
    const handleResendCode = async () => {
        // Logic to handle resending the code goes here
        setCountdown(60);
        setCanResend(false);
        if (!isValidEmail(email)) {
            console.log('Invalid email');
            return;
        }
        
        try {
            const response = await fetch(`${backendUrl}/api/verification/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
                credentials: 'include'
            });

            const data = await response.json();
        } catch (error) {
            console.error('Error:', error);
        }
        // ... other logic to send the verification code
    };

    useEffect(() => {
        // Start the countdown when the component mounts
        const timer = countdown > 0 && setInterval(() => setCountdown(countdown - 1), 1000);
        return () => clearInterval(timer);
    }, [countdown]);
    
    useEffect(() => {
        if (countdown === 0) {
            setCanResend(true);
        }
    }, [countdown]);

    const handleSubmit = async (event) => {
        setError('');
        setIsLoading(true);
        event.preventDefault();
        if (!code) {
            console.log('Please enter the verification code');
            setIsLoading(false);
            return;
        }
        
        try {
            const response = await fetch(`${backendUrl}/api/verification/verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, code }),
                credentials: 'include'
            });

            const data = await response.json();
            if (response.ok) {
                console.log('Verification successful, proceed to next step');
                if (location.state?.process === 'forgetPassword') {
                    setIsLoading(false);
                    navigate('/change-password', { state: { email } }); // Navigate to the ChangePasswordPage
                } else {
                    setIsLoading(false);
                    navigate('/sign-up', { state: { email } }); // Navigate to the SignUpPage
                }
            } else {
                setIsLoading(false);
                console.log('Verification failed: ' + data.message);
                setError('Verification Code is incorrect, please request for a new code.')
                // Handle verification failure, e.g., showing an error message
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error:', error);
        }
    };

    return (
        <div>
        <NavBar />
        <div className="gradient-background">
            <div style={styles.logoContainer}>
                <img src={logo} alt="Logo" style={styles.logo} />
            </div>
            <div style={styles.container}>
                <form onSubmit={handleSubmit} style={styles.form}>
                <h2 style={styles.h2Text}>Enter Verification Code</h2>
                <div style={styles.formContent}>
                    <input
                    type="text"
                    id="verification-code"
                    placeholder="6-digit verification code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    style={styles.input}
                    />
                    {error && <div style={{ color: 'red', width: '80%', marginTop: '10px' }}>{error}</div>}
                    <div style={styles.infoTextContainer}>
                        <p style={styles.infoText}>
                            Important Information:
                        </p>
                        <ul style={styles.infoList}>
                            <li>- Verification code is valid for 15 minutes</li>
                            <li>- Email delivery may take 30 seconds to 10 minutes</li>
                            <li>- Gmail accounts receive emails faster (recommended)</li>
                            <li>- Please check your spam/junk/all email folders</li>
                        </ul>
                    </div>
                    {
                        isLoading
                        ? (
                            <LoadingSpinner />
                        )
                        : (
                            <button 
                            type="submit" 
                            style={styles.button}
                            disabled={!code} // Button is disabled if code is not entered
                            >
                            Verify
                            </button> 
                        )
                    }
                    {countdown > 0 ? (
                        <p style={styles.countdownText}>You can resend the code in {countdown} seconds.</p>
                        ) : (
                        <button 
                            onClick={handleResendCode} 
                            style={styles.resendbutton}
                            disabled={!canResend}
                        >
                            Resend Code
                        </button>
                    )}
                </div>
                </form>
            </div>
        </div>
        </div>
    );
}

// Add styles here similarly to LoginPage
const styles = {
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '20px'
    },
    logo: {
      height: '100px', // Adjust the size as needed
    },
    formContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    input: {
      width: '80%', // 100% minus padding
      padding: '15px',
      marginTop: '5px',
      borderRadius: '4px',
      border: '1px solid #ddd',
      textAlign: 'center', // Attempt to center align the text
      // Additional styling for the placeholder
      '::placeholder': {
          textAlign: 'center', // This may not be supported in all browsers
      },
      fontSize: '15px'
    },
    button: {
      width: '70%', // Adjust the width as needed
      padding: '15px',
      marginTop: '10px',
      backgroundColor: '#4CAF50', // Base color for the button
      color: 'white',
      border: '1px solid green',
      borderRadius: '4px',
      cursor: 'pointer',
      boxShadow: '0 6px #2e7d32', // Shadow effect for 3D look
      fontSize: '15px'
    },
    resendbutton: {
        backgroundColor: 'transparent',
        color: 'black', // Hyperlink blue color
        textDecoration: 'underline',
        border: 'none',
        cursor: 'pointer',
        padding: 0,
        margin: '30px 0',
        fontSize: '16px',
    },
    buttonDisabled: {
      backgroundColor: '#A9A9A9', // Dark gray background
      color: '#D3D3D3', // Light gray text
      cursor: 'not-allowed', // Cursor to indicate the button is disabled
      boxShadow: 'none', // Remove shadow for a flat appearance
      border: '2px solid #D3D3D3', // Adding a border, adjust color as needed
    },
    infoTextContainer: {
        width: '80%',
        marginTop: '20px',
        marginBottom: '20px',
        padding: '15px',
        backgroundColor: '#f8f9fa',
        borderRadius: '8px',
        border: '1px solid #e9ecef',
    },
    infoList: {
        listStyle: 'none',
        padding: '0',
        margin: '10px 0 0 0',
        textAlign: 'left',
        color: '#666',
        fontSize: '14px',
        lineHeight: '1.6'
    },
    infoText: {
        color: '#495057',
        fontSize: '15px',
        fontWeight: '500',
        margin: '0',
        textAlign: 'left'
    },
    h2Text: {
      textAlign: 'center', // Center align text for headings
    },
    h4Text: {
      textAlign: 'center',
      marginTop: '50px',
      marginBottom: '10px'
    },
    countdownText: {
      textAlign: 'center',
      marginTop: '20px',
      marginBottom: '10px'
    }
  };

export default VerificationPage;