import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNotifications, markAllAsRead, removeNotification } from './redux/slices/notificationSlice';
import './NotificationsDropdown.css';
import { IoNotificationsOutline } from 'react-icons/io5';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const NotificationsDropdown = () => {
  const dispatch = useDispatch();
  const { notifications, unreadCount } = useSelector((state) => state.notifications);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('.notifications-dropdown')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
    if (!isOpen) dispatch(markAllAsRead());
  };

  const handleRemoveNotification = (id) => {
    dispatch(removeNotification(id));
  };

  return (
    <div className="notifications-dropdown">
      <div className="notifications-icon" onClick={toggleDropdown}>
        <IoNotificationsOutline size={28} />
        {unreadCount > 0 && <span className="unread-badge">{unreadCount}</span>}
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          {notifications.length > 0 ? (
            notifications.map((notif) => (
              <div key={notif._id} className="notification-item">
                <p>{notif.message}</p>
                <small>{new Date(notif.createdAt).toLocaleString()}</small>
                <button
                  className="remove-notification"
                  onClick={() => handleRemoveNotification(notif._id)}
                >
                  ×
                </button>
              </div>
            ))
          ) : (
            <p className="no-notifications">No notifications</p>
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationsDropdown;