import React, { useState, useEffect } from 'react';
import './PurchaseNotification.css';

function PurchaseNotification({ message, onClose }) {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    // Auto-close after 3 seconds
    const timer = setTimeout(() => {
      setIsClosing(true);
      setTimeout(onClose, 300); // Wait for animation to complete
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(onClose, 300);
  };

  return (
    <div className={`purchase-notification ${isClosing ? 'closing' : ''}`}>
      <button className="notification-close-btn" onClick={handleClose}>
        ×
      </button>
      {message}!
    </div>
  );
}

export default PurchaseNotification; 