import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import './AdminOrders.css';
import LoadingSpinner from './LoadingSpinner';

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    lottery: 'all', // 'all', 'enabled', 'disabled'
    paid: 'all'     // 'all', 'yes', 'no'
  });
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      fetchOrders(currentPage, searchTerm, filters);
    }, searchTerm ? 300 : 0); // Add delay for search, no delay for pagination

    return () => clearTimeout(timeoutId);
  }, [currentPage, searchTerm, filters]);

  const fetchOrders = async (page, search, filters) => {
    try {
      const queryParams = new URLSearchParams({
        page,
        search: search || '',
        lottery: filters.lottery,
        paid: filters.paid
      });

      const response = await fetch(
        `${backendUrl}/api/admin/orders?${queryParams}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
          }
        }
      );

      if (response.status === 401 || response.status === 403) {
        navigate('/admin/login');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to fetch orders');
      }

      const data = await response.json();
      setOrders(data.orders);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setLoading(true);
    setCurrentPage(newPage);
  };

  const handleFilterChange = (filterType, value) => {
    setLoading(true);
    setCurrentPage(1);
    setFilters(prev => ({
      ...prev,
      [filterType]: value
    }));
  };

  const formatSelectedPrizes = (prizes) => {
    if (!Array.isArray(prizes)) return 'No prizes';
    
    // Count occurrences of each prize
    const counts = prizes.reduce((acc, prize) => {
      if (prize === 'LAST') {
        acc.LAST = (acc.LAST || 0) + 1;
      } else {
        acc[prize] = (acc[prize] || 0) + 1;
      }
      return acc;
    }, {});

    // Convert to array, sort alphabetically, and move LAST to end
    const sortedPrizes = Object.entries(counts)
      .sort(([a], [b]) => {
        if (a === 'LAST') return 1;
        if (b === 'LAST') return -1;
        return a.localeCompare(b);
      })
      .map(([prize, count]) => `${count}${prize}`);

    return sortedPrizes.join(', ');
  };

  const debouncedSearch = (value) => {
    setLoading(true);
    setCurrentPage(1);
    setSearchTerm(value);
  };

  if (error) return <div className="admin-orders-error">Error: {error}</div>;

  return (
    <div>
      <NavBar />
      <div className="admin-orders-container">
        <div className="admin-orders-header">
          <div className="admin-orders-header-left">
            <button 
              onClick={() => navigate('/admin/menu')} 
              className="admin-orders-back-button"
            >
              <i className="fas fa-arrow-left"></i> Back to Menu
            </button>
            <h1>Orders</h1>
          </div>
        </div>

        <div className="admin-orders-controls">
          <div className="admin-orders-search">
            <div className="admin-orders-search-input-container">
              <i className="fas fa-search search-icon"></i>
              <input
                type="text"
                placeholder="Search by Order ID, Product, or Username"
                value={searchTerm}
                onChange={(e) => debouncedSearch(e.target.value)}
                className="admin-orders-search-input"
              />
              {searchTerm && (
                <button
                  className="admin-orders-clear-search-button"
                  onClick={() => debouncedSearch('')}
                >
                  <i className="fas fa-times"></i>
                </button>
              )}
            </div>
          </div>
          
          <div className="admin-orders-filters">
            <div className="admin-orders-filter-group">
              <label>Lottery:</label>
              <select 
                value={filters.lottery}
                onChange={(e) => handleFilterChange('lottery', e.target.value)}
                className="admin-orders-filter-select"
              >
                <option value="all">All</option>
                <option value="enabled">Enabled</option>
                <option value="disabled">Disabled</option>
              </select>
            </div>

            <div className="admin-orders-filter-group">
              <label>Paid:</label>
              <select 
                value={filters.paid}
                onChange={(e) => handleFilterChange('paid', e.target.value)}
                className="admin-orders-filter-select"
              >
                <option value="all">All</option>
                <option value="yes">Paid</option>
                <option value="no">Unpaid</option>
              </select>
            </div>
          </div>
        </div>

        <div className="admin-orders-table-container">
          {loading ? (
            <div className="admin-orders-loading-container">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <table className="admin-orders-table">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Product</th>
                    <th>Tickets</th>
                    <th>Total Cost</th>
                    <th>Selected Prizes</th>
                    <th>Username</th>
                    <th>Token Value</th>
                    <th>Lottery</th>
                    <th>Paid</th>
                    <th>Payment ID</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order._id}>
                      <td>{order.orderId || 'N/A'}</td>
                      <td>{order.productName || 'Unknown Product'}</td>
                      <td>{order.numberOfTickets || 0}</td>
                      {
                        order.paymentIntentId === "TOKEN"
                        ? <td>{(order.totalCost || 0)}</td>
                        : <td>${((order.totalCost || 0)/100).toFixed(2)}</td>
                      }
                      <td>{formatSelectedPrizes(order.selectedPrizes)}</td>
                      <td>{order.username || 'Unknown User'}</td>
                      <td>${((order.tokenTotalValue || 0)).toFixed(2)}</td>
                      <td>
                        <span className={`admin-orders-lottery-status ${order.lotteryEnabled ? 'enabled' : 'disabled'}`}>
                          {order.lotteryEnabled ? 'Yes' : 'No'}
                        </span>
                      </td>
                      <td>{order.paid ? 'Yes' : 'No'}</td>
                      <td>{order.paymentIntentId || 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="admin-orders-pagination">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1 || loading}
                  className="admin-orders-pagination-button"
                >
                  <i className="fas fa-chevron-left"></i> Previous
                </button>
                
                <span className="admin-orders-pagination-info">
                  Page {currentPage} of {totalPages}
                </span>
                
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages || loading}
                  className="admin-orders-pagination-button"
                >
                  Next <i className="fas fa-chevron-right"></i>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminOrders; 