import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import NavBar from './NavBar';
import PopularProducts from './PopularProducts'; // Import the new component
import PlayPopup from './PlayPopup';
import Footer from './Footer';
import ichibankujibanner from './ichibankujibanner.jpg'; // Import your logo
import blindBoxesBanner from './blindboxesbanner.jpg'; // Import the Blind Boxes banner
import ichibankujibannervideo from './ichibankujibannervideo.mp4';
import './HomePage.css';
import promoBanner from './promo_banner.png'; // Import your promo banner
import logo from './logo.png';

function HomePage() {
  let navigate = useNavigate(); // Hook for navigation
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showPlayPopup, setShowPlayPopup] = useState(false);
  const [showLoginSuccess, setShowLoginSuccess] = useState(false);
  const [showMobileWarning, setShowMobileWarning] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page

    // Check device screen width
    if (window.innerWidth > 768) { // Adjust this value based on your needs
      setShowMobileWarning(true);
    }

    // Check for a flag in local storage set by LoginPage on successful login
    if (sessionStorage.getItem('loginSuccess') === 'true' || (sessionStorage.getItem('accountCreatedSuccess') === 'true')) {
      setShowLoginSuccess(true);
      sessionStorage.removeItem('loginSuccess'); // Clean up the flag
      sessionStorage.removeItem('accountCreatedSuccess');
      // Hide the message after 3 seconds
      const timer = setTimeout(() => setShowLoginSuccess(false), 3000);
      return () => clearTimeout(timer);
    }
  }, []);

  const goToIchibanKujiProducts = () => {
    navigate('/ichiban-kuji-products'); // Path to your IchibanKujiProducts page
  };

  const showPlayPopupHandler = (product) => {
    setSelectedProduct(product); // Set the selected product
    setShowPlayPopup(true);
  };

  const closeMobileWarning = () => {
    setShowMobileWarning(false);
  };
  
  return (
    <div>
      <NavBar />
      <div className="gradient-background">

        {/* Promo Banner */}
        <div className="home-promo-banner-container">
          <img src={promoBanner} alt="Promo Banner" className="home-promo-banner" />
        </div>

        {/* Ichiban Kuji Section */}
        {showLoginSuccess && <div className="login-success-message">Successfully logged in!</div>}
        {showMobileWarning && (
          <>
            <div className="overlay"></div>
            <div className="mobile-warning-popup  popup-overlay">
              <p>For the best experience, please use a mobile device.</p>
              <button onClick={closeMobileWarning}>Close</button>
            </div>
          </>
        )}
        {/* <div className="home-logo-container">
          <img src={logo} alt="Logo" className="home-logo" />
        </div> */}
        <div className="kuji-banner">
          <div className="kuji-title-box">
            <span>Ichiban Kuji</span>
          </div>
            <video 
              autoPlay 
              loop 
              muted 
              playsInline
              poster={ichibankujibanner} // Optional: shows while video loads
              className="banner"
            >
              <source src={ichibankujibannervideo} type="video/mp4" />
            </video>
          <div className="text-box">
            <p>You buy a ticket and are guaranteed to win a prize, ranging from cool small items to rare and epic collectibles from your favorite anime and games.</p>
          </div>
        </div>
        <button className="kuji-play-button" onClick={goToIchibanKujiProducts}>
          Play
        </button>
        
        {/* Blind Boxes Section */}
        <div className="bb-banner">
          <div className="bb-title-box">
            <span>Blind Boxes</span>
          </div>
          <img src={blindBoxesBanner} alt="Blind Boxes Banner" className="banner" />
          <div className="text-box">
            <p>You buy a sealed box without knowing what specific item from a series is inside. Every purchase is an exciting reveal of collectibles.</p>
          </div>
        </div>
        <button className="bb-play-button">Coming soon</button>
        <Footer />

        {/* New and Popular Section */}
        {/* <PopularProducts onPlayClick={showPlayPopupHandler}/> */}

        {/* Play Popup */}
        {/* {showPlayPopup && selectedProduct && (
          <PlayPopup 
            product={selectedProduct} 
            onClose={() => setShowPlayPopup(false)} 
          />
        )} */}

        {/* {showDrawOpenPopup && <DrawOpenPopup onClose={() => setShowDrawOpenPopup(false)} onDraw={handleDraw} product={selectedProduct} />}
        {showResultsPopup && <ResultsPopup onClose={() => setShowResultsPopup(false)} onTryAgain={handleTryAgain} />} */}
      </div>
    </div>
  );
}

export default HomePage;
