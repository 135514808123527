import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NavBar from './NavBar';
import LoadingSpinner from './LoadingSpinner';
import './TradingMarketplace.css';
import ImagePopup from './ImagePopup'; // Import the ImagePopup component
import InventorySelectionModal from './InventorySelectionModal';
import ModalPopup from './ModalPopup'; // Import the ModalPopup component
import Fuse from 'fuse.js';

function TradingMarketplace() {
  const [trades, setTrades] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPrize, setSelectedPrize] = useState(null); // State to track the selected prize
  const [showInventoryModal, setShowInventoryModal] = useState(false);
  const [currentTrade, setCurrentTrade] = useState(null); // Current trade for offer
  const [popupMessage, setPopupMessage] = useState(null); // Message for the popup
  const [showConfirmationModal, setShowConfirmationModal] = useState(false); // Confirmation modal visibility
  const [offeredItems, setOfferedItems] = useState([]); // Items to be offered
  const [showOfferPopup, setShowOfferPopup] = useState(null); // Popup to view user's offer
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredTrades, setFilteredTrades] = useState([]);
  const [isSearching, setIsSearching] = useState(false); // New state for search loading
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const username = useSelector((state) => state.user?.username); // Use optional chaining
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTrades = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${backendUrl}/api/trade/active-trades`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        });
        console.log("response:",response);
        if (response.ok) {
          const data = await response.json();
          console.log("data:",data);
          setTrades(data);
          setFilteredTrades(data); // Initialize filtered trades with all trades
          console.log(data);
        } else {
          console.error('Failed to fetch trades');
        }
      } catch (error) {
        console.error('Error fetching trades:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTrades();
  }, [backendUrl]);

  // Modified search effect with debounce
  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setIsSearching(true);
      try {
        if (!searchQuery.trim()) {
          setFilteredTrades(trades);
          return;
        }

        const lowerQuery = searchQuery.toLowerCase().trim();
        const filtered = trades.filter(trade => {
          // Search in creator's username
          const usernameMatch = trade.createdBy.username.toLowerCase().includes(lowerQuery);
          
          // Search in offered prizes' set names
          const offeredSetsMatch = trade.prizesOffered.some(offer => 
            offer.setName.toLowerCase().includes(lowerQuery)
          );
          
          // Search in wanted prizes' set names
          const wantedSetsMatch = trade.prizesWanted.some(wanted => 
            wanted.productDetails.name.toLowerCase().includes(lowerQuery)
          );

          return usernameMatch || offeredSetsMatch || wantedSetsMatch;
        });

        setFilteredTrades(filtered);
      } finally {
        setIsSearching(false);
      }
    }, 300); // 300ms delay

    return () => clearTimeout(searchTimeout);
  }, [searchQuery, trades]);

  const handleImageClick = (prize) => {
    const getPrizeLetter = (name) => {
      if (name === 'Last Prize') return 'LAST';
      return name.replace('Prize ', ''); // Extract the letter after "Prize "
    };
  
    setSelectedPrize({
      letter: getPrizeLetter(prize.name),
      description: prize.description,
      src: `${backendUrl}/api/images/${prize.image}`,
    });
  };  

  const handleClosePopup = () => {
    setSelectedPrize(null);
  };

  const handleOfferTrade = (trade) => {
    setCurrentTrade(trade); // Set the current trade for which an offer is being made
    setShowInventoryModal(true); // Show the inventory selection modal
  };

  const handleConfirmOffer = (items) => {
    setOfferedItems(items); // Save the offered items for confirmation
    setShowInventoryModal(false); // Close the inventory modal
    setShowConfirmationModal(true); // Show the confirmation modal
  };

  const handleSubmitOffer = async () => {
    setShowConfirmationModal(false); // Close the confirmation modal
    try {
      const response = await fetch(`${backendUrl}/api/trade/offer/${currentTrade._id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ offeredItems }),
      });

      const data = await response.json();

      if (response.ok) {
        setPopupMessage({ type: 'success', message: data.message });
      } else if (response.status === 403) {
        // Handle specific error for offering on own trade
        setPopupMessage({ type: 'error', message: data.message });
      } else {
        // Handle generic error
        setPopupMessage({ type: 'error', message: 'Failed to submit trade offer. Please try again.' });
      }
    } catch (error) {
      console.error('Error submitting trade offer:', error);
      setPopupMessage({ type: 'error', message: 'An error occurred while submitting the trade offer. Please try again.' });
    }
  };

  const handleWithdrawOffer = async (tradeId, offerId) => {
    try {
      const response = await fetch(`${backendUrl}/api/trade/${tradeId}/withdraw-offer/${offerId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        setPopupMessage({ type: 'success', message: 'Offer withdrawn successfully.' });
        setShowOfferPopup(null); // Close the offer popup
        setTrades((prevTrades) =>
          prevTrades.map((trade) =>
            trade._id === tradeId
              ? { ...trade, userOffer: null } // Remove the user's offer from the trade
              : trade
          )
        );
      } else {
        setPopupMessage({ type: 'error', message: 'Failed to withdraw offer.' });
      }
    } catch (error) {
      console.error('Error withdrawing offer:', error);
      setPopupMessage({ type: 'error', message: 'An error occurred while withdrawing the offer.' });
    }
  };

  return (
    <div>
      <NavBar />
      <div className="gradient-background">
        <div className="trading-marketplace">
          <h1>Trading Marketplace</h1>
          
          <div className="trading-marketplace-search">
            <input
              type="text"
              placeholder="Search by username or set name..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="trading-marketplace-search-input"
            />
            {isSearching && (
              <div className="trading-marketplace-search-spinner">
                <LoadingSpinner />
              </div>
            )}
          </div>

          {isLoading ? (
            <LoadingSpinner />
          ) : filteredTrades.length > 0 ? (
            filteredTrades.map((trade) => (
              <div key={trade._id} className="trading-marketplace-trade-card">
                <div className="trading-marketplace-trade-header">
                  {trade.createdBy.profilePicture ? (
                    <img
                      src={`${backendUrl}/api/images/${trade.createdBy.profilePicture}`}
                      alt={`${trade.createdBy.username}'s profile`}
                      className="trading-marketplace-profile-picture"
                    />
                  ) : null}
                  <span className="trading-marketplace-trade-created-by">
                    {trade.createdBy.username}
                  </span>
                </div>
                <div className="trading-marketplace-trade-details">
                  {/* Prizes Offered */}
                  <div className="trading-marketplace-prizes-container">
                    <h3 style={{ marginTop: "0px" }}>Prizes Offered</h3>
                    <div className="trading-marketplace-prizes">
                      {trade.prizesOffered.map((offer, index) => (
                        <div key={index} className="trading-marketplace-prize-card">
                          <h4 className="trading-marketplace-prize-name">{offer.prizeDetails.name}</h4>
                          <span className="trading-marketplace-prize-description">({offer.prizeDetails.description})</span>
                          <img
                            src={`${backendUrl}/api/images/${offer.prizeDetails.image}`}
                            alt={offer.prizeDetails.name}
                            onClick={() => handleImageClick(offer.prizeDetails)}
                            className="trading-marketplace-prize-image"
                          />
                          <span className="trading-marketplace-prize-quantity">Qty: {offer.quantity}</span>
                          {offer.shipByDate ? (
                            <span className="trading-marketplace-ship-by">
                              Ship By: {new Date(offer.shipByDate).toLocaleDateString()}
                            </span>
                          ) : offer.estimatedArrival && (
                            <span className="trading-marketplace-arrival">
                              Est Arrival: {offer.estimatedArrival}
                            </span>
                          )}
                          <span className="trading-marketplace-prize-set-name">({offer.setName})</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Arrow */}
                  {/* <div className="trading-marketplace-arrow">
                    <span>&#8593;&#8595;</span>
                  </div> */}

                  {/* Prizes Wanted */}
                  <div className="trading-marketplace-prizes-container">
                    <h3>Prizes Wanted</h3>
                    <div className="trading-marketplace-prizes">
                      {trade.prizesWanted.map((wanted, index) => (
                        <div key={index} className="trading-marketplace-prize-card">
                          <h4 className="trading-marketplace-prize-name">{wanted.prizeDetails.name}</h4>
                          <span className="trading-marketplace-prize-description">({wanted.prizeDetails.description})</span>
                          <img
                            src={`${backendUrl}/api/images/${wanted.prizeDetails.image}`}
                            alt={wanted.prizeDetails.name}
                            onClick={() => handleImageClick(wanted.prizeDetails)}
                            className="trading-marketplace-prize-image"
                          />
                          <span className="trading-marketplace-prize-quantity">Qty: {wanted.quantity}</span>
                          <span className="trading-marketplace-prize-set-name">({wanted.productDetails.name})</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {
                  trade.userOffer ? (
                    <button
                      className="trading-marketplace-view-offer-button"
                      onClick={() =>
                        setShowOfferPopup({
                          ...trade.userOffer,
                          tradeId: trade._id, // Include the tradeId
                        })
                      }
                    >
                      View Your Offer
                    </button>
                  ) : (
                    <button
                      className="trading-marketplace-offer-trade-button"
                      onClick={() => handleOfferTrade(trade)}
                    >
                      Offer Trade
                    </button>
                  )
                }
              </div>
            ))
          ) : (
            <div className="trading-marketplace-no-trades">
              {searchQuery.trim() 
                ? `No trades found matching "${searchQuery}"`
                : "No active trades available."}
            </div>
          )}
        </div>
      </div>
      {selectedPrize && (
        <ImagePopup
          prize={selectedPrize}
          src={selectedPrize.src}
          onClose={handleClosePopup}
        />
      )}
      {showInventoryModal && (
        <InventorySelectionModal
          onClose={() => setShowInventoryModal(false)}
          onConfirm={handleConfirmOffer}
        />
      )}
      {showConfirmationModal && (
        <ModalPopup
          type="confirmation"
          message="Are you sure you want to submit this trade offer?"
          details={offeredItems} // Pass the offeredItems to display in the modal
          onConfirm={handleSubmitOffer} // Call the API on confirm
          onClose={() => setShowConfirmationModal(false)}
        />
      )}
      {popupMessage && (
        <ModalPopup
          type={popupMessage.type}
          message={popupMessage.message}
          onClose={() => setPopupMessage(null)}
        />
      )}
      {showOfferPopup && (
        <ModalPopup
          type="view-offer"
          message="Your Offer for this Trade"
          details={showOfferPopup.offeredPrizes.map((prize) => ({
            prizeName: prize.prizeDetails.name,
            quantity: prize.quantity,
            setName: prize.setName
          }))}
          onConfirm={() => handleWithdrawOffer(showOfferPopup.tradeId, showOfferPopup._id)}
          onClose={() => setShowOfferPopup(null)}
        />
      )}
    </div>
  );
}

export default TradingMarketplace;