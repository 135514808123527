import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import closeIcon from './closeicon.png';
import PrizeCard from './PrizeCard';
import PlayProductCard from './PlayProductCard';
import LoadingSpinner from './LoadingSpinner';
import PlayInfoPopup from './PlayInfoPopup'; // Changed to PlayInfoPopup
import './PlayPopup.css';

function PlayPopup({ onClose }) {
  const selectedProduct = useSelector((state) => state.product.selectedProduct);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [prizes, setPrizes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchPrizes = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${backendUrl}/api/prizes/${selectedProduct.setId}/${selectedProduct.productId}`);
        const data = await response.json();
        setPrizes(data);
      } catch (error) {
        console.error("Failed to fetch prizes", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedProduct) {
      fetchPrizes();
      // Show popup if there's a promotion or lottery enabled
      if (selectedProduct.lottery?.enabled || selectedProduct.promotion) {
        setShowInfoPopup(true);
      }
    }
  }, [selectedProduct, backendUrl]);

  const handleCloseInfoPopup = () => {
    setShowInfoPopup(false);
  };

  if (isLoading) {
    return (
      <div className="play-popup-loading">
        <button className="play-popup-close-button" onClick={onClose}>
          <img src={closeIcon} alt="Close" />
        </button>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="play-popup popup-overlay">
      {showInfoPopup && (
        <PlayInfoPopup
          onClose={handleCloseInfoPopup}
          promotion={selectedProduct.promotion}
          lottery={selectedProduct.lottery}
        />
      )}
      <button className="play-popup-close-button" onClick={onClose}>
        <img src={closeIcon} alt="Close" />
      </button>
      <PlayProductCard product={selectedProduct} />
      <div className="prize-row">
        {prizes.map(prize => (
          <PrizeCard
            key={prize.id}
            letter={prize.name}
            imageSrc={`${backendUrl}/api/images/${prize.image}`}
            description={prize.description}
            quantity={prize.quantity}
          />
        ))}
      </div>
    </div>
  );
}

export default PlayPopup;