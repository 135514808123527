import { trackError } from './errorTracking';

// Create a utility file for analytics
export const trackEvent = (category, action, label = null, value = null) => {
  try {
    // Only track in production or if specifically enabled in development
    if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENABLE_DEV_ANALYTICS === 'true') {
      if (typeof window !== 'undefined' && window.gtag) {
        // Ensure value is a number if provided
        const numericValue = value ? parseFloat(value) : null;
        
        const eventParams = {
          'event_category': category,
          'event_label': label
        };

        // Only add value if it's a valid number
        if (numericValue !== null && !isNaN(numericValue)) {
          eventParams.value = numericValue;
        }

        window.gtag('event', action, eventParams);
        
        // Log successful tracking in development
        if (process.env.NODE_ENV === 'development') {
          console.log('Event tracked:', { category, action, label, value: numericValue });
        }
      } else {
        console.warn('Google Analytics not initialized');
      }
    } else {
      console.log('Analytics disabled in development mode:', { category, action, label, value });
    }
  } catch (error) {
    console.error('Error tracking event:', error);
    trackError(error, `Analytics Error: ${category} - ${action}`);
  }
};

// Usage example in components:
// import { trackEvent } from '../utils/analytics';

// Track when users add to cart
// trackEvent('Shopping', 'Add to Cart', productName, price);

// // Track when users favorite an item
// trackEvent('Engagement', 'Favorite Item', productId);

// // Track successful purchases
// trackEvent('Shopping', 'Purchase Complete', orderId, totalAmount); 