import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import LoadingSpinner from './LoadingSpinner';
import './AdminProductView.css';

const AdminProductView = () => {
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [showLotteryOrders, setShowLotteryOrders] = useState(false);
  const [editing, setEditing] = useState({
    sku: false,
    quantity: false,
    sold: false,
    status: false,
    arrival: false,
    doubleChance: false,
    promotion: false,
    lottery: {
      completed: false,
      totalTickets: false,
      ticketsLeft: false
    },
  });
  
  const [editedValues, setEditedValues] = useState({
    sku: '',
    quantity: 0,
    sold: 0,
    status: true,
    arrival: '',
    doubleChance: false,
    promotionId: null,
    lottery: {
      enabled: false,
      completed: false,
      totalTickets: 0,
      ticketsLeft: 0
    },
  });

  const [promotions, setPromotions] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [showPreorderConfirmation, setShowPreorderConfirmation] = useState(false);
  const [isUpdatingPreorder, setIsUpdatingPreorder] = useState(false);

  useEffect(() => {
    fetchProductDetails();
  }, [id]);

  useEffect(() => {
    fetchPromotions();
  }, []);

  const fetchProductDetails = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/admin/login');
        return;
      }

      const response = await fetch(`${backendUrl}/api/admin/products/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (response.status === 401 || response.status === 403) {
        navigate('/admin/login');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to fetch product details');
      }

      const data = await response.json();
      setProduct(data);
    } catch (error) {
      console.error('Error fetching product details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPromotions = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/admin/promotions`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminToken')}`,
        }
      });

      if (response.status === 401 || response.status === 403) {
        navigate('/admin/login');
        return;
      }

      if (response.ok) {
        const data = await response.json();
        setPromotions(data);
      } else {
        console.error('Failed to fetch promotions:', response.status);
      }
    } catch (error) {
      console.error('Error fetching promotions:', error);
    }
  };

  useEffect(() => {
    if (product) {
      setEditedValues({
        sku: product.productSku,
        quantity: product.productQuantity,
        sold: product.sold,
        status: product.active,
        arrival: product.arrival || '',
        doubleChance: product.doubleChance,
        promotionId: product.promotion?.id || null,
        lottery: {
          enabled: product.lottery?.enabled || false,
          completed: product.lottery?.completed || false,
          totalTickets: product.lottery?.totalTickets || 0,
          ticketsLeft: product.lottery?.ticketsLeft || 0
        },
      });
    }
  }, [product]);

  const handleEdit = (field, subfield = null) => {
    if (subfield) {
      setEditing(prev => ({
        ...prev,
        [field]: {
          ...prev[field],
          [subfield]: true
        }
      }));
    } else {
      setEditing(prev => ({
        ...prev,
        [field]: true
      }));
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const response = await fetch(`${backendUrl}/api/admin/products/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminToken')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(editedValues)
      });

      if (response.status === 401 || response.status === 403) {
        navigate('/admin/login');
        return;
      }

      console.log(response);

      if (response.ok) {
        // Reset all editing states
        setEditing({
          sku: false,
          quantity: false,
          sold: false,
          status: false,
          arrival: false,
          doubleChance: false,
          promotion: false,
          lottery: {
            completed: false,
            totalTickets: false,
            ticketsLeft: false
          },
        });
        // Refresh product data
        fetchProductDetails();
      }
    } catch (error) {
      console.error('Error saving product:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleResetLottery = () => {
    // Update the values
    setEditedValues(prev => ({
      ...prev,
      lottery: {
        ...prev.lottery,
        enabled: false,
        completed: false,
        totalTickets: 0,
        ticketsLeft: 0,
        orders: []
      }
    }));

    // Set editing state to true for all lottery fields to show they've changed
    setEditing(prev => ({
      ...prev,
      lottery: {
        ...prev.lottery,
        enabled: true,
        completed: true,
        totalTickets: true,
        ticketsLeft: true
      }
    }));
  };

  const handleReloadSet = () => {
    // Get the current SKU and increment its last number
    const currentSku = product.productSku;
    const newSku = incrementSku(currentSku);

    // Calculate quantities array from prizes in order (A to Z, then LAST)
    const prizesQuantities = product.prizes
      .sort((a, b) => {
        if (a.name === 'Last Prize') return 1;
        if (b.name === 'Last Prize') return -1;
        // Extract the letter after 'Prize ' and compare
        const letterA = a.name.replace('Prize ', '');
        const letterB = b.name.replace('Prize ', '');
        return letterA.localeCompare(letterB);
      })
      .map(prize => prize.initialQuantity);

    // Update all the necessary fields
    setEditedValues(prev => ({
      ...prev,
      sku: newSku,
      sold: 0,
      status: true,
      promotionId: null,
      prizesQuantities,
      lottery: {
        enabled: false,
        completed: false,
        totalTickets: 0,
        ticketsLeft: 0,
        orders: []
      }
    }));

    // Show that fields have changed
    setEditing(prev => ({
      ...prev,
      sku: true,
      sold: true,
      status: true,
      promotion: true,
      lottery: {
        ...prev.lottery,
        enabled: true,
        completed: true,
        totalTickets: true,
        ticketsLeft: true
      }
    }));
  };

  // Helper function to increment the SKU
  const incrementSku = (sku) => {
    // Find the last number in the SKU
    const match = sku.match(/^(.*?)(\d+)$/);
    if (!match) {
      console.error('SKU format not recognized');
      return sku;
    }

    const [_, prefix, number] = match;
    const nextNumber = parseInt(number) + 1;
    return `${prefix}${nextNumber}`;
  };

  const handleUpdatePreorder = async () => {
    setShowPreorderConfirmation(true);
  };

  const handleConfirmPreorderUpdate = async () => {
    setIsUpdatingPreorder(true);
    try {
      const response = await fetch(
        `${backendUrl}/api/admin/products/${id}/availability`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
          },
          body: JSON.stringify({
            availability: false,
            updateInventory: true
          })
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update availability');
      }

      // Refresh product data
      fetchProductDetails();
      setShowPreorderConfirmation(false);

    } catch (error) {
      console.error('Error updating availability:', error);
      alert('Failed to update availability. Please try again.');
    } finally {
      setIsUpdatingPreorder(false);
    }
  };

  // Update hasChanges to remove availability check
  const hasChanges = () => {
    if (!product) return false;
    
    return (
      editedValues.sku !== product.productSku ||
      editedValues.quantity !== product.productQuantity ||
      editedValues.sold !== product.sold ||
      editedValues.status !== product.active ||
      editedValues.doubleChance !== product.doubleChance ||
      editedValues.promotionId !== (product.promotion?.id || null) ||
      editedValues.lottery?.completed !== (product.lottery?.completed || false) ||
      editedValues.lottery?.totalTickets !== (product.lottery?.totalTickets || 0) ||
      editedValues.lottery?.ticketsLeft !== (product.lottery?.ticketsLeft || 0) ||
      editedValues.arrival !== (product.arrival || '')
    );
  };

  const renderEditableField = (field, value, type = 'text') => {
    const isLotteryField = field.startsWith('lottery.');
    const fieldPath = field.split('.');
    const currentValue = isLotteryField 
      ? editedValues.lottery[fieldPath[1]]
      : editedValues[field];

    if (editing[fieldPath[0]] && (!isLotteryField || editing.lottery[fieldPath[1]])) {
      return (
        <div className="admin-product-editable-field">
          {type === 'select' ? (
            <select
              value={currentValue}
              onChange={(e) => {
                if (isLotteryField) {
                  setEditedValues(prev => ({
                    ...prev,
                    lottery: {
                      ...prev.lottery,
                      [fieldPath[1]]: e.target.value === 'true'
                    }
                  }));
                } else {
                  setEditedValues(prev => ({...prev, [field]: e.target.value}));
                }
              }}
            >
              {field === 'status' && (
                <>
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </>
              )}
              {field === 'doubleChance' && (
                <>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </>
              )}
              {(field === 'lottery.completed' || field === 'lottery.enabled') && (
                <>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </>
              )}
            </select>
          ) : type === 'number' ? (
            <input
              type="number"
              value={currentValue}
              onChange={(e) => {
                if (isLotteryField) {
                  setEditedValues(prev => ({
                    ...prev,
                    lottery: {
                      ...prev.lottery,
                      [fieldPath[1]]: parseInt(e.target.value)
                    }
                  }));
                } else {
                  setEditedValues(prev => ({...prev, [field]: parseInt(e.target.value)}));
                }
              }}
              min="0"
            />
          ) : (
            <input
              type={type}
              value={currentValue}
              onChange={(e) => {
                if (isLotteryField) {
                  setEditedValues(prev => ({
                    ...prev,
                    lottery: {
                      ...prev.lottery,
                      [fieldPath[1]]: e.target.value
                    }
                  }));
                } else {
                  setEditedValues(prev => ({...prev, [field]: e.target.value}));
                }
              }}
            />
          )}
        </div>
      );
    }

    return (
      <div className="admin-product-editable-field">
        <span>{value}</span>
        <button onClick={() => handleEdit(fieldPath[0], isLotteryField ? fieldPath[1] : null)}>
          <i className="fas fa-edit"></i>
        </button>
      </div>
    );
  };

  const formatSelectedPrizes = (prizes) => {
    // Create a frequency map of prizes
    const prizeCount = prizes.reduce((acc, prize) => {
      acc[prize] = (acc[prize] || 0) + 1;
      return acc;
    }, {});

    // Separate 'LAST' from other prizes
    const hasLast = 'LAST' in prizeCount;
    const lastCount = prizeCount['LAST'] || 0;
    delete prizeCount['LAST'];

    // Convert other prizes to array of "countPrize" format and sort alphabetically by prize letter
    const formattedPrizes = Object.entries(prizeCount)
      .sort((a, b) => a[0].localeCompare(b[0])) // Sort by prize letter
      .map(([prize, count]) => `${count}${prize}`);

    // Add 'LAST' at the end if it exists
    if (hasLast) {
      formattedPrizes.push(`${lastCount}LAST`);
    }

    return formattedPrizes.join(', ');
  };

  if (isLoading) return <LoadingSpinner />;
  if (!product) return <div>Product not found</div>;

  console.log(product);

  return (
    <div>
      <NavBar />
      <div className="admin-product-view">
        <div className="admin-product-header">
          <h1>{product.productName}</h1>
          <div className="admin-product-header-actions">
            <button 
              className="admin-product-reload-button"
              onClick={handleReloadSet}
              title="Reset sold count, status, promotion, lottery, and update quantities"
            >
              <i className="fas fa-sync"></i> Reload Set
            </button>
            {product.preorder && (
              <button 
                className="admin-product-preorder-button"
                onClick={handleUpdatePreorder}
              >
                <i className="fas fa-box"></i> Update Preorder to Instock
              </button>
            )}
            {hasChanges() && (
              <button 
                className="admin-product-save-button"
                onClick={handleSave}
                disabled={isSaving}
              >
                {isSaving ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  <i className="fas fa-save"></i>
                )}
                Save Changes
              </button>
            )}
            <button 
              onClick={() => navigate('/admin/products')} 
              className="admin-product-back-button"
            >
              <i className="fas fa-arrow-left"></i> Back to Products
            </button>
          </div>
        </div>

        {/* Add confirmation popup */}
        {showPreorderConfirmation && (
          <div className="admin-preorder-to-instock-confirmation-popup">
            <div className="admin-preorder-to-instock-confirmation-popup-content">
              <h3>Update to In-stock</h3>
              <p>Are you sure you want to update this product from pre-order to instock?</p>
              <p>This will update all existing inventories to instock with a 2-week shipping timeline.</p>
              <div className="admin-preorder-to-instock-confirmation-popup-buttons">
                {isUpdatingPreorder ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <button 
                      className="admin-preorder-to-instock-confirmation-popup-cancel-button"
                      onClick={() => setShowPreorderConfirmation(false)}
                    >
                      Cancel
                    </button>
                    <button 
                      className="admin-preorder-to-instock-confirmation-popup-confirm-button"
                      onClick={handleConfirmPreorderUpdate}
                    >
                      Confirm
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="admin-product-content">
          <div className="admin-product-main-info">
            <img 
              src={`${backendUrl}/api/images/${product.productImage}`}
              alt={product.productName}
              className="admin-product-image"
            />
            <div className="admin-product-details">
              <h2>Product Information</h2>
              <div className="admin-product-info-grid">
                <div className="admin-product-info-item">
                  <label>SKU</label>
                  {editing.sku ? (
                    <div className="admin-product-editable-field">
                      <input
                        type="text"
                        value={editedValues.sku}
                        onChange={(e) => setEditedValues(prev => ({...prev, sku: e.target.value}))}
                      />
                    </div>
                  ) : (
                    <div className="admin-product-editable-field">
                      <span>{product.productSku}</span>
                      <button onClick={() => handleEdit('sku')}>
                        <i className="fas fa-edit"></i>
                      </button>
                    </div>
                  )}
                </div>
                <div className="admin-product-info-item">
                  <label>Quantity</label>
                  {editing.quantity ? (
                    <div className="admin-product-editable-field">
                      <input
                        type="number"
                        value={editedValues.quantity}
                        onChange={(e) => setEditedValues(prev => ({...prev, quantity: parseInt(e.target.value)}))}
                        min="0"
                      />
                    </div>
                  ) : (
                    <div className="admin-product-editable-field">
                      <span>{product.productQuantity}</span>
                      <button onClick={() => handleEdit('quantity')}>
                        <i className="fas fa-edit"></i>
                      </button>
                    </div>
                  )}
                </div>
                <div className="admin-product-info-item">
                  <label>Sold</label>
                  {renderEditableField('sold', product.sold, 'number')}
                </div>
                <div className="admin-product-info-item">
                  <label>Status</label>
                  {renderEditableField('status', product.active ? 'Active' : 'Inactive', 'select')}
                </div>
                <div className="admin-product-info-item">
                  <label>Availability</label>
                  {editing.availability ? (
                    <div className="admin-product-editable-field">
                      <span>{product.preorder ? 'Pre-order' : 'In Stock'}</span>
                    </div>
                  ) : (
                    <div className="admin-product-editable-field">
                      <span>{product.preorder ? 'Pre-order' : 'In Stock'}</span>
                    </div>
                  )}
                </div>
                {editedValues.availability && (
                  <div className="admin-product-info-item">
                    <label>Arrival</label>
                    {editing.arrival ? (
                      <div className="admin-product-editable-field">
                        <input 
                          type="text" 
                          value={editedValues.arrival || ''}
                          onChange={(e) => setEditedValues(prev => ({...prev, arrival: e.target.value}))}
                          placeholder="e.g., Jun 2025"
                        />
                      </div>
                    ) : (
                      <div className="admin-product-editable-field">
                        <span>{product.arrival || 'Not specified'}</span>
                        <button onClick={() => handleEdit('arrival')}>
                          <i className="fas fa-edit"></i>
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="admin-product-features">
            <h2>Features</h2>
            <div className="admin-product-features-grid">
              <div className="admin-product-feature-item">
                <label>Double Chance</label>
                {renderEditableField('doubleChance', product.doubleChance ? 'Yes' : 'No', 'select')}
              </div>
              <div className="admin-product-feature-item">
                <label>Promotion</label>
                {editing.promotion ? (
                  <div className="admin-product-editable-field">
                    <select
                      value={editedValues.promotionId || ''}
                      onChange={(e) => setEditedValues(prev => ({
                        ...prev, 
                        promotionId: e.target.value === '' ? null : e.target.value
                      }))}
                    >
                      <option value="">No Promotion</option>
                      {promotions.map(promo => (
                        <option key={promo._id} value={promo._id}>
                          {promo.description}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div className="admin-product-editable-field">
                    <span>{product.promotion ? product.promotion.description : 'No Active Promotion'}</span>
                    <button onClick={() => handleEdit('promotion')}>
                      <i className="fas fa-edit"></i>
                    </button>
                  </div>
                )}
              </div>
              <div className="admin-product-feature-item">
                <label>Lottery</label>
                <div className="admin-product-lottery-details">
                  <div className="admin-product-lottery-header">
                    <h3>Lottery Configuration</h3>
                    <button 
                      className="admin-product-lottery-reset-button"
                      onClick={handleResetLottery}
                      title="Reset all lottery settings"
                    >
                      <i className="fas fa-redo"></i> Reset Lottery
                    </button>
                  </div>
                  <div className="admin-product-lottery-field">
                    <label>Enabled</label>
                    {renderEditableField('lottery.enabled', product.lottery?.enabled ? 'Yes' : 'No', 'select')}
                  </div>
                  <div className="admin-product-lottery-field">
                    <label>Completed</label>
                    {renderEditableField('lottery.completed', product.lottery?.completed ? 'Yes' : 'No', 'select')}
                  </div>
                  <div className="admin-product-lottery-field">
                    <label>Total Tickets</label>
                    {renderEditableField('lottery.totalTickets', product.lottery?.totalTickets || 0, 'number')}
                  </div>
                  <div className="admin-product-lottery-field">
                    <label>Tickets Left</label>
                    {renderEditableField('lottery.ticketsLeft', product.lottery?.ticketsLeft || 0, 'number')}
                  </div>
                  
                  {product.lottery?.enabled && (
                    <div className="admin-product-lottery-orders-section">
                      <button 
                        className="admin-product-lottery-orders-toggle"
                        onClick={() => setShowLotteryOrders(!showLotteryOrders)}
                      >
                        Orders <i className={`fas fa-${showLotteryOrders ? 'minus' : 'plus'}`}></i>
                      </button>
                      
                      {showLotteryOrders && (
                        <div className="admin-product-lottery-orders-list">
                          {product.lottery.orders.map((order, index) => (
                            <div key={index} className="admin-product-lottery-order-item">
                              <span>Order ID: {order.orderId}</span>
                              <span>User: {order.username}</span>
                              <span>Prizes: {formatSelectedPrizes(order.selectedPrizes)}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="admin-product-prizes">
            <h2>Prizes</h2>
            <div className="admin-product-prizes-grid">
              {product.prizes.map((prize, index) => (
                <div key={index} className="admin-product-prize-card">
                  <img 
                    src={`${backendUrl}/api/images/${prize.image}`}
                    alt={prize.name}
                    className="admin-product-prize-image"
                  />
                  <div className="admin-product-prize-info">
                    <h3>{prize.name}</h3>
                    <p>{prize.description}</p>
                    <div className="admin-product-prize-quantities">
                      <span className="admin-product-prize-quantity">
                        Remaining: {prize.remainingQuantity}/{prize.initialQuantity}
                      </span>
                      <div className="admin-product-prize-quantity-progress-bar">
                        <div 
                          className="admin-product-prize-quantity-progress" 
                          style={{ 
                            width: `${(prize.remainingQuantity / prize.initialQuantity) * 100}%`,
                            backgroundColor: prize.remainingQuantity === 0 ? '#dc3545' : '#28a745'
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProductView; 