import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PurchaseNotification from '../PurchaseNotification';

const WebSocketContext = createContext(null);

export function WebSocketProvider({ children }) {
  const [notifications, setNotifications] = useState([]);
  const [ws, setWs] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const wsUrl = backendUrl.replace('http', 'ws');
  
  // Get current user's username from Redux store
  const currentUsername = useSelector(state => state.user.username);

  // Create WebSocket connection
  const connectWebSocket = useCallback(() => {
    console.log('Attempting to connect to WebSocket...');
    const websocket = new WebSocket(`${wsUrl}/ws`);

    websocket.onopen = () => {
      console.log('WebSocket connected');
    };

    websocket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        
        if (data.type === 'purchase' && data.username !== currentUsername) {
          setNotifications(prev => [...prev, {
            id: Date.now(),
            message: `Someone just bought ${data.productName}`
          }]);
        } else if (data.type === 'connected') {
          console.log('WebSocket connection confirmed:', data.message);
        }
      } catch (error) {
        console.error('Error processing WebSocket message:', error);
      }
    };

    websocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    websocket.onclose = (event) => {
      console.log('WebSocket disconnected:', event);
      setWs(null);
      // Attempt to reconnect after 5 seconds
      setTimeout(connectWebSocket, 5000);
    };

    setWs(websocket);
  }, [wsUrl, currentUsername]);

  // Initialize WebSocket connection
  useEffect(() => {
    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [connectWebSocket]);

  const removeNotification = (id) => {
    setNotifications(prev => prev.filter(n => n.id !== id));
  };

  return (
    <WebSocketContext.Provider value={{ notifications, removeNotification }}>
      {children}
      {notifications.map(notification => (
        <PurchaseNotification
          key={notification.id}
          message={notification.message}
          onClose={() => removeNotification(notification.id)}
        />
      ))}
    </WebSocketContext.Provider>
  );
}

export function useWebSocket() {
  return useContext(WebSocketContext);
} 