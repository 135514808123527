import React, { useState, useEffect } from 'react';
import ticketIcon from './ticket.png';
import duckIcon from './duck.png';
import tokenIcon from './surprize_token.png';
import starIcon from './star.png';
import './ProductCard.css';
import LoadingSpinner from './LoadingSpinner';
import { useSelector } from 'react-redux';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import LoginReminderPopup from './LoginReminderPopup';
import { useNavigate } from 'react-router-dom';
import FavoriteSuccessPopup from './FavoriteSuccessPopup';
import { trackEvent } from './utils/analytics';

function ProductCard({ product, onPlayClick, onImageLoad }) {

  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [isImageLoading, setIsImageLoading] = useState(true); // State to track image loading
  const [isFavorite, setIsFavorite] = useState(false);
  const [showLoginReminder, setShowLoginReminder] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [popupType, setPopupType] = useState('favorite'); // Add this state
  const username = useSelector(state => state.user.username);
  const token = localStorage.getItem('token');

  let lotteryEnabled = false;

  if (product.lottery) {
    if (product.lottery.enabled) {
      lotteryEnabled = true;
    }
  }

  useEffect(() => {
    // Check if this product is in user's favorites
    const checkFavoriteStatus = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/users/favorites/check/${product.setId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        setIsFavorite(data.isFavorite);
      } catch (error) {
        console.error('Error checking favorite status:', error);
      }
    };

    if (username) {
      checkFavoriteStatus();
    }
  }, [product.setId, backendUrl, token, username]);

  const handleFavoriteClick = async (e) => {
    e.stopPropagation(); // Prevent triggering other click events
    if (!username) {
      // Show login reminder popup instead of alert
      setShowLoginReminder(true);
      return;
    }

    try {
      const response = await fetch(`${backendUrl}/api/users/favorites/${product.setId}`, {
        method: isFavorite ? 'DELETE' : 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 403) {
        setShowLoginReminder(true);
        return;
      }

      if (response.ok) {
        setIsFavorite(!isFavorite);
        setPopupType(isFavorite ? 'unfavorite' : 'favorite');
        setShowSuccessPopup(true);
      }
      
    } catch (error) {
      console.error('Error updating favorite:', error);
    }
  };

  const handleLoginRedirect = () => {
    navigate('/login', { state: { from: window.location.pathname } });
    setShowLoginReminder(false);
  };

  const handlePlayClick = () => {
    // Track the view product event
    trackEvent(
      'Product',
      'View Product',
      product.name,
      product.price
    );
    
    // Call the existing onPlayClick handler
    onPlayClick(product);
  };

  return (
    <div>
        <div className="product-banner" key={product.setId}>

        <div className="product-title-box">
          <span className="product-title-text">{product.name}</span>
        </div>

        {isImageLoading && <LoadingSpinner />}

        <div className="product-image-wrapper">
          <img
            src={`${backendUrl}/api/images/${product.image}`}
            alt={product.name}
            className={`product-poster ${isImageLoading ? 'hidden' : ''}`}
            onLoad={() => setIsImageLoading(false)} // Set loading to false when image has loaded
          />
        </div>
        {/* {
          product.promotion
          ? (            
            <div className="product-text-box-promotion">
              {
                product.promotion.type === 'BUY_X_GET_Y_FREE'
                ? <p className="product-card-text-top promotion">PROMOTION: Buy {product.promotion.details.requiredQuantity} Get {product.promotion.details.freeQuantity} FREE</p>
                : null
              }
              {
                product.promotion.type === 'CHALLENGE'
                ? <p className="product-card-text-top challenge">CHALLENGE: Buy {product.promotion.details.minimumQuantity} tickets, Get {product.promotion.details.challengeAlphabet} to get ALL prizes (inc. Last Prize)!</p>
                : null
              }
              {
                product.preorder
                ? <p className="product-card-text status">PRE-ORDER</p>
                : <p className="product-card-text status">INSTOCK</p>
              }
              {
                product.doubleChance 
                ? <p className="product-card-text doublechance">Double Chance: Available</p>
                : <p className="product-card-text doublechance">Double Chance: Not Available</p>
              }
            </div>
          )
          : lotteryEnabled
          ? (            
            <div className="product-text-box-lottery">
              <p className="product-card-text-top lottery">CHANCE: Every ticket gets a chance to win the Last Prize.</p>
              {
                product.preorder
                ? <p className="product-card-text status">PRE-ORDER</p>
                : <p className="product-card-text status">INSTOCK</p>
              }
              {
                product.doubleChance 
                ? <p className="product-card-text doublechance">Double Chance: Available</p>
                : <p className="product-card-text doublechance">Double Chance: Not Available</p>
              }
            </div>
          )
          : (
            <div className="product-text-box">
            {
              product.preorder
              ? <p className="product-card-text-top status">PRE-ORDER</p>
              : <p className="product-card-text-top status">INSTOCK</p>
            }
            {
              product.doubleChance 
              ? <p className="product-card-text doublechance">Double Chance: Available</p>
              : <p className="product-card-text doublechance">Double Chance: Not Available</p>
            }
            </div>
          )
        } */}
        <div className="price-tag">
            <span className="token-price">{product.token}<img src={tokenIcon} alt="Token" className="product-token-icon" /> </span>
            <span>${product.price}</span>
        </div>
        <div className={`items-left-tag ${lotteryEnabled ? 'lottery-enabled' : ''} ${product.promotion ? 'promotion-enabled' : ''}`}>
          <img 
            src={
              lotteryEnabled 
              ? duckIcon 
              : product.promotion 
              ? starIcon
              : ticketIcon
            } 
            alt="Icon" 
            className="ticket-icon" 
          />
          <div className="product-card-items-left-tag-chance">
            { lotteryEnabled ? <span className="product-card-chance-label">CHANCE!</span> : null }
            {product.promotion && product.promotion.type === 'BUY_X_GET_Y_FREE' && (
              <span className="product-card-promotion-label">
                Buy {product.promotion.details.requiredQuantity} Get {product.promotion.details.freeQuantity} FREE!
              </span>
            )}  
            {product.promotion && product.promotion.type === 'CHALLENGE' && (
              <span className="product-card-promotion-label">
                CHALLENGE (Get {product.promotion.details.challengeAlphabet})!
              </span>
            )}            
            <span>{product.quantity}</span>
          </div>
        </div>
        {/* Check if a promotion exists and it's a BUY_X_GET_Y_FREE type
        <div className="promotion-container">
        {product.promotion && product.promotion.type === 'BUY_X_GET_Y_FREE' && (
          <div className="promotion-tag">
            PROMOTION!<br/>
            Buy {product.promotion.details.requiredQuantity} Get {product.promotion.details.freeQuantity} FREE
          </div>
        )}
        </div> */}
        <button 
          className="product-play-button" 
          onClick={handlePlayClick}
        >
          Play
        </button>
        <button 
          className={`favorite-button ${isFavorite ? 'favorite-active' : ''}`}
          onClick={handleFavoriteClick}
        >
          {isFavorite ? <FaHeart /> : <FaRegHeart />}
        </button>
        </div>
        {showLoginReminder && (
          <LoginReminderPopup 
            message="Please log in to favorite sets"
            onClose={() => setShowLoginReminder(false)}
            onLogin={handleLoginRedirect}
          />
        )}

        {showSuccessPopup && (
          <FavoriteSuccessPopup 
            message={
              popupType === 'favorite'
                ? `You have favorited "${product.name}". You will receive in-app notifications when other users purchase tickets.`
                : `You will no longer receive notifications for "${product.name}".`
            }
            type={popupType}
            onClose={() => setShowSuccessPopup(false)}
          />
        )}
    </div>
  );
}

export default ProductCard;