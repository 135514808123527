import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import LoadingSpinner from './LoadingSpinner';
import { Dropdown } from 'react-bootstrap';
import './AdminProducts.css';

const AdminProducts = () => {
  const [sets, setSets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    setIsLoading(true);
    const timeoutId = setTimeout(() => {
      fetchSets(currentPage, searchQuery);
    }, searchQuery ? 300 : 0);

    return () => clearTimeout(timeoutId);
  }, [currentPage, searchQuery]);

  const fetchSets = async (page, search) => {
    try {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/admin/login');
        return;
      }

      const queryParams = new URLSearchParams({
        page,
        search: search || ''
      });

      const response = await fetch(`${backendUrl}/api/admin/sets?${queryParams}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (response.status === 401 || response.status === 403) {
        navigate('/admin/login');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to fetch sets');
      }

      const data = await response.json();
      setSets(data.sets);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching sets:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setIsLoading(true);
    setCurrentPage(newPage);
  };

  const handleSearch = (value) => {
    setIsLoading(true);
    setSearchQuery(value);
    setCurrentPage(1);
  };

  return (
    <div>
      <NavBar />
      <div className="admin-products-container">
        <div className="admin-products-header">
          <div className="admin-products-header-left">
            <button 
              className="admin-products-back-to-menu-button"
              onClick={() => navigate('/admin/menu')}
            >
              <i className="fas fa-arrow-left"></i> Back to Menu
            </button>
            <h1>Products</h1>
          </div>
          <button 
            className="admin-products-add-product-button"
            onClick={() => navigate('/admin/add-product')}
          >
            <i className="fas fa-plus"></i> Add New Product
          </button>
        </div>

        <div className="admin-products-controls">
          <div className="admin-products-search-box">
            <i className="fas fa-search"></i>
            <input
              type="text"
              placeholder="Search products..."
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="admin-products-table-container">
          {isLoading ? (
            <div className="admin-products-loading-container">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <table className="admin-products-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>SKU</th>
                    <th>Set Qty</th>
                    <th>Selling Qty</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Arrival</th>
                    <th>Total Sold</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sets.map((set) => (
                    <tr key={set.id}>
                      <td>{set.productName}</td>
                      <td>{set.productSku}</td>
                      <td>{set.productQuantity}</td>
                      <td>{set.productQuantity - set.sold}</td>
                      <td>
                        <span className={`admin-products-status-badge ${set.active ? 'active' : 'inactive'}`}>
                          {set.active ? 'Active' : 'Inactive'}
                        </span>
                      </td>
                      <td>
                        <span className={`admin-products-type-badge ${set.preorder ? 'preorder' : 'instock'}`}>
                          {set.preorder ? 'Pre-order' : 'In Stock'}
                        </span>
                      </td>
                      <td>
                        {set.preorder && set.arrival ? 
                          set.arrival : 
                          '-'}
                      </td>
                      <td>
                        {set.totalSold}
                      </td>
                      <td>
                        <button 
                          className="admin-products-action-button"
                          onClick={() => navigate(`/admin/product/${set.id}`)}
                          title="View Product"
                        >
                          <i className="fas fa-eye"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="admin-products-pagination">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1 || isLoading}
                  className="admin-products-pagination-button"
                >
                  <i className="fas fa-chevron-left"></i> Previous
                </button>
                
                <span className="admin-products-pagination-info">
                  Page {currentPage} of {totalPages}
                </span>
                
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages || isLoading}
                  className="admin-products-pagination-button"
                >
                  Next <i className="fas fa-chevron-right"></i>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminProducts; 