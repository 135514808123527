import React, { useEffect } from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import './ShippingAndDelivery.css'; // Ensure you have this CSS file for styling

function ShippingAndDelivery() {
    
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, [])

    return (
        <div>
            <NavBar />
            <div className='gradient-background'>
                <div style={styles.container}>
                    <h1 style={styles.title}>Shipping and Delivery</h1>
                    <div style={styles.textContent}>
                        <p>We use Qxpress to offer reliable and efficient local shipping in Singapore. Here are the key points regarding our shipping and delivery policy:</p>

                        <h2>Standard Shipping</h2>
                        <p>All standard shipping is handled through Qxpress and takes an estimated 2 to 4 working days to arrive. For every 2 big prizes and 20 small prizes, a shipping fee of $5 will be applied.</p>
                        
                        <h2>Shipping Notifications</h2>
                        <p>Once you arrange shipment for your items, we will ship it out using Qxpress and you will receive updates through SMS and email associated with your user profile. This ensures you are fully informed about the status of your delivery from dispatch to arrival.</p>
                        
                        <h2>Important Notes</h2>
                        <ul>
                            <li>Shipping fees are calculated and added at the time you choose to arrange delivery for your orders.</li>
                            <li>We strive to ensure the accuracy of shipping times; however, please note that delivery times may vary due to external factors beyond our control.</li>
                        </ul>

                        <p>If you have any questions or concerns about our shipping practices, please contact us through the information provided on our Contact Us page.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

const styles = {
    container: {
        padding: '20px',
        textAlign: 'left',
        backgroundColor: '#f5f5f5',
        minHeight: 'calc(100vh - 120px)', // Adjust the height based on your header/footer size
    },
    title: {
        textAlign: 'center',
        color: '#333',
    },
    textContent: {
        margin: '20px',
        lineHeight: '1.6',
        fontSize: '16px',
        color: '#666',
    }
};

export default ShippingAndDelivery;