import React, { useState, useEffect } from 'react';
import './LoginReminderPopup.css';

function LoginReminderPopup({ onLogin, onClose }) {
    return (
      <div className="login-reminder-popup popup-overlay">
        <div className="login-reminder-card">
          <p className="login-reminder-text">Please login first.</p>
          <div className="login-reminder-button-group">
            <button className="login-reminder-login-button" onClick={onLogin}>Login</button>
            <button className="login-reminder-close-button" onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    );
  }
  
export default LoginReminderPopup;