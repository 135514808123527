import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { countries } from 'countries-list';
import LoadingSpinner from './LoadingSpinner'; // Adjust the path as necessary
import { setPaymentState } from './redux/slices/paymentSlice';

function ShipmentPopup({ selectedItems, onCancel, userShippingAddress, setShowShipmentPopup }) {

    console.log(userShippingAddress);

    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [useProfileAddress, setUseProfileAddress] = useState(true);
    const [country, setCountry] = useState('SG'); // Default to Singapore
    const [postalCode, setPostalCode] = useState('');
    const [fullAddress, setFullAddress] = useState('');
    const [unitNumber, setUnitNumber] = useState('');
    const [fullName, setFullName] = useState('');
    const [isPostalCodeValid, setIsPostalCodeValid] = useState(true); // Default to true for initial load
    const [isFullAddressValid, setIsFullAddressValid] = useState(false);
    const [isUnitNumberValid, setIsUnitNumberValid] = useState(false);
    const [isFullNameValid, setIsFullNameValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false); // New state for error messages
    const [showExpiredModal, setShowExpiredModal] = useState(false);
    const [expiredError, setExpiredError] = useState('');

    const isShippingAddressComplete = () => {
        const address = useProfileAddress ? userShippingAddress : {
          fullName, country, postalCode, fullAddress, unitNumber
        };
        return Object.values(address).every(value => value !== '');
    };

    const countryOptions = Object.keys(countries).map((countryCode) => ({
        code: countryCode,
        name: countries[countryCode].name,
    }));

    const handleChangeCheckbox = (useProfile) => {
        setUseProfileAddress(useProfile);
        if (useProfile) {
          setIsPostalCodeValid(true); // Reset validation state when switching back to profile address
        } else {
          setIsPostalCodeValid(false);
        }
    };
    
    const handlePostalCodeChange = (value) => {
        setPostalCode(value);
        const isValid = /^\d{6}$/.test(value); // Simple regex for Singapore postal code validation
        setIsPostalCodeValid(isValid);
    };

    const handleFullAddressChange = (value) => {
        setFullAddress(value);
        setIsFullAddressValid(value.trim().length > 0); // Check that some non-whitespace characters are entered
    };
    
    const handleUnitNumberChange = (value) => {
        setUnitNumber(value);
        setIsUnitNumberValid(value.trim().length > 0); // Similarly check for non-whitespace characters
    };

    const handleFullNameChanged = (value) => {
        setFullName(value);
        setIsFullNameValid(value.trim().length > 0); // Check that some non-whitespace characters are entered
    };

    const handleConfirmShipment = async () => {
        setIsLoading(true); // Start loading
        setError(false);
        setExpiredError('');
        
        if (useProfileAddress && !isShippingAddressComplete()) {
            alert("Please update your profile shipping address to proceed.");
            navigate('/user-profile', { state: { editMode: true } });
            return;
        }
        
        // Prepare the data payload for the shipment request
        let data = {
            inventoryItems: selectedItems,
            useProfileAddress,
        };
    
        if (!useProfileAddress) {
            data = {
                ...data,
                fullName: fullName,
                country: country,
                postalCode: postalCode,
                fullAddress: fullAddress,
                unitNumber: unitNumber
            };
        }
    
        try {
            const response = await fetch(`${backendUrl}/api/payment/shipping/create-intent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                credentials: 'include'
            });
    
            const dataResponse = await response.json();

            if (!response.ok) {
                console.log("dataresponse:",dataResponse);
                if (dataResponse.error === 'EXPIRED_SHIPPING') {
                    setExpiredError(dataResponse.message);
                    setShowExpiredModal(true);
                } else {
                    throw new Error('Failed to create shipment');
                }
                setIsLoading(false);
                return;
            }
    
            if (dataResponse.clientSecret) {
                dispatch(setPaymentState({
                    clientSecret: dataResponse.clientSecret, 
                    shipmentId: dataResponse.shipmentId, 
                    shipmentItems: selectedItems,
                    shippingBreakdown: dataResponse.shippingBreakdown
                }));
            }
    
            setShowShipmentPopup(false);
            navigate('/shipping-payment');
        } catch (error) {
            console.error('Error confirming shipment:', error);
            setError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdateProfileClick = () => {
        navigate('/user-profile');
    };    

  return (
    <div className="shipment-popup popup-overlay">
      <div className="shipment-card">
        <h3 className="arrange-shipment-title">Arrange Shipment</h3>
        <div className="checkboxes">
          <label className="profile-address-checkbox">
            <input
              type="checkbox"
              checked={useProfileAddress}
              onChange={() => handleChangeCheckbox(true)}
            /> Use Profile Shipping Address
          </label>
          <label className="another-address-checkbox">
            <input
              type="checkbox"
              checked={!useProfileAddress}
              onChange={() => handleChangeCheckbox(false)}
            /> Use Another Shipping Address
          </label>
        </div>
        {/* Additional UI elements for address input could go here */}
        {useProfileAddress && (
            <div className='shipment-profile-shipping-address'>
                <strong className="shipment-shipping-address-title">Shipping Address:</strong>
                <div className='shipment-profile-fullname'>Full Name: {userShippingAddress.fullName}</div>
                <div className='shipment-profile-country'>Country: {userShippingAddress.country}</div>
                <div className='shipment-profile-postalcode'>Postal Code: {userShippingAddress.postalCode}</div>
                <div className='shipment-profile-fulladdress'>Full Address: {userShippingAddress.fullAddress}</div>
                <div className='shipment-profile-unitnumber'>Unit Number: {userShippingAddress.unitNumber}</div>
                <div className="button-div">
                {!isShippingAddressComplete() && (
                <button
                    className="update-shipping-address-button"
                    onClick={() => navigate('/user-profile', { state: { editMode: true } })}
                >
                    Please update your profile shipping address to proceed.
                </button>
                )}
                </div>
            </div>
        )}
        {!useProfileAddress && (
            <div className='shipment-another-shipping-address'>
                <strong className="shipment-another-shipping-address-title">Shipping Address:</strong>
                <div className="shipment-input-group">
                <label htmlFor="fullName">Full Name:</label>
                <input
                    id="fullName"
                    type="text"
                    value={fullName}
                    onChange={(e) => handleFullNameChanged(e.target.value)}
                    className={!isPostalCodeValid ? 'invalid' : ''}
                />
                </div>
                <div className="shipment-input-group">
                <label>Country:</label>
                <select value={country} onChange={(e) => setCountry(e.target.value)}>
                    {countryOptions.map(({ code, name }) => (
                    <option key={code} value={code}>{name}</option>
                    ))}
                </select>
                </div>
                <div className="shipment-input-group">
                <label htmlFor="postalCode">Postal Code:</label>
                <input
                    id="postalCode"
                    type="text"
                    value={postalCode}
                    onChange={(e) => handlePostalCodeChange(e.target.value)}
                    className={!isPostalCodeValid ? 'invalid' : ''}
                />
                </div>
                <div className="shipment-input-group">
                <label htmlFor="fullAddress">Full Address:</label>
                <input
                    id="fullAddress"
                    type="text"
                    value={fullAddress}
                    onChange={(e) => handleFullAddressChange(e.target.value)}
                />
                </div>
                <div className="shipment-input-group">
                <label htmlFor="unitNumber">Unit Number:</label>
                <input
                    id="unitNumber"
                    type="text"
                    value={unitNumber}
                    onChange={(e) => handleUnitNumberChange(e.target.value)}
                />
                </div>
            </div>
        )}
        {showExpiredModal && (
            <div className="expired-modal popup-overlay">
                <div className="expired-modal-content">
                    <h3>Shipping Expired</h3>
                    {expiredError.split('\n').map((line, index) => (
                        <p key={index}>{line}</p>
                    ))}
                    <div className="expired-modal-buttons">
                        <button 
                            className="expired-modal-close-button"
                            onClick={() => setShowExpiredModal(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        )}
        {error && (
            <div className="error-message">
                <p>Please <span onClick={handleUpdateProfileClick} style={{color: 'blue', cursor: 'pointer', textDecoration: 'underline'}}>update</span> your profile address and phone number.</p>
            </div>
        )}
        {isLoading ? (
            <LoadingSpinner />
            ) : (
            <>
                <button
                className='confirm-shipment-button'
                onClick={handleConfirmShipment}
                disabled={
                    (!useProfileAddress && (!isPostalCodeValid || !isFullAddressValid || !isUnitNumberValid || !isFullNameValid)) || (!isShippingAddressComplete())
                }
                >
                Confirm Shipment
                </button>
                <button className='cancel-shipment-button' onClick={onCancel}>Cancel</button>
            </>
        )}
      </div>
    </div>
  )
}

export default ShipmentPopup;