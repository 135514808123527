import React from 'react';
import './RefreshPopup.css';

function RefreshPopup({ onClose }) {
    return (
      <div className="refresh-popup popup-overlay">
        <div className="refresh-popup-content">
          <p className="refresh-text">The number of tickets available has changed.</p>
          <p className='refresh-text'>Please refresh the page.</p>
          <button className='refresh-close-button' onClick={onClose}>Close</button>
        </div>
      </div>
    );
}  

export default RefreshPopup;