import React from 'react';
import './TopUpTokensPopup.css';

function TopUpTokensPopup({ onTopUp, onProceed, tokensLacking }) {
  return (
    <div className="topup-popup-overlay popup-overlay">
      <div className="topup-popup-content">
        <h2>Not enough tokens</h2>
        <p>You need {tokensLacking} more tokens to complete this purchase! Would you like to top up your tokens?</p>
        <div className="topup-popup-buttons">
          <button onClick={onTopUp} className="popup-button-topup">Top Up</button>
          <button onClick={onProceed} className="popup-button-proceed">Other Payments</button>
        </div>
      </div>
    </div>
  );
}

export default TopUpTokensPopup;