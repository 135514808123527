export const trackError = (error, errorInfo) => {
  try {
    if (typeof window !== 'undefined' && window.gtag) {
        const errorEvent = {
        'event_category': 'Error',
        'event_label': error.message || 'Unknown error',
        'value': 0,
        'error_info': errorInfo,
        'error_stack': error.stack,
        'timestamp': new Date().toISOString()
      };

      window.gtag('event', 'error', errorEvent);

      // Log errors in development
      if (process.env.NODE_ENV === 'development') {
        console.error('Error tracked:', errorEvent);
      }
    } else {
      console.warn('Google Analytics not initialized');
    }
  } catch (trackingError) {
    // Fallback error logging if tracking fails
    console.error('Error tracking failed:', {
      originalError: error,
      trackingError: trackingError,
      errorInfo: errorInfo
    });
  }
};

// Helper function to handle async errors
export const trackAsyncError = async (asyncFn, errorContext) => {
  try {
    return await asyncFn();
  } catch (error) {
    trackError(error, errorContext);
    throw error; // Re-throw to allow further error handling
  }
};

// // Usage in error boundaries or try-catch blocks
// try {
//   // ... your code
// } catch (error) {
//   trackError(error, 'Shopping Cart Error');
// } 