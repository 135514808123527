import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Fuse from 'fuse.js';
import NavBar from './NavBar';
import './OrdersPage.css';
import LoadingSpinner from './LoadingSpinner'; // Adjust the path as necessary
import ShipmentPopup from './ShipmentPopup';
import ShipmentSuccessPopup from './ShipmentSuccessPopup';
import tokenIcon from './surprize_token.png';
import Footer from './Footer';

function OrdersPage() {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [showShipmentPopup, setShowShipmentPopup] = useState(false);
  const [userShippingAddress, setUserShippingAddress] = useState({});
  const [showShipmentSuccess, setShowShipmentSuccess] = useState(false);
  const [itemLimitExceeded, setItemLimitExceeded] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const ordersPerPage = 10;

  const location = useLocation();
  const navigate = useNavigate();
  const username = useSelector(state => state.user.username);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    if (location.state?.shipmentSuccess) {
      navigate(location.pathname, { state: {} });
      setShowShipmentSuccess(true);
    }
  }, [location, navigate]);

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true); // Start loading
      try {
        const response = await fetch(`${backendUrl}/api/order/user/${username}`, {
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          setOrders(data.orders);
          setFilteredOrders(data.orders);
        } else {
          console.error('Failed to fetch orders');
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (username) {
      fetchOrders();
    }
  }, [username, backendUrl]);

  // First, filter orders based on search query
  useEffect(() => {
    const handleSearch = () => {
      if (!searchQuery.trim()) {
        setFilteredOrders(orders);
        return;
      }

      const fuse = new Fuse(orders, {
        keys: ['orderId', 'productName'],
        includeScore: false,
        threshold: 0.4,
      });

      const result = fuse.search(searchQuery);
      setFilteredOrders(result.map(({ item }) => item));
    };

    handleSearch();
  }, [searchQuery, orders]);

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize to the start of the day

  // // Then, apply the checkbox/shipping filter to the searched results
  // const finalFilteredOrders = filteredOrders.filter(order => {
  //   // Determine the effective shipByDate
  //   let shipByDate;
  //   if (order.shipByDate) {
  //     shipByDate = new Date(order.shipByDate);
  //   } else if (order.dateTimeCreated) {
  //     shipByDate = new Date(order.dateTimeCreated);
  //     shipByDate.setDate(shipByDate.getDate() + 14); // Add 14 days for orders without a shipByDate
  //   }

  //   // Normalize shipByDate for day comparison
  //   if (shipByDate) {
  //     shipByDate.setHours(0, 0, 0, 0);
  //   }

  //   return (
  //     !showCheckboxes || // Always show all orders when not in batch shipping mode
  //     (
  //       !order.shipped && // Exclude shipped orders
  //       !order.preorder && // Exclude preorders
  //       (!shipByDate || shipByDate >= today) // Only include orders with a valid shipByDate that is today or in the future
  //     )
  //   );
  // });

  // const fetchUserShippingAddress = async () => {
  //   try {
  //     const response = await fetch(`${backendUrl}/api/users/shipping-address/${username}`, {
  //       method: 'GET',
  //       credentials: 'include', // for session-based authentication
  //       headers: {
  //         'Authorization': `Bearer ${localStorage.getItem('token')}` // Assuming token is stored in localStorage
  //       },
  //     });

  //     if (response.ok) {
  //       const address = await response.json();
  //       setUserShippingAddress(address);
  //     } else {
  //       console.error('Failed to fetch user shipping address');
  //     }
  //   } catch (error) {
  //     console.error('Error fetching user shipping address:', error);
  //   }
  // };

  const handleCardClick = (order) => {
    // Navigate to the detailed order page and pass the order details as state
    navigate(`/order-details/${order.orderId}`, { state: { order } });
  };

  // const handleSelectOrder = (orderId) => {
  //   setSelectedOrders((prevSelected) =>
  //     prevSelected.includes(orderId)
  //       ? prevSelected.filter((id) => id !== orderId)
  //       : [...prevSelected, orderId]
  //   );
  // };

  // const handleCancelBatchShipping = () => {
  //   setShowCheckboxes(false);
  // };

  // const handleConfirmBatchShipping = async () => {
  //   try {
  //     const response = await fetch(`${backendUrl}/api/shipment/check-item-limit`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ orderIds: selectedOrders }),
  //     });

  //     const data = await response.json();

  //     if (response.status === 400) {
  //       // If item limit exceeded, show popup
  //       setItemLimitExceeded(true);
  //       setTotalItems(data.totalItems);
  //     } else {
  //       fetchUserShippingAddress();
  //       // Proceed with batch shipping if item limit is within bounds
  //       setShowShipmentPopup(true);
  //     }
  //   } catch (error) {
  //     console.error('Error checking item limit:', error);
  //   }
  // };

  // const handleCloseShipmentSuccessPopup = () => {
  //   setShowShipmentSuccess(false);
  // }

  // Calculate current orders to display
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0); // Scroll to top when changing pages
  };

  // Add Pagination component
  const Pagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredOrders.length / ordersPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="orders-pagination">
        <button
          className="orders-pagination-button"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt;
        </button>
        
        <span className="orders-pagination-info">
          Page {currentPage} of {Math.ceil(filteredOrders.length / ordersPerPage)}
        </span>

        <button
          className="orders-pagination-button"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === Math.ceil(filteredOrders.length / ordersPerPage)}
        >
          &gt;
        </button>
      </div>
    );
  };

  const formatSelectedPrizes = (selectedPrizes) => {
    // Count occurrences of each prize
    const prizeCounts = selectedPrizes.reduce((acc, prize) => {
      acc[prize] = (acc[prize] || 0) + 1;
      return acc;
    }, {});

    // Convert to array of formatted strings and sort
    const formattedPrizes = Object.entries(prizeCounts)
      .map(([prize, count]) => ({
        prize,
        count,
        display: `${count}${prize}`
      }))
      .sort((a, b) => {
        // If either is LAST, handle separately
        if (a.prize === 'LAST') return 1;  // LAST goes to the end
        if (b.prize === 'LAST') return -1; // LAST goes to the end
        
        // For non-LAST prizes, sort alphabetically
        return a.prize.localeCompare(b.prize);
      })
      .map(item => item.display);

    return formattedPrizes.join(', ');
  };

  // Add this helper function at the top of your component
  const formatDateToSGT = (utcDate) => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'Asia/Singapore',
      hour12: false
    };

    return new Intl.DateTimeFormat('en-SG', options).format(new Date(utcDate));
  };

  return (
    <div>
      <NavBar />
      <div className="gradient-background">
        {/* {showShipmentSuccess && (
          <ShipmentSuccessPopup onCancel={handleCloseShipmentSuccessPopup} />
        )}
        {itemLimitExceeded && (
          <div className="item-limit-popup popup-overlay">
            <div className="item-limit-popup-content">
              <p>
                Item limit exceeded! You are trying to ship {totalItems} items, but
                the limit is 10.
              </p>
              <button onClick={() => setItemLimitExceeded(false)}>Close</button>
            </div>
          </div>
        )} */}
        <div className="orders-container">
          <div className="orders-title-container">
            <h2 className="orders-title">Orders</h2>
          </div>
          <div className="orders-search">
            <input
              type="text"
              placeholder="Search by Order ID or Product Name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="order-search-input"
            />
          </div>
          {/* <div className="orders-top">
            {showCheckboxes ? (
              <>
                <button
                  className="batch-ship-confirm-button"
                  disabled={selectedOrders.length === 0}
                  onClick={handleConfirmBatchShipping}
                >
                  Ship Orders
                </button>
                <button
                  className="batch-cancel-button"
                  onClick={handleCancelBatchShipping}
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                <h2 className="orders-title">Orders</h2>
                <button
                  className="batch-ship-button"
                  onClick={() => setShowCheckboxes(true)}
                >
                  Batch Shipping
                </button>
              </>
            )}
          </div> */}
          {isLoading ? (
            <LoadingSpinner />
          ) : filteredOrders.length > 0 ? (
            <>
              {currentOrders.map((order) =>
                showCheckboxes ? (
                  <div className="order-row" key={order.orderId}>
                    {/* <input
                      type="checkbox"
                      checked={selectedOrders.includes(order.orderId)}
                      onChange={() => handleSelectOrder(order.orderId)}
                    /> */}
                    <div
                      className="order-card"
                      onClick={() => handleCardClick(order)}
                    >
                      <div className="order-top">
                        <div className="order-id">{order.orderId}</div>
                        <div className="order-date">{formatDateToSGT(order.dateTimeCreated)}</div>
                      </div>
                      <div className="order-content">
                        <img
                          src={`${backendUrl}/api/images/${order.productImage}`}
                          alt="Product"
                        />
                        <div className="order-details">
                          <div className="product-name">{order.productName}</div>
                          <div className="ticket-calculation">
                            {order.paymentIntentId === "TOKEN" ? (
                              <div className="ticket-cost">
                                {order.totalCost["$numberDecimal"]
                                  ? parseFloat(
                                      order.totalCost["$numberDecimal"] /
                                        order.numberOfTickets
                                    ).toFixed(2)
                                  : "N/A"}{" "}
                                <img
                                  src={tokenIcon}
                                  alt="Token"
                                  className="order-token-icon"
                                />
                              </div>
                            ) : (
                              <div className="ticket-cost">
                                $
                                {order.totalCost["$numberDecimal"]
                                  ? parseFloat(
                                      order.totalCost["$numberDecimal"] /
                                        100 /
                                        order.numberOfTickets
                                    ).toFixed(2)
                                  : "N/A"}
                              </div>
                            )}
                            <div className="number-of-tickets">
                              x{order.numberOfTickets}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="order-bottom">
                        <div className="selected-prizes">
                          {order.lotteryEnabled ? (
                            order.selectedPrizes.length > 0 ? (
                              order.lotteryWon ? (
                                <div className="selected-prizes">
                                  {formatSelectedPrizes(order.selectedPrizes)} (CHANCE Won!)
                                </div>
                              ) : (
                                <div className="selected-prizes">
                                  {formatSelectedPrizes(order.selectedPrizes)} (CHANCE Lost!)
                                </div>
                              )
                            ) : (
                              <div className="selected-prizes">
                                Please wait for CHANCE tickets to be sold out.
                              </div>
                            )
                          ) : (
                            <div className="selected-prizes">
                              {formatSelectedPrizes(order.selectedPrizes)}
                            </div>
                          )}
                        </div>
                        {order.paymentIntentId === "TOKEN" ? (
                          <div className="order-page-total-cost">
                            Total:{" "}
                            <strong>
                              {order.totalCost["$numberDecimal"]}
                            </strong>
                            <img
                              src={tokenIcon}
                              alt="Token"
                              className="order-token-icon"
                            />
                          </div>
                        ) : (
                          <div className="order-page-total-cost">
                            Total:{" "}
                            <strong>
                              $
                              {order.totalCost["$numberDecimal"]
                                ? parseFloat(
                                    order.totalCost["$numberDecimal"] / 100
                                  ).toFixed(2)
                                : "N/A"}
                            </strong>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="order-card"
                    key={order.orderId}
                    onClick={() => handleCardClick(order)}
                  >
                    <div className="order-top">
                      <div className="order-id">{order.orderId}</div>
                      <div className="order-date">{formatDateToSGT(order.dateTimeCreated)}</div>
                    </div>
                    <div className="order-content">
                      <img
                        src={`${backendUrl}/api/images/${order.productImage}`}
                        alt="Product"
                      />
                      <div className="order-details">
                        <div className="product-name">{order.productName}</div>
                        <div className="ticket-calculation">
                          {order.paymentIntentId === "TOKEN" ? (
                            <div className="ticket-cost">
                              {order.totalCost["$numberDecimal"]
                                ? parseFloat(
                                    order.totalCost["$numberDecimal"] /
                                      order.numberOfTickets
                                  ).toFixed(2)
                                : "N/A"}{" "}
                              <img
                                src={tokenIcon}
                                alt="Token"
                                className="order-token-icon"
                              />
                            </div>
                          ) : (
                            <div className="ticket-cost">
                              $
                              {order.totalCost["$numberDecimal"]
                                ? parseFloat(
                                    order.totalCost["$numberDecimal"] /
                                      100 /
                                      order.numberOfTickets
                                  ).toFixed(2)
                                : "N/A"}
                            </div>
                          )}
                          <div className="number-of-tickets">
                            x{order.numberOfTickets}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="order-bottom">
                      <div className="selected-prizes">
                        {order.lotteryEnabled ? (
                          order.selectedPrizes.length > 0 ? (
                            order.lotteryWon ? (
                              <div className="selected-prizes">
                                {formatSelectedPrizes(order.selectedPrizes)} (CHANCE Won!)
                              </div>
                            ) : (
                              <div className="selected-prizes">
                                {formatSelectedPrizes(order.selectedPrizes)} (CHANCE Lost!)
                              </div>
                            )
                          ) : (
                            <div className="selected-prizes">
                              Please wait for CHANCE tickets to be sold out.
                            </div>
                          )
                        ) : (
                          <div className="selected-prizes">
                            {formatSelectedPrizes(order.selectedPrizes)}
                          </div>
                        )}
                      </div>
                      {order.paymentIntentId === "TOKEN" ? (
                        <div className="order-page-total-cost">
                          Total:{" "}
                          <strong>
                            {order.totalCost["$numberDecimal"]}
                          </strong>
                          <img
                            src={tokenIcon}
                            alt="Token"
                            className="order-token-icon"
                          />
                        </div>
                      ) : (
                        <div className="order-page-total-cost">
                          Total:{" "}
                          <strong>
                            $
                            {order.totalCost["$numberDecimal"]
                              ? parseFloat(
                                  order.totalCost["$numberDecimal"] / 100
                                ).toFixed(2)
                              : "N/A"}
                          </strong>
                        </div>
                      )}
                    </div>
                  </div>
                )
              )}
              <Pagination />
            </>
          ) : (
            <div className="no-orders-message">No orders found.</div>
          )}
        </div>
        <Footer />
      </div>
      {showShipmentPopup && <ShipmentPopup
            orderIds={selectedOrders} // Pass the single order in an array
            onCancel={() => setShowShipmentPopup(false)}
            userShippingAddress={userShippingAddress}
            setShowShipmentPopup={setShowShipmentPopup}
          />
      }
    </div>
  );  
}

export default OrdersPage;