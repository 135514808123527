import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import './AdminSelfCollect.css';
import LoadingSpinner from './LoadingSpinner';

const AdminSelfCollect = () => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [updatingStatus, setUpdatingStatus] = useState(null);
  const [statusFilter, setStatusFilter] = useState('all');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [pendingStatusChange, setPendingStatusChange] = useState(null);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      fetchCollections(currentPage, searchTerm, statusFilter);
    }, searchTerm ? 300 : 0);

    return () => clearTimeout(timeoutId);
  }, [currentPage, searchTerm, statusFilter]);

  const fetchCollections = async (page, search, status) => {
    try {
      const queryParams = new URLSearchParams({
        page,
        search: search || '',
        status: status || 'all'
      });

      const response = await fetch(
        `${backendUrl}/api/admin/selfcollect?${queryParams}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
          }
        }
      );

      if (response.status === 401 || response.status === 403) {
        navigate('/admin/login');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to fetch collections');
      }

      const data = await response.json();
      setCollections(data.collections);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching collections:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setLoading(true);
    setCurrentPage(newPage);
  };

  const debouncedSearch = (value) => {
    setLoading(true);
    setCurrentPage(1);
    setSearchTerm(value);
  };

  const handleStatusChange = async (collectionId, newStatus, currentStatus) => {
    if (currentStatus === 'completed' || currentStatus === 'cancelled') {
      return;
    }

    if (newStatus === 'cancelled') {
      setPendingStatusChange({ collectionId, newStatus });
      setShowConfirmModal(true);
      return;
    }

    await updateStatus(collectionId, newStatus);
  };

  const updateStatus = async (collectionId, newStatus) => {
    try {
      setUpdatingStatus(collectionId);
      const response = await fetch(
        `${backendUrl}/api/admin/selfcollect/${collectionId}/status`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
          },
          body: JSON.stringify({ status: newStatus })
        }
      );

      if (response.status === 401 || response.status === 403) {
        navigate('/admin/login');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to update status');
      }

      setCollections(collections.map(collection => 
        collection._id === collectionId 
          ? { ...collection, status: newStatus }
          : collection
      ));
    } catch (error) {
      console.error('Error updating status:', error);
      alert('Failed to update status. Please try again.');
    } finally {
      setUpdatingStatus(null);
      setShowConfirmModal(false);
      setPendingStatusChange(null);
    }
  };

  const handleStatusFilterChange = (value) => {
    setLoading(true);
    setStatusFilter(value);
    setCurrentPage(1);
  };

  if (error) return <div className="admin-selfcollect-error">Error: {error}</div>;

  return (
    <div>
      <NavBar />
      <div className="admin-selfcollect-container">
        <div className="admin-selfcollect-header">
          <div className="admin-selfcollect-header-left">
            <button 
              onClick={() => navigate('/admin/menu')} 
              className="admin-selfcollect-back-button"
            >
              <i className="fas fa-arrow-left"></i> Back to Menu
            </button>
            <h1>Self Collections</h1>
          </div>
        </div>

        <div className="admin-selfcollect-controls">
          <div className="admin-selfcollect-search">
            <div className="admin-selfcollect-search-container">
              <i className="fas fa-search admin-selfcollect-search-icon"></i>
              <input
                type="text"
                placeholder="Search by Collection ID or Username"
                value={searchTerm}
                onChange={(e) => debouncedSearch(e.target.value)}
                className="admin-selfcollect-search-input"
              />
              {searchTerm && (
                <button
                  className="admin-selfcollect-clear-search-button"
                  onClick={() => debouncedSearch('')}
                >
                  <i className="fas fa-times"></i>
                </button>
              )}
            </div>
          </div>
          
          <div className="admin-selfcollect-filters">
            <div className="admin-selfcollect-filter-group">
              <label>Status:</label>
              <select 
                value={statusFilter}
                onChange={(e) => handleStatusFilterChange(e.target.value)}
                className="admin-selfcollect-filter-select"
              >
                <option value="all">All Status</option>
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </div>
          </div>
        </div>

        <div className="admin-selfcollect-table-container">
          {loading ? (
            <div className="admin-selfcollect-loading-container">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <table className="admin-selfcollect-table">
                <thead>
                  <tr>
                    <th>Collection ID</th>
                    <th>Username</th>
                    <th>Collection Date</th>
                    <th>Parcel Content</th>
                    <th>Status</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {collections.map((collection) => (
                    <tr key={collection._id}>
                      <td>{collection.collectionId || 'N/A'}</td>
                      <td>{collection.username || 'Unknown User'}</td>
                      <td>{new Date(collection.collectionDate).toLocaleString()}</td>
                      <td>{collection.parcelContent || 'N/A'}</td>
                      <td>
                        <select
                          className={`admin-selfcollect-status-select ${collection.status.toLowerCase()}`}
                          value={collection.status}
                          onChange={(e) => handleStatusChange(collection._id, e.target.value, collection.status)}
                          disabled={updatingStatus === collection._id || 
                                   collection.status === 'completed' || 
                                   collection.status === 'cancelled'}
                        >
                          <option value="pending">Pending</option>
                          <option value="completed">Completed</option>
                          <option value="cancelled">Cancelled</option>
                        </select>
                        {updatingStatus === collection._id && (
                          <span className="admin-selfcollect-status-loading">
                            <i className="fas fa-spinner fa-spin"></i>
                          </span>
                        )}
                      </td>
                      <td>{new Date(collection.createdAt).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="admin-selfcollect-pagination">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1 || loading}
                  className="admin-selfcollect-pagination-button"
                >
                  <i className="fas fa-chevron-left"></i> Previous
                </button>
                
                <span className="admin-selfcollect-pagination-info">
                  Page {currentPage} of {totalPages}
                </span>
                
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages || loading}
                  className="admin-selfcollect-pagination-button"
                >
                  Next <i className="fas fa-chevron-right"></i>
                </button>
              </div>
            </>
          )}
        </div>

        {showConfirmModal && pendingStatusChange && (
          <div className="admin-selfcollect-modal-overlay">
            <div className="admin-selfcollect-modal">
              <h3>Confirm Cancellation</h3>
              <p>Are you sure you want to cancel this self-collection?</p>
              <p className="admin-selfcollect-modal-warning">
                Warning: This will return all items to the user's inventory.
              </p>
              <div className="admin-selfcollect-modal-buttons">
                <button
                  className="admin-selfcollect-modal-confirm"
                  onClick={() => {
                    if (pendingStatusChange) {
                      updateStatus(
                        pendingStatusChange.collectionId,
                        pendingStatusChange.newStatus
                      );
                    }
                  }}
                >
                  Confirm
                </button>
                <button
                  className="admin-selfcollect-modal-cancel"
                  onClick={() => {
                    setShowConfirmModal(false);
                    setPendingStatusChange(null);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminSelfCollect; 