import './ShippingInfoPopup.css';

const ShippingInfoPopup = ({ onClose }) => {
    return (
        <div className="inventory-shipping-info-popup-overlay popup-overlay">
            <div className="inventory-shipping-info-popup">
                <h3>Shipping Information</h3>
                <p>For every $5 spent, you can ship:</p>
                <ul>
                    <li>2 Big Items</li>
                    <li>20 Small Items</li>
                </ul>
                <button className="inventory-shipping-info-popup-close-button" onClick={onClose}>Got it!</button>
            </div>
        </div>
    );
};

export default ShippingInfoPopup;