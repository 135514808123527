import React, { useState, useEffect } from 'react';
import './PrizeSelectionModal.css';
import Fuse from 'fuse.js';

function PrizeSelectionModal({ products, onClose, onConfirm }) {
  const [selectedPrizes, setSelectedPrizes] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [prizeImages, setPrizeImages] = useState({});
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  console.log("products", products);

  useEffect(() => {
    const handleSearch = async () => {
      if (!searchQuery.trim()) {
        setFilteredProducts([]);
        setPrizeImages({});
        return;
      }

      const fuse = new Fuse(products || [], {
        keys: ['name'],
        includeScore: false,
        threshold: 0.3,
      });

      const result = fuse.search(searchQuery);
      const filtered = result.map(({ item }) => item);
      setFilteredProducts(filtered);

      // Only fetch images if we have filtered products
      if (filtered && filtered.length > 0) {
        try {
          const productIds = filtered.map(product => product._id).join(',');
          const response = await fetch(`${backendUrl}/api/products-images?productIds=${productIds}`);

          
          if (response.ok) {
            const data = await response.json();

            console.log(data);
            setPrizeImages(data.prizeImages || {});
          }
        } catch (error) {
          console.error('Error fetching prize images:', error);
          setPrizeImages({});
        }
      }
    };

    handleSearch();
  }, [searchQuery, products, backendUrl]);

  const handleQuantityChange = (prize, productId, productName, change) => {
    const existingIndex = selectedPrizes.findIndex(
      (item) => item.productId === productId && item.prizeId === prize._id
    );

    if (existingIndex > -1) {
      const updatedPrizes = [...selectedPrizes];
      updatedPrizes[existingIndex].quantity += change;

      if (updatedPrizes[existingIndex].quantity <= 0) {
        updatedPrizes.splice(existingIndex, 1);
      }

      setSelectedPrizes(updatedPrizes);
    } else if (change > 0) {
      setSelectedPrizes((prev) => [
        ...prev,
        { productId, productName, prizeId: prize._id, prize: prize.name, quantity: change },
      ]);
    }
  };

  console.log(filteredProducts);

  return (
    <div className="prize-selection-modal popup-overlay">
      <div className="prize-selection-modal-content">
        <h4>Select Desired Prizes</h4>
        <div className="prize-selection-search">
          <input
            type="text"
            placeholder="Search by Set Name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
        </div>
        <div className="prize-selection-products-container">
          {searchQuery.trim() === '' ? (
            <div className="empty-search-message">
              Please enter a set name to search for prizes
            </div>
          ) : !filteredProducts || filteredProducts.length === 0 ? (
            <div className="no-results-message">
              No sets found matching "{searchQuery}"
            </div>
          ) : (
            filteredProducts.map((product) => (
              <div key={product._id} className="prize-selection-product-section">
                <h5>{product.name}</h5>
                <div className="prize-selection-prizes-container">
                  {(product.prizes || []).map((prize) => (
                    <div key={prize._id} className="prize-selection-prize-item">
                      {prizeImages[prize._id] && (
                        <img
                          src={`${backendUrl}/api/images/${prizeImages[prize._id]}`}
                          alt={prize.name}
                          className="prize-selection-prize-image"
                          loading="lazy"
                        />
                      )}
                      <span>{prize.name}</span>
                      <div className="prize-selection-quantity-controls">
                        <button onClick={() => handleQuantityChange(prize, product._id, product.name, -1)}>-</button>
                        <span>
                          {selectedPrizes.find(
                            (item) =>
                              item.productId === product._id && item.prizeId === prize._id
                          )?.quantity || 0}
                        </span>
                        <button onClick={() => handleQuantityChange(prize, product._id, product.name, 1)}>+</button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          )}
        </div>
        <div className="prize-selection-modal-actions">
          <button onClick={onClose}>Cancel</button>
          <button onClick={() => onConfirm(selectedPrizes)}>Confirm</button>
        </div>
      </div>
    </div>
  );
}

export default PrizeSelectionModal;