import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NavBar from './NavBar';
import LoadingSpinner from './LoadingSpinner';
import './ManageTrades.css';
import ImagePopup from './ImagePopup';
import Footer from './Footer';

function ManageTrades() {
  const [trades, setTrades] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCancelling, setIsCancelling] = useState(false);
  const [selectedPrize, setSelectedPrize] = useState(null);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [tradeToCancel, setTradeToCancel] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const username = useSelector((state) => state.user?.username);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserTrades = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${backendUrl}/api/trade/user-trades`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        if (response.ok) {
          const data = await response.json();
          setTrades(data);
        } else {
          console.error('Failed to fetch user trades');
        }
      } catch (error) {
        console.error('Error fetching user trades:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserTrades();
  }, [backendUrl]);

  const handleImageClick = (prize) => {
    const getPrizeLetter = (name) => {
      if (name === 'Last Prize') return 'LAST';
      return name.replace('Prize ', '');
    };

    setSelectedPrize({
      letter: getPrizeLetter(prize.name),
      description: prize.description,
      src: `${backendUrl}/api/images/${prize.image}`,
    });
  };

  const handleClosePopup = () => {
    setSelectedPrize(null);
  };

  const handleConfirmCancelTrade = async () => {
    if (!tradeToCancel) return;

    setIsCancelling(true);
    try {
      const response = await fetch(`${backendUrl}/api/trade/cancel-trade/${tradeToCancel}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      if (response.ok) {
        setTrades((prevTrades) => prevTrades.filter((trade) => trade._id !== tradeToCancel));
      } else {
        console.error('Failed to cancel trade');
      }
    } catch (error) {
      console.error('Error canceling trade:', error);
    } finally {
      setIsCancelling(false);
      setShowCancelConfirmation(false);
      setTradeToCancel(null);
    }
  };

  const handleOpenCancelConfirmation = (tradeId) => {
    setTradeToCancel(tradeId);
    setShowCancelConfirmation(true);
  };

  const handleCloseCancelConfirmation = () => {
    setTradeToCancel(null);
    setShowCancelConfirmation(false);
  };

  const handleCreateTrade = () => {
    navigate('/inventory');
  };

  const handleViewTradeHistory = () => {
    navigate('/trade-history');
  };

  return (
    <div>
      <NavBar />
      <div className="gradient-background">
        <div className="manage-trades">
          <h1>Manage Your Trades</h1>
          <div className="manage-trades-header-buttons">
            <button className="manage-trades-create-trade-button" onClick={handleCreateTrade}>
              Create Trade
            </button>
            <button className="manage-trades-history-button" onClick={handleViewTradeHistory}>
              Trade History
            </button>
          </div>
          {isLoading ? (
            <LoadingSpinner />
          ) : trades.length > 0 ? (
            trades.map((trade) => (
              <div key={trade._id} className="manage-trades-card">
                <div className="manage-trades-header">
                  <span className="manage-trades-created-date">
                    Created: {new Date(trade.createdAt).toLocaleDateString()}
                  </span>
                </div>
                <div className="manage-trades-details">
                  <div className="manage-trades-prize-container">
                    <h3 style={{marginTop: "5px"}}>Prizes Offered</h3>
                    <div className="manage-trades-prizes">
                      {trade.prizesOffered.map((offer, index) => (
                        <div key={index} className="manage-trades-prize-card">
                          <h4 className="manage-trades-prize-name">{offer.prizeDetails.name}</h4>
                          <span className="manage-trades-prize-description">({offer.prizeDetails.description})</span>
                          <img
                            src={`${backendUrl}/api/images/${offer.prizeDetails.image}`}
                            alt={offer.prizeDetails.name}
                            onClick={() => handleImageClick(offer.prizeDetails)}
                            className="manage-trades-prize-image"
                          />
                          <span className="manage-trades-prize-quantity">Qty: {offer.quantity}</span>
                          {offer.shipByDate && (
                            <span className="manage-trades-ship-by">
                              Ship By: {new Date(offer.shipByDate).toLocaleDateString()}
                            </span>
                          )}
                          <span className="manage-trades-prize-set-name">({offer.setName})</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="manage-trades-prizes-container">
                    <h3 style={{marginTop: "5px"}}>Prizes Wanted</h3>
                    <div className="manage-trades-prizes">
                      {trade.prizesWanted.map((wanted, index) => (
                        <div key={index} className="manage-trades-prize-card">
                          <h4 className="manage-trades-prize-name">{wanted.prizeDetails.name}</h4>
                          <span className="manage-trades-prize-description">({wanted.prizeDetails.description})</span>
                          <img
                            src={`${backendUrl}/api/images/${wanted.prizeDetails.image}`}
                            alt={wanted.prizeDetails.name}
                            onClick={() => handleImageClick(wanted.prizeDetails)}
                            className="manage-trades-prize-image"
                          />
                          <span className="manage-trades-prize-quantity">Qty: {wanted.quantity}</span>
                          <span className="manage-trades-prize-set-name">({wanted.productDetails.name})</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="manage-trades-button-group">
                  <button
                    className="manage-trades-view-offers-button"
                    onClick={() => navigate(`/trade/${trade._id}`)}
                  >
                    View Offers
                  </button>
                  <button
                    className="manage-trades-cancel-button"
                    onClick={() => handleOpenCancelConfirmation(trade._id)}
                  >
                    Cancel Trade
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="manage-trades-no-trades">You have no active trades.</div>
          )}
        </div>
        <Footer />
      </div>
      {selectedPrize && (
        <ImagePopup prize={selectedPrize} src={selectedPrize.src} onClose={handleClosePopup} />
      )}
      {showCancelConfirmation && (
        <div className="cancel-trade-confirmation-popup popup-overlay">
          <div className="cancel-trade-confirmation-popup-content">
            <p>Are you sure you want to cancel this trade?</p>
            <div className="cancel-trade-confirmation-popup-buttons">
              {isCancelling ? (
                <LoadingSpinner />
              ) : (
                <>
                  <button 
                    className="cancel-trade-confirm-button" 
                    onClick={handleConfirmCancelTrade}
                  >
                    Yes
                  </button>
                  <button 
                    className="cancel-trade-cancel-button" 
                    onClick={handleCloseCancelConfirmation}
                  >
                    No
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ManageTrades;