import React from 'react';
import './PaymentSuccessPopup.css'; // Style your popup accordingly

const PaymentSuccessPopup = ({ onClose, selectedPrizes, challengeWon, product }) => {
  // Check if 'LAST' is in the selected prizes
  let includesLastPrize = selectedPrizes.includes('LAST');

  if (challengeWon) {
    includesLastPrize = false;
  }

  let lotteryEnabled = false;

  if (product.lottery) {
    if (product.lottery.enabled) {
      lotteryEnabled = true;
    }
  }

  return (
    <div className="payment-success-popup-overlay popup-overlay">
      <div className="payment-success-popup-content">
        <h2>Payment Successful!</h2>
        {includesLastPrize && (
          <p>Congratulations on your buy out and winning the last prize!</p>
        )}
        {
          lotteryEnabled
          ? <p>Your payment has been successfully processed. <br/><br/> You will know what prizes you win once all the CHANCE tickets are sold. You will now be redirected to the Orders Page.</p>
          : <p>Your payment has been successfully processed. You can now proceed to draw your tickets.</p>
        }
        <button onClick={onClose}>Continue</button>
      </div>
    </div>
  );
};

export default PaymentSuccessPopup;