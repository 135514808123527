    // src/components/Footer.js
    import React from 'react';
    import { Link } from 'react-router-dom';
    import instagramLogo from './instagram-logo.png'; // Ensure you have these logos in your src folder
    import tiktokLogo from './tiktok-logo.png';

    function Footer() {
        return (
            <div style={styles.footerContainer}>
                <div style={styles.divider}></div> {/* Divider line at the top */}
                <footer style={styles.footer}>
                    <div style={styles.columns}>
                        <ul style={styles.footerList}>
                            <li style={styles.footerItem}><Link to="/terms-and-conditions" style={styles.link}>Terms and Conditions</Link></li>
                            <li style={styles.footerItem}><Link to="/shipping-and-delivery" style={styles.link}>Shipping and Delivery</Link></li>
                            <li style={styles.footerItem}><Link to="/faq" style={styles.link}>FAQ</Link></li> {/* FAQ Page Link */}
                        </ul>
                        <ul style={styles.footerList}>
                            <li style={styles.footerItem}><Link to="/about-us" style={styles.link}>About Us</Link></li>
                            <li style={styles.footerItem}><Link to="/how-it-works" style={styles.link}>How It Works</Link></li>
                            <li style={styles.footerItem}><Link to="/contact-us" style={styles.link}>Contact Us</Link></li>
                        </ul>
                    </div>
                    <div style={styles.socialAndCopyright}>
                    <div style={styles.socialIcons}>
                            <a href="https://www.instagram.com/subtleweebclub/" target="_blank" rel="noopener noreferrer">
                                <img src={instagramLogo} alt="Instagram" style={styles.socialIcon} />
                            </a>
                            <a href="https://www.tiktok.com/@subtleweebclub" target="_blank" rel="noopener noreferrer">
                                <img src={tiktokLogo} alt="TikTok" style={styles.socialIcon} />
                            </a>
                        </div>
                        <div style={styles.rightAlignedText}>
                            © 2025 Surprize
                        </div>
                    </div>
                </footer>
            </div>
        );
    }

    const styles = {
        footerContainer: {
            marginTop: '30px',
            width: '100%',
            padding: '0 20px', // Add padding here to add space inside the container
            boxSizing: 'border-box', // This ensures padding is included within the width
        },
        footer: {
            padding: '20px 0px',
            textAlign: 'left',
            position: 'relative',
            bottom: 0,
            width: '100%',
            boxSizing: 'border-box', // Ensure padding does not add to width
        },
        divider: {
            borderTop: '2px solid #3b3b3b',
            width: '100%',
        },
        columns: {
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
        },
        footerList: {
            listStyleType: 'none',
            padding: 0,
            margin: '0 10px', // Adjusted margin for the list
            flex: '1 1 50%', // Flex property to take up 50% of the footer width
        },
        footerItem: {
            margin: '10px 0',
        },
        link: {
            textDecoration: 'none',
            color: '#3b3b3b',
            fontSize: '0.8em',
        },
        socialAndCopyright: {
            display: 'flex',
            justifyContent: 'space-between', // Aligns children to the edges
            alignItems: 'center',
            width: '100%',
            marginTop: '20px'
        },
        socialIcons: {
            display: 'flex',
        },
        socialIcon: {
            width: '24px',
            height: '24px',
            marginRight: '10px',
        },
        rightAlignedText: {
            color: '#3b3b3b',
            fontSize: '0.8em',
        }
    };

    export default Footer;