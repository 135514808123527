import './TradeInfoPopup.css';

const TradeInfoPopup = ({ onClose }) => {
  return (
    <div className="inventory-trade-info-popup-overlay popup-overlay">
      <div className="inventory-trade-info-popup">
        <h3>Trade Information</h3>
        <div className="inventory-trade-info-popup-text">
          <p>You can only trade items that are:</p>
          <ul>
            <li>Pre-order items</li>
            <li>Instock items with ship by dates more than 1 day in the future</li>
          </ul>
          <p>This ensures that trades can be completed before shipping deadlines.</p>
        </div>
        <button className="inventory-trade-info-popup-close-button" onClick={onClose}>
          Got it!
        </button>
      </div>
    </div>
  );
};

export default TradeInfoPopup; 