import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import LoadingSpinner from './LoadingSpinner';
import './AdminInventoryLookup.css';

const AdminInventoryLookup = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [inventory, setInventory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [productFilter, setProductFilter] = useState('');

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleSearch = async () => {
    if (!searchTerm.trim()) {
      setError('Please enter a username or email');
      return;
    }

    setLoading(true);
    setError(null);
    setInventory(null);

    try {
      const adminToken = localStorage.getItem('adminToken');
      if (!adminToken) {
        throw new Error('Admin authentication required');
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/inventory/${searchTerm}`,
        {
          headers: {
            'Authorization': `Bearer ${adminToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw new Error(await response.text());
      }
      const data = await response.json();
      setInventory(data.inventory);
    } catch (err) {
      setError(err.message || 'Failed to fetch inventory');
      if (err.message === 'Admin authentication required') {
        navigate('/admin/login');
      }
    } finally {
      setLoading(false);
    }
  };

  const renderInventory = () => {
    if (!inventory) return null;

    // Filter products based on search term
    const filteredInventory = Object.entries(inventory).reduce((acc, [productName, items]) => {
      if (productName.toLowerCase().includes(productFilter.toLowerCase())) {
        acc[productName] = items;
      }
      return acc;
    }, {});

    return Object.entries(filteredInventory).map(([productName, items]) => {
      // Sort items array: alphabetically by prizeName, with 'Last Prize' at the end
      const sortedItems = [...items].sort((a, b) => {
        if (a.prizeName === 'Last Prize') return 1;
        if (b.prizeName === 'Last Prize') return -1;
        return a.prizeName.localeCompare(b.prizeName);
      });

      return (
        <div key={productName} className="admin-inventory-lookup-product-section">
          <h3>{productName}</h3>
          <div className="admin-inventory-lookup-items-grid">
            {sortedItems.map((item, index) => (
              <div key={index} className="admin-inventory-lookup-item-card">
                <img 
                  src={`${process.env.REACT_APP_BACKEND_URL}/api/images/${item.image}`} 
                  alt={item.prizeName} 
                  className="admin-inventory-lookup-item-image" 
                />
                <div className="admin-inventory-lookup-item-details">
                  <h4>{item.prizeName}</h4>
                  {item.batches.map((batch, batchIndex) => (
                    <div key={batchIndex} className="admin-inventory-lookup-batch-info">
                      <p>SKU: {batch.sku}</p>
                      <p>Quantity: {batch.quantity}</p>
                      {batch.shipByDate && (
                        <p>Ship By: {new Date(batch.shipByDate).toLocaleDateString()}</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <NavBar />
      <div className="admin-inventory-lookup-container">
        <div className="admin-inventory-lookup-header">
          <div className="admin-inventory-lookup-header-left">
            <button 
              onClick={() => navigate('/admin/menu')} 
              className="admin-inventory-lookup-back-button"
            >
              <i className="fas fa-arrow-left"></i> Back to Menu
            </button>
            <h1>User Inventory Lookup</h1>
          </div>
        </div>

        <div className="admin-inventory-lookup-search">
          <div className="admin-inventory-lookup-search-container">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Enter username or email"
              className="admin-inventory-lookup-search-input"
            />
            <button onClick={handleSearch} className="admin-inventory-lookup-search-button">
              <i className="fas fa-search"></i> Search
            </button>
          </div>
          {error && <div className="admin-inventory-lookup-error-message">{error}</div>}
          
          {/* Add product filter input when inventory is shown */}
          {inventory && (
            <div className="admin-inventory-lookup-search-container" style={{ marginTop: '1rem' }}>
              <input
                type="text"
                value={productFilter}
                onChange={(e) => setProductFilter(e.target.value)}
                placeholder="Filter by product name"
                className="admin-inventory-lookup-search-input"
              />
            </div>
          )}
        </div>

        <div className="admin-inventory-lookup-results">
          {loading ? (
            <LoadingSpinner />
          ) : (
            renderInventory()
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminInventoryLookup; 