import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Fuse from 'fuse.js'; // For fuzzy searching
import NavBar from './NavBar';
import './InventoryPage.css';
import LoadingSpinner from './LoadingSpinner';
import ShipmentPopup from './ShipmentPopup';
import ImagePopup from './ImagePopup';
import PrizeSelectionModal from './PrizeSelectionModal'; // New modal for selecting wanted prizes
import SelfCollectionModal from './SelfCollectionModal'; // New modal for self-collection
import LoginReminderPopup from './LoginReminderPopup';
import Footer from './Footer';
import ShippingInfoPopup from './ShippingInfoPopup';
import TradeInfoPopup from './TradeInfoPopup';

function InventoryPage() {
  const [inventory, setInventory] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(''); // For search query
  const [searchedInventory, setSearchedInventory] = useState({}); // Searched inventory
  const [selectedAction, setSelectedAction] = useState(null); // "Ship", "Abandon", or "Trade"
  const [selectedItems, setSelectedItems] = useState([]); // Items with adjusted quantities
  const [selectedPrize, setSelectedPrize] = useState(null); // State to track the selected prize for the popup
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showPrizeSelectionModal, setShowPrizeSelectionModal] = useState(false); // New state for prize selection modal
  const [showShipmentPopup, setShowShipmentPopup] = useState(false);
  const [userShippingAddress, setUserShippingAddress] = useState(null);
  const [animeFilterOptions, setAnimeFilterOptions] = useState([]);
  const [selectedAnime, setSelectedAnime] = useState('All');
  const [products, setProducts] = useState([]); // Store all products and prizes for prize selection
  const [prizesWanted, setPrizesWanted] = useState([]); // Store selected prizes wanted for trade
  const [prizesOffered, setPrizesOffered] = useState([]); // Store selected prizes wanted for trade
  const [showSelfCollectionModal, setShowSelfCollectionModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [showShippingInfo, setShowShippingInfo] = useState(false);
  const [showTradeInfo, setShowTradeInfo] = useState(false);
  const [isConfirmingTrade, setIsConfirmingTrade] = useState(false);
  const username = useSelector((state) => state.user.username);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const navigate = useNavigate();

  const fetchInventory = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${backendUrl}/api/inventory/user/${username}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setInventory(data.inventory);
        setSearchedInventory(data.inventory); // Initialize searched inventory with full inventory
        console.log("inventory:", data.inventory)
        const allTags = Object.values(data.inventory)
          .flat()
          .map((item) => item.product?.tags?.[0]) // Extract first tag
          .filter(Boolean);

        setAnimeFilterOptions(['All', ...new Set(allTags)]);
      } else {
        console.error('Failed to fetch inventory');
      }
    } catch (error) {
      console.error('Error fetching inventory:', error);
    } finally {
      setIsLoading(false);
    }
  }, [backendUrl, username]);

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/products-with-prizes`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setProducts(data.products);
      } else {
        console.error('Failed to fetch products');
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {

    const fetchUserShippingAddress = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/users/shipping-address/${username}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          credentials: 'include',
        });

        if (response.ok) {
          const address = await response.json();
          setUserShippingAddress(address);
        } else {
          console.error('Failed to fetch user shipping address');
        }
      } catch (error) {
        console.error('Error fetching user shipping address:', error);
      }
    };

    if (username) {
      fetchInventory();
      fetchUserShippingAddress();
      fetchProducts();
    }
  }, [username, backendUrl, fetchInventory]);

  const handleStatusFilterChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  useEffect(() => {
    const handleSearch = () => {
      if (!searchQuery.trim()) {
        setSearchedInventory(inventory); // Reset to full inventory if search query is empty
        return;
      }

      const fuse = new Fuse(Object.entries(inventory), {
        keys: ['0', '1.product.name'], // Search in product names
        includeScore: false,
        threshold: 0.4, // Adjust threshold for fuzzy matching
      });

      const result = fuse.search(searchQuery);

      // Transform the result into the original inventory structure
      const searchResults = result.reduce((acc, { item }) => {
        const [setName, prizes] = item;
        acc[setName] = prizes;
        return acc;
      }, {});

      setSearchedInventory(searchResults);
    };

    handleSearch();
  }, [searchQuery, inventory]);

  // Filtered inventory based on both search and status filter
  const filteredInventory = selectedStatus === 'All'
    ? searchedInventory
    : Object.entries(searchedInventory).reduce((acc, [setName, prizes]) => {
        const filteredPrizes = prizes.filter((prize) => {
          const isInstock = prize.batches.some(batch => batch.shipByDate);
          return (selectedStatus === 'Instock' && isInstock) || 
                 (selectedStatus === 'Preorder' && !isInstock);
        });
        
        if (filteredPrizes.length > 0) {
          acc[setName] = filteredPrizes;
        }
        return acc;
      }, {});

  const handleQuantityChange = (batch, prizeName, setName, inventoryId, productId, action, change) => {
    const existingItemIndex = selectedItems.findIndex(
      (item) =>
        item.batch.batchId === batch.batchId &&
        item.prizeName === prizeName &&
        item.setName === setName &&
        item.inventoryId === inventoryId &&
        item.productId === productId
    );
  
    if (existingItemIndex > -1) {
      const updatedItems = [...selectedItems];
      const newQuantity = Math.min(
        Math.max(0, updatedItems[existingItemIndex].quantity + change), // Ensure non-negative quantity
        batch.quantity // Ensure quantity does not exceed available stock
      );
      if (newQuantity > 0) {
        updatedItems[existingItemIndex].quantity = newQuantity;
      } else {
        updatedItems.splice(existingItemIndex, 1); // Remove if quantity is 0
      }
      setSelectedItems(updatedItems);
    } else if (change > 0) {
      const newQuantity = Math.min(change, batch.quantity); // Ensure new quantity doesn't exceed available stock
      setSelectedItems((prev) => [
        ...prev,
        { batch, prizeName, setName, inventoryId, productId, action, quantity: newQuantity },
      ]);
    }
  };  

  const handleActionClick = (action) => {
    setSelectedAction(action);
    console.log("action:", action);
    if (action === 'Ship') {
      setShowShippingInfo(true);
    } else if (action === 'Trade') {
      setShowTradeInfo(true);
    }
    setSelectedItems([]); // Reset selected items when changing actions
    setShowConfirmModal(false);
    setShowPrizeSelectionModal(false);
    setShowShipmentPopup(false);
    setShowSelfCollectionModal(false);
  };

  const handleConfirmTradeItems = () => {
    setShowPrizeSelectionModal(true); // Open prize selection modal
  };

  const transformToPrizesOffered = (selectedItems) => {
    return selectedItems.map((item) => ({
      inventoryId: item.inventoryId,
      batchId: item.batch.batchId,
      prizeName: item.prizeName,
      quantity: item.quantity,
      setName: item.setName,
      productId: item.productId
    }));
  };

  const handlePrizeSelectionConfirm = (selectedPrizesWanted) => {
    console.log("selectedprizeswanted:", selectedPrizesWanted);
    setPrizesWanted(selectedPrizesWanted); // Update prizesWanted state
    setPrizesOffered(transformToPrizesOffered(selectedItems)); // Update prizesOffered state
    console.log(prizesOffered);
    setShowPrizeSelectionModal(false); // Close PrizeSelectionModal
    setShowConfirmModal(true); // Open ConfirmModal
  };

  const handleConfirmTrade = async () => {
    const prizesOffered = transformToPrizesOffered(selectedItems);
    setIsConfirmingTrade(true); // Start loading
    try {
      const response = await fetch(`${backendUrl}/api/trade/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ prizesOffered, prizesWanted, username }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Trade created:', data);
        setSelectedItems([]); // Reset selected items
        setSelectedAction(null); // Reset action
        navigate('/manage-trades')
      } else {
        console.error('Failed to create trade');
        alert('Failed to create trade. Please try again.');
      }
    } catch (error) {
      console.error('Error creating trade:', error);
      alert('An error occurred while creating the trade. Please try again.');
    } finally {
      setIsConfirmingTrade(false); // Stop loading
      setShowPrizeSelectionModal(false); // Close the prize selection modal
      setShowConfirmModal(false); // Close confirm modal
    }
  };

  const handleConfirm = async () => {
    if (selectedAction === "Abandon") {
      try {
        const response = await fetch(`${backendUrl}/api/inventory/abandon`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({ items: selectedItems }), // Send selected items to the backend
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log(data.message); // Log success message
          setSelectedItems([]); // Reset selected items
          setSelectedAction(null); // Reset action
          fetchInventory(); // Refresh the inventory
        } else {
          console.error('Failed to abandon items');
        }
      } catch (error) {
        console.error('Error abandoning items:', error);
      }
    } else if (selectedAction === "Ship") {
      setShowShipmentPopup(true); // Open the shipment popup
    } else if (selectedAction === "Trade") {
      await handleConfirmTrade();
    } else if (selectedAction === "Self-Collect") {
      setShowSelfCollectionModal(true); // Open the self-collection modal
    }
    setShowConfirmModal(false); // Close the confirm modal
  };

  const sortPrizes = (prizes) => {
    return prizes.sort((a, b) => {
      if (a.prizeName === 'Last Prize') return 1;
      if (b.prizeName === 'Last Prize') return -1;
      return a.prizeName.localeCompare(b.prizeName);
    });
  };

  const handleImageClick = (prize) => {
    setSelectedPrize({
      letter: formatPrizeName(prize.prizeName),
      description: prize.description,
      src: `${backendUrl}/api/images/${prize.image}`,
    });
  };

  const formatPrizeName = (name) => {
    if (name === 'Last Prize') return 'Last';
    return name.replace('Prize ', ''); // Remove "Prize " prefix, leaving only the letter
  };

  // Close the ImagePopup
  const handleClosePopup = () => {
    setSelectedPrize(null);
  };

  const handleSelfCollect = (items) => {
    setSelectedItems(items);
    setShowSelfCollectionModal(true);
  };

  const handleSelfCollectionConfirm = async (collectionDetails) => {
    try {
      const response = await fetch(`${backendUrl}/api/self-collection/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(collectionDetails),
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || data.message || 'Failed to schedule self-collection');
      }

      // Refresh inventory after successful self-collection
      fetchInventory();
      
      // Redirect to shipments page with collections tab
      navigate('/shipments', { state: { activeTab: 'collections' } });
      
      return data;
    } catch (error) {
      throw error;
    }
  };

  // Add this helper function at the top of your component
  const isTradeEligible = (batch) => {
    if (!batch.shipByDate) return true; // Preorder items are always eligible
    
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 2);
    tomorrow.setHours(0, 0, 0, 0); // Set to start of day
    
    return new Date(batch.shipByDate) > tomorrow;
  };

  return (
    <div>
      <NavBar />
      <div className="gradient-background">
        <div className="inventory-container">
          <h2 className="inventory-title">Inventory</h2>
          <div className="inventory-actions">
            <button
              className={`inventory-action-button ${selectedAction === 'Ship' ? 'active' : ''}`}
              onClick={() => handleActionClick('Ship')}
            >
              Ship Items
            </button>
            <button
              className={`inventory-action-button ${selectedAction === 'Self-Collect' ? 'active' : ''}`}
              onClick={() => handleActionClick('Self-Collect')}
            >
              Self-Collect
            </button>
            <button
              className={`inventory-action-button ${selectedAction === 'Trade' ? 'active' : ''}`}
              onClick={() => handleActionClick('Trade')}
            >
              Trade Items
            </button>
            <button
              className={`inventory-action-button ${selectedAction === 'Abandon' ? 'active' : ''}`}
              onClick={() => handleActionClick('Abandon')}
            >
              Abandon Items
            </button>
          </div>
          <div className="inventory-search">
            <input
              type="text"
              placeholder="Search by product name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)} // Trigger search on input change
              className="inventory-search-input"
            />
          </div>
          <div className="inventory-status-filter">
            <label htmlFor="statusFilter">Filter by status:</label>
            <select
              id="statusFilter"
              value={selectedStatus}
              onChange={handleStatusFilterChange}
              className="inventory-status-filter-select"
            >
              <option value="All">All Items</option>
              <option value="Instock">In Stock</option>
              <option value="Preorder">Pre-order</option>
            </select>
          </div>
          {isLoading ? (
            <LoadingSpinner />
          ) : Object.keys(filteredInventory).length > 0 ? (
            Object.entries(filteredInventory).map(([setName, prizes]) => (
              <div key={setName} className="inventory-set">
                <h3 className="inventory-set-name">{setName}</h3>
                {prizes[0]?.arrival && (
                  <span className="inventory-arrival">
                    Est Arrival: {prizes[0].arrival}
                  </span>
                )}
                <div className="inventory-prizes-container">
                  <div className="inventory-prize-scroll-wrapper">
                  {sortPrizes(prizes).map((prize, index) => (
                    <div 
                      key={index} 
                      className={`inventory-prize-card ${prize.type?.toLowerCase()}-prize`}
                    >
                      <h4 className={`inventory-prize-name ${prize.itemType === 'blind_box' ? 'blindbox' : ''}`}>
                        {prize.prizeName}
                      </h4>
                      <span className={`inventory-prize-type-indicator ${prize.type?.toLowerCase()}`}>
                        {prize.type}
                      </span>
                      <img
                        src={`${backendUrl}/api/images/${prize.image}`}
                        alt={prize.prizeName}
                        className="inventory-prize-image"
                        onClick={() => handleImageClick(prize)}
                      />
                      <div className="inventory-batches-container">
                        {prize.batches.map((batch, batchIndex) => (
                          <div key={batchIndex} className="inventory-batch-details">
                            <span>
                              {batch.shipByDate
                                ? `Instock: ${batch.quantity}`
                                : `Pre-order: ${batch.quantity}`}
                            </span>
                            {batch.inTrade > 0 && <span>In Trade: {batch.inTrade}</span>}
                            {selectedAction === 'Ship' && !batch.shipByDate && (
                              <span className="inventory-batch-disabled-message">
                                Cannot ship pre-order items
                              </span>
                            )}
                            {selectedAction === 'Self-Collect' && !batch.shipByDate && (
                              <span className="inventory-batch-disabled-message">
                                Cannot self-collect pre-order items
                              </span>
                            )}
                            {selectedAction === 'Trade' && batch.shipByDate && !isTradeEligible(batch) && (
                              <span className="inventory-batch-disabled-message">
                                Cannot trade items due within 1 day
                              </span>
                            )}
                            {batch.shipByDate && (
                              <span className="inventory-ship-by">
                                Ship By: {new Date(batch.shipByDate).toLocaleDateString()}
                              </span>
                            )}
                            {selectedAction && (
                              <div className="inventory-quantity-controls">
                                <button
                                  className="inventory-quantity-btn"
                                  disabled={
                                    (selectedAction === 'Ship' && !batch.shipByDate) || 
                                    (selectedAction === 'Self-Collect' && !batch.shipByDate) ||
                                    (selectedAction === 'Trade' && batch.shipByDate && !isTradeEligible(batch))
                                  }
                                  onClick={() =>
                                    handleQuantityChange(
                                      batch,
                                      prize.prizeName,
                                      setName,
                                      prize.id,
                                      prize.product._id,
                                      selectedAction,
                                      -1
                                    )
                                  }
                                >
                                  -
                                </button>
                                <input
                                  type="number"
                                  min="0"
                                  max={batch.quantity}
                                  value={
                                    selectedItems.find(
                                      (item) =>
                                        item.batch.batchId === batch.batchId &&
                                        item.prizeName === prize.prizeName &&
                                        item.setName === setName &&
                                        item.inventoryId === prize.id
                                    )?.quantity || 0
                                  }
                                  disabled={
                                    (selectedAction === 'Ship' && !batch.shipByDate) || 
                                    (selectedAction === 'Self-Collect' && !batch.shipByDate) ||
                                    (selectedAction === 'Trade' && batch.shipByDate && !isTradeEligible(batch))
                                  }
                                  onChange={(e) =>
                                    handleQuantityChange(
                                      batch,
                                      prize.prizeName,
                                      setName,
                                      prize.id,
                                      prize.product._id,
                                      selectedAction,
                                      parseInt(e.target.value, 10) -
                                        (selectedItems.find(
                                          (item) =>
                                            item.batch.batchId === batch.batchId &&
                                            item.prizeName === prize.prizeName &&
                                            item.setName === setName &&
                                            item.inventoryId === prize.id
                                        )?.quantity || 0)
                                    )
                                  }
                                  className="inventory-quantity-input"
                                />
                                <button
                                  className="inventory-quantity-btn"
                                  disabled={
                                    (selectedAction === 'Ship' && !batch.shipByDate) || 
                                    (selectedAction === 'Self-Collect' && !batch.shipByDate) ||
                                    (selectedAction === 'Trade' && batch.shipByDate && !isTradeEligible(batch))
                                  }
                                  onClick={() =>
                                    handleQuantityChange(
                                      batch,
                                      prize.prizeName,
                                      setName,
                                      prize.id,
                                      prize.product._id,
                                      selectedAction,
                                      1
                                    )
                                  }
                                >
                                  +
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-inventory-message">No inventory items found.</div>
          )}
          {selectedItems.length > 0 && (
            <button
              className="inventory-floating-confirm-button"
              onClick={() => {
                if (selectedAction === 'Trade') {
                  handleConfirmTradeItems(); // Open the prize selection modal for trading
                } else if (selectedAction === 'Self-Collect') {
                  setShowSelfCollectionModal(true); // Open self-collection modal directly
                } else {
                  setShowConfirmModal(true); // Open the confirm modal for shipping and abandoning
                }
              }}
            >
              Confirm {selectedAction} Items
            </button>
          )}
        </div>
        <Footer />
      </div>
      {showConfirmModal && (
        <div className="inventory-confirm-modal popup-overlay">
          <div className="inventory-confirm-modal-content">
            <h4>Confirm {selectedAction} Items</h4>
            {selectedAction === "Abandon" && (
              <span style={{ fontSize: "0.8em", marginTop: "0px", color: 'red'}}>You will not be able to get your items back.</span>
              )
            }
            {selectedAction === "Trade" && (
              <span style={{ fontSize: "0.8em", marginTop: "0px", color: 'blue' }}>
                You are creating a trade with the selected items.
              </span>
            )}
            {selectedAction === "Trade" && (
              <>
                <h5 style={{ marginBottom: '0px'}}>Prizes Offered:</h5>
                <ul>
                  {prizesOffered.map((item, index) => (
                    <li key={index} style={{ marginBottom: '0px'}}>
                      {item.setName} - {item.prizeName} (Qty: {item.quantity})
                    </li>
                  ))}
                </ul>
                <h5 style={{ marginBottom: '0px'}}>Prizes Wanted:</h5>
                <ul>
                  {prizesWanted.map((item, index) => (
                    <li key={index} style={{ marginBottom: '0px'}}>
                      {item.productName} - {item.prize} (Qty: {item.quantity})
                    </li>
                  ))}
                </ul>
              </>
            )}
            {
              selectedAction === "Trade"
              ? null
              : <ul>
                  {selectedItems.map((item, index) => (
                    <li key={index}>
                      {item.setName} - {item.prizeName} (Qty: {item.quantity})
                    </li>
                  ))}
                </ul>
            }
            {
              selectedAction === "Trade"
              ? null
              : <div className="inventory-confirm-total-items">
                  <strong>Total Items: {selectedItems.reduce((total, item) => total + item.quantity, 0)}</strong>
                </div>
            }
            <div className="inventory-confirm-modal-buttons">
              {isConfirmingTrade ? (
                <LoadingSpinner />
              ) : (
                <>
                  <button className="inventory-confirm-button" onClick={handleConfirm}>Confirm</button>
                  <button className="inventory-cancel-button" onClick={() => setShowConfirmModal(false)}>Cancel</button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {showShipmentPopup && (
        <ShipmentPopup
          selectedItems={selectedItems} // Pass selectedItems
          onCancel={() => setShowShipmentPopup(false)}
          userShippingAddress={userShippingAddress}
          setShowShipmentPopup={setShowShipmentPopup}
        />
      )}
      {selectedPrize && (
        <ImagePopup
          prize={selectedPrize}
          src={selectedPrize.src}
          onClose={handleClosePopup}
        />
      )}
      {showPrizeSelectionModal && (
        <PrizeSelectionModal
          products={products}
          onClose={() => setShowPrizeSelectionModal(false)}
          onConfirm={handlePrizeSelectionConfirm}
        />
      )}
      {showSelfCollectionModal && (
        <SelfCollectionModal
          selectedItems={selectedItems}
          onClose={() => setShowSelfCollectionModal(false)}
          onConfirm={handleSelfCollectionConfirm}
        />
      )}
      {showShippingInfo && (
        <ShippingInfoPopup onClose={() => setShowShippingInfo(false)} />
      )}
      {showTradeInfo && (
        <TradeInfoPopup onClose={() => setShowTradeInfo(false)} />
      )}
    </div>
  );
}

export default InventoryPage;