import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuthState } from './redux/slices/authSlice';
import HomePage from './HomePage';
import IchibanKujiProducts from './IchibanKujiProducts';
import BlindBoxesProducts from './BlindBoxesProducts';
import LoginPage from './LoginPage';
import VerificationPage from './VerificationPage';
import SignupPage from './SignupPage';
import VerifyEmailPage from './VerifyEmailPage';
import KujiPaymentPage from './KujiPaymentPage';
import DrawPage from './DrawPage';
import ResultsPage from './ResultsPage';
import ProfilePage from './ProfilePage';
import ResetPasswordPage from './ResetPasswordPage';
import VerifyResetPasswordPage from './VerifyResetPasswordPage';
import OrdersPage from './OrdersPage';
import OrderDetailsPage from './OrderDetailsPage';
import ShippingPaymentPage from './ShippingPaymentPage';
import ProtectedRoute from './ProtectedRoute';
import TermsAndConditions from './TermsAndConditions'; // Import the new component
import ShippingAndDelivery from './ShippingAndDelivery';
import ContactUs from './ContactUs';
import HowItWorks from './HowItWorks';
import TestAudio from './TestAudio';
import FAQ from './FAQ';
import AboutUs from './AboutUs';
import TradingMarketplace from './TradingMarketplace';
import TradeHistory from './TradeHistory';
import PurchaseTokensPage from './PurchaseTokensPage';
import TokenPaymentPage from './TokenPaymentPage';
import UserTokensPage from './UserTokensPage';
import InventoryPage from './InventoryPage';
import ShipmentsPage from './ShipmentsPage';
import ManageTrades from './ManageTrades';
import ViewOffers from './ViewOffers';
import AdminLogin from './AdminLogin';
import AdminMenu from './AdminMenu';
import AdminAddProduct from './AdminAddProduct';
import AdminProtectedRoute from './AdminProtectedRoute'; // Import the new AdminProtectedRoute
import AdminProducts from './AdminProducts';
import { WebSocketProvider } from './contexts/WebSocketContext';
import AdminProductView from './AdminProductView';
import AdminOrders from './AdminOrders';
import AdminSelfCollect from './AdminSelfCollect';
import AdminShipments from './AdminShipments';
import AdminInventoryLookup from './AdminInventoryLookup';

import './App.css';

// Create a separate component for tracking
function PageTracker() {
  const location = useLocation();

  useEffect(() => {
    // Track page views
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search
      });
    }
  }, [location]);

  return null;
}

function App() {
  return (
    <WebSocketProvider>
      <Router>
        <PageTracker />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/verify" element={<VerificationPage />} />
          <Route path="/sign-up" element={<SignupPage />} />
          <Route path="/verify-email" element={<VerifyEmailPage />} />
          <Route path="/ichiban-kuji-products" element={<IchibanKujiProducts />} />
          <Route path="/blind-boxes-products" element={<BlindBoxesProducts />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/user-profile" element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          } />
          <Route path="/orders" element={
            <ProtectedRoute>
              <OrdersPage />
            </ProtectedRoute>
          } />
          <Route path="/order-details/:orderId" element={
            <ProtectedRoute>
              <OrderDetailsPage />
            </ProtectedRoute>
          } />

          <Route path="/kuji-payment" element={
            <ProtectedRoute>
              <KujiPaymentPage />
            </ProtectedRoute>
          } />

          <Route path="/trading-marketplace" element={
            <ProtectedRoute>
              <TradingMarketplace />
            </ProtectedRoute>
          } />

          {/* <Route path="/create-trade" element={
            <ProtectedRoute>
              <CreateTrade />
            </ProtectedRoute>
          } /> */}

          <Route path="/manage-trades" element={
            <ProtectedRoute>
              <ManageTrades />
            </ProtectedRoute>
          } />

          <Route path="/purchase-tokens" element={
            <ProtectedRoute>
              <PurchaseTokensPage />
            </ProtectedRoute>
          } />

          <Route path="/token-payment" element={
            <ProtectedRoute>
              <TokenPaymentPage />
            </ProtectedRoute>
          } />

          <Route path="/tokens" element={
            <ProtectedRoute>
              <UserTokensPage />
            </ProtectedRoute>
          } />

          <Route path="/inventory" element={
            <ProtectedRoute>
              <InventoryPage />
            </ProtectedRoute>
          } />

          <Route path="/shipments" element={
            <ProtectedRoute>
              <ShipmentsPage />
            </ProtectedRoute>
          } />

          <Route path="/trade/:tradeId" element={
            <ProtectedRoute>
              <ViewOffers />
            </ProtectedRoute>
          } />

          <Route path="/trade-history" element={
            <ProtectedRoute>
              <TradeHistory />
            </ProtectedRoute>
          } />

          <Route path="/admin/menu" element={
            <AdminProtectedRoute>
              <AdminMenu />
            </AdminProtectedRoute>
          } />
          
          <Route path="/admin/add-product" element={
            <AdminProtectedRoute>
              <AdminAddProduct />
            </AdminProtectedRoute>
          } />

          <Route path="/admin/products" element={
            <AdminProtectedRoute>
              <AdminProducts />
            </AdminProtectedRoute>
          } />

          <Route path="/admin/product/:id" element={
            <AdminProtectedRoute>
              <AdminProductView />
            </AdminProtectedRoute>
          } />

          <Route path="/admin/orders" element={
            <AdminProtectedRoute>
              <AdminOrders />
            </AdminProtectedRoute>
          } />

          <Route path="/admin/selfcollect" element={
            <AdminProtectedRoute>
              <AdminSelfCollect />
            </AdminProtectedRoute>
          } />

          <Route path="/admin/shipments" element={
            <AdminProtectedRoute>
              <AdminShipments />
            </AdminProtectedRoute>
          } />

          <Route path="/admin/inventory-lookup" element={
            <AdminProtectedRoute>
              <AdminInventoryLookup />
            </AdminProtectedRoute>
          } />

          <Route path="/reset-password-verify" element={<VerifyResetPasswordPage />} />
          <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/shipping-and-delivery" element={<ShippingAndDelivery />} />
          <Route path="/shipping-payment" element={<ShippingPaymentPage />} /> {/* Dynamic route for order details */}
          <Route path="/kuji-payment" element={<KujiPaymentPage />} />
          <Route path="/draw" element={<DrawPage />} />
          <Route path="/results" element={<ResultsPage />} />
          <Route path="/test-audio" element={<TestAudio />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/admin/login" element={<AdminLogin />} />
        </Routes>
      </Router>
    </WebSocketProvider>
  );
}

export default App;