// PaymentForm.js
import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Make sure you've installed react-router-dom
import './ShippingPaymentForm.css';
import NavBar from './NavBar';
import LoadingSpinner from './LoadingSpinner'; // Ensure you have this component created

function ShippingPaymentForm() {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate(); // Hook for programmatic navigation

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
  const shipmentId = useSelector((state) => state.payment.shipmentId);
  const shipmentItems = useSelector((state) => state.payment.shipmentItems);
  const shippingBreakdown = useSelector((state) => state.payment.shippingBreakdown);

  const [isLoading, setIsLoading] = useState(false); // New loading state
  const [isFormLoading, setIsFormLoading] = useState(true); // New loading state
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentRequestAvailable, setPaymentRequestAvailable] = useState(false);

  const totalItems = shipmentItems?.reduce((sum, item) => sum + item.quantity, 0) || 0;
  const shippingFee = shippingBreakdown.shippingFee*100;

  console.log(shipmentItems);

  useEffect(() => {
    window.scroll(0, 0);
    const initializePaymentRequest = async () => {
      if (stripe) {

        const pr = stripe.paymentRequest({
          country: 'SG',
          currency: 'sgd',
          total: {
            label: 'Total',
            amount: shippingFee, // Ensure totalCost is in cents
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });
    
        pr.canMakePayment().then(result => {
          if (result && result.applePay) {
            setPaymentRequest(pr);
            setPaymentRequestAvailable(true);
          }
        }).catch(error => {
          console.error("Payment request error: ", error);
        });
    
        pr.on('token', async (e) => {
          // Handle the submission of payment details
          const { token, error } = e;
    
          // Process the payment using the token.id...
          // Similar to handling confirmation in your existing handleSubmit
    
          if (error) {
            console.log(`Payment failed: ${error.message}`);
            e.complete('fail');
          } else {
            console.log('Payment succeeded!');
            // // Navigate or update state as needed
            // const response = await fetch(`${backendUrl}/api/order/update-shipment/${shipmentId}`, {
            //   method: 'POST',
            //   headers: {
            //     'Content-Type': 'application/json',
            //   },
            //   credentials: 'include'
            // });
        
            // if (!response.ok) {
            //   throw new Error('Network response was not ok');
            // }

            e.complete('success');
            navigate(`/shipments`, { state: { shipmentSuccess: true } });
        }
        });
      }
    }

    initializePaymentRequest();

  }, [stripe, navigate, backendUrl, shipmentId]);
  

  useEffect(() => {
    if (stripe) {
      // Assume if stripe is loaded, elements might be ready soon after
      // This is a simplification and might not accurately reflect the loaded state of the PaymentElement
      const timer = setTimeout(() => setIsFormLoading(false), 1000); // Wait for 1 second before assuming elements are loaded
      return () => clearTimeout(timer);
    }
  }, [stripe]);

  const checkShipmentStatus = async (shipmentId) => {
    try {
      const response = await fetch(`${backendUrl}/api/shipment/processing-status/${shipmentId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      });
      const data = await response.json();

      if (data.status === 'completed') {
        // Redirect to shipments page
        navigate('/shipments', { state: { shipmentSuccess: true } });
      } else if (data.status === 'failed') {
        // Handle error
        console.error('Shipment processing failed:', data.error);
        setIsLoading(false);
      } else {
        // Still processing, check again in 2 seconds
        setTimeout(() => checkShipmentStatus(shipmentId), 2000);
      }
    } catch (error) {
      console.error('Error checking shipment status:', error);
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      setIsLoading(false);
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/shipments`,
      },
      redirect: 'if_required',
    });

    if (result.error) {
      console.log(result.error.message);
      setIsLoading(false);
    } else {
      if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        // Start checking shipment status
        checkShipmentStatus(shipmentId);
      }
    }
  };

  return (
    <div>
        <NavBar />
        {isLoading && (
            <div className="spinner-overlay">
              <LoadingSpinner />
              <p style={{color: "white"}}>Processing your payment...</p>
              <p style={{color: "white", textAlign: "center", fontSize: "14px"}}>This might take awhile, please do not close this page.</p>
            </div>
        )}
        <div className="gradient-background">
            <div className="shipping-payment-container">
                <div className="shipping-payment-info">
                  {shippingBreakdown && (
                    <>
                      <p className="p-text"><strong>Big Prizes:</strong> {shippingBreakdown.bigPrizes}</p>
                      <p className="p-text"><strong>Small Prizes:</strong> {shippingBreakdown.smallPrizes}</p>
                      <p className="shipping-cost p-text">
                        <strong>Shipping Cost:</strong> ${(shippingFee / 100).toFixed(2)}
                      </p>
                    </>
                  )}
                </div>
                <div className="shipping-payment-form">
                  {/* {paymentRequestAvailable && (
                    <PaymentRequestButtonElement options={{ paymentRequest }} />
                  )} */}
                  <form className="shipping-stripe-payment-element-form" onSubmit={handleSubmit}>
                    <PaymentElement />
                    <button type="submit" className="shipping-pay-button" disabled={!stripe || isLoading}>
                      {isLoading ? 'Processing...' : 'Pay'}
                    </button>
                  </form>
                </div>
            </div>
        </div>
    </div>
  );
}

export default ShippingPaymentForm;