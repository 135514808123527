import React, { useState } from 'react';
import NavBar from './NavBar';
import logo from './logo.png'; // Import your logo
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';

function VerifyEmailPage() {
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage(''); // Clear any previous error messages
    
    if (!isValidEmail(email)) {
        console.log('Invalid email');
        setIsLoading(false);
        return;
    }
    
    try {
        const emailExists = await checkEmailExists(email);
        if (emailExists) {
            setIsLoading(false);
            setEmailError('An account already exists with this email.');
            return;
        }

        const response = await fetch(`${backendUrl}/api/verification/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
            credentials: 'include'
        });

        const data = await response.json();
        if (response.ok) {
          setIsLoading(false);
          navigate('/verify', { state: { email } });
        } else {
          setIsLoading(false);
          setErrorMessage(
            <div style={styles.errorContainer}>
              <div style={styles.errorMessage}>
                Unable to send verification code.
              </div>
              <div style={styles.errorHelp}>
                Please visit <a href="https://www.playsurprize.com/contact-us" target="_blank" rel="noopener noreferrer" style={styles.errorLink}>our support page</a> for assistance.
              </div>
            </div>
          );
        }
    } catch (error) {
        setIsLoading(false);
        setErrorMessage(
          <div style={styles.errorContainer}>
            <div style={styles.errorMessage}>
              Unable to send verification code.
            </div>
            <div style={styles.errorHelp}>
              Please visit <a href="https://www.playsurprize.com/contact-us" target="_blank" rel="noopener noreferrer" style={styles.errorLink}>our support page</a> for assistance.
            </div>
          </div>
        );
    }
  };

  const checkEmailExists = async (email) => {
    try {
      const response = await fetch(`${backendUrl}/api/users/check-email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
        credentials: 'include'
      });

      const data = await response.json();
      return !response.ok;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };

  const isValidEmail = email => {
    // Simple email validation pattern
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const buttonStyle = isValidEmail(email) ? styles.button : { ...styles.button, ...styles.buttonDisabled };

  return (
    <div>
      <NavBar />
      <div className="gradient-background">
        <div style={styles.logoContainer}>
            <img src={logo} alt="Logo" style={styles.logo} />
        </div>
        <div style={styles.container}>
            <form onSubmit={handleEmailSubmit} style={styles.form}>
            <h2 style={styles.h2Text}>Continue with Email</h2>
            <div style={styles.formContent}>
                <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={styles.input}
                />
                {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
                <p style={styles.infoText}>
                  You will receive a verification code in your email. 
                  (Please check your spam/junk/all emails folder too!)
                </p>
                {isLoading ? (
                  <LoadingSpinner />
                ) : errorMessage ? (
                  errorMessage
                ) : (
                  <button 
                    type="submit" 
                    style={buttonStyle}
                    disabled={!isValidEmail(email)}
                  >
                    Continue
                  </button>
                )}
            </div>
            </form>
        </div>
      </div>
    </div>
  );
}

// Use the same styles from LoginPage or adjust as needed
const styles = {
    formContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '50px'
      },
      logo: {
        height: '100px', // Adjust the size as needed
      },
      input: {
        width: '80%', // 100% minus padding
        padding: '15px',
        marginTop: '5px',
        borderRadius: '4px',
        border: '1px solid #ddd',
        textAlign: 'center', // Attempt to center align the text
        // Additional styling for the placeholder
        '::placeholder': {
            textAlign: 'center', // This may not be supported in all browsers
        },
        fontSize: '15px'
      },
      button: {
        width: '60%', // Adjust the width as needed
        padding: '15px',
        marginTop: '30px',
        backgroundColor: '#4CAF50', // Base color for the button
        color: 'white',
        border: '1px solid green',
        borderRadius: '4px',
        cursor: 'pointer',
        boxShadow: '0 6px #2e7d32', // Shadow effect for 3D look
        fontSize: '15px'
      },
      buttonDisabled: {
        backgroundColor: '#A9A9A9', // Dark gray background
        color: '#D3D3D3', // Light gray text
        cursor: 'not-allowed', // Cursor to indicate the button is disabled
        boxShadow: 'none', // Remove shadow for a flat appearance
        border: '2px solid #D3D3D3', // Adding a border, adjust color as needed
      },
      infoText: {
        textAlign: 'center',
        color: '#666', // You can change the color as needed
        fontSize: '15px',
        width: '80%'
      },
      h2Text: {
        textAlign: 'center', // Center align text for headings
      },
      h4Text: {
        textAlign: 'center',
        marginTop: '50px',
        marginBottom: '10px'
      },
      errorContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
        marginTop: '20px',
        width: '100%',
      },
      errorMessage: {
        color: '#dc3545',
        fontWeight: 'bold',
        textAlign: 'center',
      },
      errorHelp: {
        color: '#dc3545',
        textAlign: 'center',
        fontSize: '14px',
      },
      errorLink: {
        color: '#dc3545',
        textDecoration: 'underline',
        fontWeight: 'bold',
      },
};

export default VerifyEmailPage;