import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import NavBar from './NavBar'; // Assuming you have a NavBar component
import Footer from './Footer'; // Assuming you have a Footer component
import './UserTokensPage.css'; // Custom CSS for styling
import tokenIcon from './surprize_token.png';
import LoadingSpinner from './LoadingSpinner'; // Import your spinner

function UserTokensPage() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [activeTokens, setActiveTokens] = useState([]);
  const [expiredTokens, setExpiredTokens] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const token = localStorage.getItem('token'); // Assuming the auth token is stored in localStorage
  const navigate = useNavigate(); // Use navigate for redirection

  useEffect(() => {
    fetchUserTokens(); // Fetch tokens if no verification needed
  }, []);

  const fetchUserTokens = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/token/user-tokens`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setActiveTokens(data.activeTokens || []);
        setExpiredTokens(data.expiredTokens || []);
      } else {
        console.error('Failed to fetch user tokens');
      }
    } catch (error) {
      console.error('Error fetching user tokens:', error);
    }
    setIsLoading(false); // End loading once data is fetched
  };

  const formatDateToSingaporeTime = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'Asia/Singapore',
      timeZoneName: 'short',
    };
    return new Date(dateString).toLocaleString('en-SG', options);
  };

  const handlePurchaseTokensClick = () => {
    navigate('/purchase-tokens'); // Navigate to the purchase tokens page
  };

  return (
    <div>
      <NavBar />
      <div className="gradient-background">
        <div className="purchase-tokens-button-container">
          <button className="purchase-tokens-button" onClick={handlePurchaseTokensClick}>
            Purchase Tokens
          </button>
        </div>

        {/* Show loading spinner if loading */}
        {isLoading ? (
          <LoadingSpinner /> // Use the LoadingSpinner component
        ) : (
          <div className="user-tokens-container">
            <h1 className="user-tokens-title">Your Tokens</h1>
            <div className="user-tokens-section">
              <h2>Active Tokens</h2>
              {activeTokens.length === 0 ? (
                <p>You have no active tokens.</p>
              ) : (
                <ul>
                  {activeTokens.map(token => (
                    <li key={token._id} className="active-token-list-item">
                      <img src={tokenIcon} alt="Token" className="user-token-icon" />
                      <span>{token.amountLeft}</span>&nbsp;-&nbsp;
                      <span>Expires on: {formatDateToSingaporeTime(token.expiryDate)}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="user-tokens-section">
              <h2>Expired Tokens</h2>
              {expiredTokens.length === 0 ? (
                <p>You have no expired tokens.</p>
              ) : (
                <ul>
                  {expiredTokens.map(token => (
                    <li key={token._id} className="expired-token-list-item">
                      <img src={tokenIcon} alt="Token" className="user-token-icon" />
                      <span>{token.amountLeft}</span>&nbsp;-&nbsp;
                      <span>Expired on: {formatDateToSingaporeTime(token.expiryDate)}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default UserTokensPage;