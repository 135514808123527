import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './InventorySelectionModal.css';
import Fuse from 'fuse.js';

function InventorySelectionModal({ onClose, onConfirm }) {
  const [inventory, setInventory] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredInventory, setFilteredInventory] = useState({});
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const username = useSelector((state) => state.user.username);

  const sortPrizes = (prizes) => {
    return prizes.sort((a, b) => {
      if (a.prizeName === 'Last Prize') return 1;
      if (b.prizeName === 'Last Prize') return -1;
      return a.prizeName.localeCompare(b.prizeName);
    });
  };

  useEffect(() => {
    const fetchInventory = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${backendUrl}/api/inventory/user/${username}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setInventory(data.inventory);
        } else {
          console.error('Failed to fetch inventory');
        }
      } catch (error) {
        console.error('Error fetching inventory:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInventory();
  }, [backendUrl, username]);

  useEffect(() => {
    const handleSearch = () => {
      if (!searchQuery.trim()) {
        setFilteredInventory(inventory);
        return;
      }

      const fuse = new Fuse(Object.entries(inventory), {
        keys: ['0'], // Search through the set names (first element of each entry)
        includeScore: false,
        threshold: 0.4,
      });

      const result = fuse.search(searchQuery);
      const searchedInventory = Object.fromEntries(
        result.map(({ item }) => item)
      );
      setFilteredInventory(searchedInventory);
    };

    handleSearch();
  }, [searchQuery, inventory]);

  const handleQuantityChange = (batch, setName, inventoryId, prizeId, productId, prizeName, change) => {
    if (batch.quantity === 0) return;

    const existingItemIndex = selectedItems.findIndex(
      (item) =>
        item.batchId === batch.batchId &&
        item.setName === setName &&
        item.inventoryId === inventoryId &&
        item.prizeId === prizeId &&
        item.prizeName === prizeName
    );

    if (existingItemIndex > -1) {
      const updatedItems = [...selectedItems];
      const newQuantity = Math.min(
        Math.max(0, updatedItems[existingItemIndex].quantity + change), // Ensure non-negative quantity
        batch.quantity // Ensure quantity does not exceed available stock
      );
      if (newQuantity > 0) {
        updatedItems[existingItemIndex].quantity = newQuantity;
      } else {
        updatedItems.splice(existingItemIndex, 1); // Remove if quantity is 0
      }
      setSelectedItems(updatedItems);
    } else if (change > 0) {
      const newQuantity = Math.min(change, batch.quantity); // Ensure new quantity doesn't exceed available stock
      setSelectedItems((prev) => [
        ...prev,
        {
          inventoryId, // Reference to the inventory document
          batchId: batch.batchId, // Reference to the batch within the inventory
          quantity: newQuantity,
          prizeId, // Reference to the prize document
          setName, // Set name (to identify product sets)
          productId,
          prizeName
        },
      ]);
    }
  };

  const handleConfirm = () => {
    onConfirm(selectedItems);
    onClose();
  };

  const isTradeEligible = (batch) => {
    if (!batch.shipByDate) return true; // Preorder items are always eligible
    
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 2);
    tomorrow.setHours(0, 0, 0, 0); // Set to start of day
    
    return new Date(batch.shipByDate) > tomorrow;
  };

  if (isLoading) {
    return (
      <div className="inventory-selection-modal popup-overlay">
        <div className="inventory-selection-modal-content">
          <h4>Loading Inventory...</h4>
        </div>
      </div>
    );
  }

  return (
    <div className="inventory-selection-modal popup-overlay">
      <div className="inventory-selection-modal-content">
        <h4>Select Items to Offer</h4>
        <div className="inventory-selection-search">
          <input
            type="text"
            placeholder="Search by Set Name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="inventory-selection-search-input"
          />
        </div>
        <div className="inventory-selection-container">
          {Object.entries(filteredInventory).map(([setName, prizes]) => (
            <div key={setName} className="inventory-selection-set">
              <h5>{setName}</h5>
              <div className="inventory-selection-prizes">
                {sortPrizes(prizes).map((prize) => (
                  <div key={prize.id} className="inventory-selection-prize-card">
                    <span className='inventory-selection-prize-name'>{prize.prizeName}</span>
                    <img
                      src={`${backendUrl}/api/images/${prize.image}`}
                      alt={prize.prizeName}
                      className="inventory-selection-prize-image"
                    />
                    {prize.batches.map((batch) => (
                      <div key={batch._id} className="inventory-selection-batch-details">
                        <span>
                          {batch.shipByDate 
                            ? `Instock: ${batch.quantity}` 
                            : `Pre-order: ${batch.quantity}`
                          }
                        </span>
                        {batch.inTrade > 0 && <span>In Trade: {batch.inTrade}</span>}
                        {batch.shipByDate && (
                          <span className="inventory-selection-ship-by">
                            Ship By: {new Date(batch.shipByDate).toLocaleDateString()}
                          </span>
                        )}
                        {batch.shipByDate && !isTradeEligible(batch) && (
                          <span className="inventory-selection-disabled-message">
                            Cannot trade items due within 2 days
                          </span>
                        )}
                        <div className="inventory-selection-quantity-controls">
                          <button
                            className="inventory-selection-quantity-btn"
                            onClick={() =>
                              handleQuantityChange(
                                batch,
                                setName,
                                prize.id,
                                prize.prizeId._id,
                                prize.product._id,
                                prize.prizeName,
                                -1
                              )
                            }
                            disabled={!isTradeEligible(batch)}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            min="0"
                            max={batch.quantity}
                            value={
                              selectedItems.find(
                                (item) =>
                                  item.batchId === batch.batchId &&
                                  item.setName === setName &&
                                  item.inventoryId === prize.id &&
                                  item.prizeId === prize.prizeId._id &&
                                  item.prizeName === prize.prizeName
                              )?.quantity || 0
                            }
                            onChange={(e) =>
                              handleQuantityChange(
                                batch,
                                setName,
                                prize.id,
                                prize.prizeId,
                                prize.product._id,
                                parseInt(e.target.value, 10) -
                                  (selectedItems.find(
                                    (item) =>
                                      item.batchId === batch.batchId &&
                                      item.setName === setName &&
                                      item.inventoryId === prize.id &&
                                      item.prizeId === prize.prizeId._id &&
                                      item.prizeName === prize.prizeName
                                  )?.quantity || 0)
                              )
                            }
                            className="inventory-selection-quantity-input"
                            disabled={!isTradeEligible(batch)}
                          />
                          <button
                            className="inventory-selection-quantity-btn"
                            onClick={() =>
                              handleQuantityChange(
                                batch,
                                setName,
                                prize.id,
                                prize.prizeId._id,
                                prize.product._id,
                                prize.prizeName,
                                1
                              )
                            }
                            disabled={!isTradeEligible(batch)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="inventory-selection-modal-buttons">
          <button onClick={onClose} className="inventory-selection-cancel-button">
            Cancel
          </button>
          <button onClick={handleConfirm} className="inventory-selection-confirm-button">
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default InventorySelectionModal;