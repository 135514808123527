import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedProduct, clearSelectedProduct } from './redux/slices/productSlice';
import { clearSelectedPrizes } from './redux/slices/prizesSlice';
import { resetPayment } from './redux/slices/paymentSlice';
import './ResultsPage.css';
import NavBar from './NavBar';
import LoadingSpinner from './LoadingSpinner';
import ImagePopup from './ImagePopup';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function ResultsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedPrizes = useSelector((state) => state.prizes.selectedPrizes);
  const [prizeDetails, setPrizeDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchPrizes = async () => {
      const orderState = JSON.parse(sessionStorage.getItem('orderState'));
      
      if (orderState && orderState.selectedProduct) {
        try {
          setIsLoading(true);
          
          // Count occurrences of each prize
          const prizeCount = selectedPrizes.reduce((acc, prize) => {
            acc[prize] = (acc[prize] || 0) + 1;
            return acc;
          }, {});

          const prizeResponse = await fetch(`${backendUrl}/api/prize/fetch-prizes`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              productId: orderState.selectedProduct.productId,
              selectedPrizes: selectedPrizes, // Send raw prize ranks (e.g., ['A', 'B', 'LAST'])
            }),
            credentials: 'include'
          });

          if (prizeResponse.ok) {
            const { prizes } = await prizeResponse.json();
            
            // Format prize details with quantities
            const formattedPrizes = prizes.map(prize => {
              // Extract rank from prize name
              const rank = prize.name === 'Last Prize' ? 'LAST' : prize.name.split(' ')[1];
              return {
                name: prize.name,
                description: prize.description,
                image: `${backendUrl}/api/images/${prize.image}`,
                rank: rank,
                quantity: prizeCount[rank] || 0
              };
            });
            setPrizeDetails(formattedPrizes);
          }
        } catch (error) {
          console.error('Error fetching prize details:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchPrizes();
  }, [selectedPrizes]);

  const onTryAgain = () => {
    // Store the current product info in sessionStorage before refresh
    const orderState = JSON.parse(sessionStorage.getItem('orderState'));
    const productToRetry = orderState.selectedProduct;
    
    // Clear everything except the product we want to retry
    dispatch(clearSelectedPrizes());
    dispatch(resetPayment());
    sessionStorage.clear();
    
    // Store just the product info and retry flag
    sessionStorage.setItem('retryProduct', JSON.stringify(productToRetry));
    
    // Force refresh and navigate to products page
    window.location.href = '/ichiban-kuji-products';
  };

  const onBrowse = () => {
    dispatch(clearSelectedProduct());
    navigate('/ichiban-kuji-products');
  };

  const handleImageClick = (prize) => {
    setSelectedImage({
      src: prize.image,
      prize: {
        letter: prize.rank,
        description: prize.description
      }
    });
  };

  useEffect(() => {
    return () => {
      sessionStorage.clear();
      dispatch(clearSelectedPrizes());
      dispatch(resetPayment());
    };
  }, [dispatch]);

  if (isLoading) {
    return (
      <div>
        <NavBar />
        <div className="gradient-background">
          <div className="results-page-loading">
            <LoadingSpinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <NavBar />
      <div className="gradient-background">
        <div className="results-page-popup">
          <h2 className="results-page-text">YOU WON</h2>
          {prizeDetails.map((prize, index) => (
            <div className="results-page-prize-card" key={index}>
              <h3 className="results-page-prize-name">{prize.name}</h3>
              <p className="results-page-prize-quantity">(Qty: {prize.quantity})</p>
              <img 
                src={prize.image} 
                alt={prize.name} 
                className="results-page-prize-image"
                onClick={() => handleImageClick(prize)}
              />
              <div className="results-page-prize-info">
                <p className="results-page-prize-description">{prize.description}</p>
              </div>
            </div>
          ))}
          <button className="results-page-try-button" onClick={onTryAgain}>Try Again</button>
          <button className="results-page-browse-button" onClick={onBrowse}>Browse Others</button>
        </div>
      </div>
      {selectedImage && (
        <ImagePopup 
          src={selectedImage.src}
          prize={selectedImage.prize}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </div>
  );
}

export default ResultsPage;