import React from 'react';
import './TryAgainPopup.css';

function TryAgainPopup({ onClose }) {
    return (
      <div className="tryagain-popup popup-overlay">
        <div className="tryagain-popup-content">
          <p className="tryagain-text">Please Try Again.</p>
          <button className='tryagain-close-button' onClick={onClose}>Close</button>
        </div>
      </div>
    );
}  

export default TryAgainPopup;