import React, { useEffect, useState } from 'react';
import './FavoriteSuccessPopup.css';

function FavoriteSuccessPopup({ message, type = 'favorite', onClose }) {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsClosing(true);
      setTimeout(onClose, 300);
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={`favorite-success-popup ${type} ${isClosing ? 'closing' : ''}`}>
      <div className="favorite-success-content">
        <span>{message}</span>
      </div>
    </div>
  );
}

export default FavoriteSuccessPopup; 