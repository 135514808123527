import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ViewOffers.css';
import LoadingSpinner from './LoadingSpinner';
import NavBar from './NavBar';
import ImagePopup from './ImagePopup';

function ViewOffers() {
  const { tradeId } = useParams();
  const [offers, setOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPrize, setSelectedPrize] = useState(null); // State to track selected prize
  const [confirmationAction, setConfirmationAction] = useState(null); // Track confirmation action
  const [confirmationOfferId, setConfirmationOfferId] = useState(null); // Track offer ID for confirmation
  const [isProcessing, setIsProcessing] = useState(false); // Track API call state
  const [confirmationMessage, setConfirmationMessage] = useState(null); // Success message
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOffers = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${backendUrl}/api/trade/${tradeId}/offers`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        if (response.ok) {
          const data = await response.json();
          setOffers(data);
        } else {
          console.error('Failed to fetch offers');
        }
      } catch (error) {
        console.error('Error fetching offers:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOffers();
  }, [backendUrl, tradeId]);

  const handleAcceptOffer = async (offerId) => {
    try {
      setIsProcessing(true); // Start processing
      const response = await fetch(`${backendUrl}/api/trade/${tradeId}/accept-offer/${offerId}`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      if (response.ok) {
        setConfirmationMessage('Offer accepted successfully');
        setTimeout(() => navigate('/manage-trades'), 2000); // Navigate after success
      } else {
        setConfirmationMessage('Failed to accept offer');
      }
    } catch (error) {
      console.error('Error accepting offer:', error);
      setConfirmationMessage('An error occurred while accepting the offer');
    } finally {
      setIsProcessing(false); // End processing
    }
  };

  const handleRejectOffer = async (offerId) => {
    try {
      setIsProcessing(true); // Start processing
      const response = await fetch(`${backendUrl}/api/trade/${tradeId}/reject-offer/${offerId}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      if (response.ok) {
        setOffers((prevOffers) => prevOffers.filter((offer) => offer._id !== offerId));
        setConfirmationMessage('Offer rejected successfully');
      } else {
        setConfirmationMessage('Failed to reject offer');
      }
    } catch (error) {
      console.error('Error rejecting offer:', error);
      setConfirmationMessage('An error occurred while rejecting the offer');
    } finally {
      setIsProcessing(false); // End processing
    }
  };

  const showConfirmation = (action, offerId) => {
    setConfirmationAction(action);
    setConfirmationOfferId(offerId);
    setConfirmationMessage(null); // Reset message
  };

  const handleConfirmAction = () => {
    if (confirmationAction === 'accept') {
      handleAcceptOffer(confirmationOfferId);
    } else if (confirmationAction === 'reject') {
      handleRejectOffer(confirmationOfferId);
    }
  };

  const handleImageClick = (prize) => {
    const getPrizeLetter = (name) => {
      if (name === 'Last Prize') return 'LAST';
      return name.replace('Prize ', ''); // Extract letter
    };

    setSelectedPrize({
      letter: getPrizeLetter(prize.name),
      description: prize.description,
      src: `${backendUrl}/api/images/${prize.image}`,
    });
  };

  const handleClosePopup = () => {
    setSelectedPrize(null); // Close the popup
  };

  return (
    <div>
      <NavBar />
      <div className="gradient-background">
        <div className="view-offers">
          <h1>Offers for Your Trade</h1>
          {isLoading ? (
            <LoadingSpinner />
          ) : offers.length > 0 ? (
            offers.map((offer) => (
              <div key={offer._id} className="offer-card">
                <div className="offer-details">
                  <h4 className="offer-by">
                    Offer from:
                    {offer.fromUser.profilePicture ? (
                      <img
                        src={`${backendUrl}/api/images/${offer.fromUser.profilePicture}`}
                        alt={`${offer.fromUser.username}'s profile`}
                        className="offer-user-profile-picture"
                      />
                    ) : null}
                    <span className="offer-username">{offer.fromUser.username}</span>
                  </h4>
                  <div className="offer-prizes">
                    {offer.offeredPrizes.map((prize, index) => (
                      <div key={index} className="offer-prize-card">
                        <h4 className="offer-prize-card-name">{prize.prizeId.name}</h4>
                        <span className="offer-prize-card-description">{prize.prizeId.description}</span>
                        <img
                          src={`${backendUrl}/api/images/${prize.prizeId.image}`}
                          alt={prize.prizeId.name}
                          className="offer-prize-image"
                          onClick={() => handleImageClick(prize.prizeId)} // Handle image click
                        />
                        <span className="offer-prize-card-qty">Qty: {prize.quantity}</span>
                        <span className="offer-prize-card-set-name">({prize.setName})</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="offer-actions">
                  <button
                    onClick={() => showConfirmation('reject', offer._id)}
                    className="reject-offer-button"
                  >
                    Reject
                  </button>
                  <button
                    onClick={() => showConfirmation('accept', offer._id)}
                    className="accept-offer-button"
                  >
                    Accept
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No offers available for this trade.</p>
          )}
        </div>
      </div>
      {selectedPrize && (
        <ImagePopup
          prize={selectedPrize}
          src={selectedPrize.src}
          onClose={handleClosePopup} // Close the popup
        />
      )}
      {confirmationAction && (
        <div className="offer-confirmation-popup popup-overlay">
          <div className="offer-confirmation-popup-content">
            {isProcessing ? (
              <LoadingSpinner />
            ) : confirmationMessage ? (
              <p>{confirmationMessage}</p>
            ) : (
              <>
                <p>
                  Are you sure you want to {confirmationAction} this offer?
                </p>
                <div className="offer-confirmation-popup-actions">
                  <button
                    onClick={() => setConfirmationAction(null)}
                    className="offer-cancel-action-button"
                  >
                    No
                  </button>
                  <button
                    onClick={handleConfirmAction}
                    className="offer-confirm-action-button"
                  >
                    Yes
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewOffers;