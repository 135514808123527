import React from 'react';
import './ModalPopup.css';

function ModalPopup({ 
  type, 
  message, 
  details, // Optional array for additional details
  onConfirm, // Callback for confirmation actions
  onClose // Callback for closing the modal
}) {

  return (
    <div className="modal-popup popup-overlay">
      <div className={`modal-popup-content ${type}`}>
        <p>{message}</p>
        
        {/* Render additional details if provided */}
        {details && details.length > 0 && (
          <ul className="modal-popup-details">
            {details.map((item, index) => (
              <li key={index}>
                {item.setName} - {item.prizeName} (Qty: {item.quantity})
              </li>
            ))}
          </ul>
        )}
        
        {/* Render buttons based on the modal type */}
        <div className="modal-popup-actions">
          <button className="modal-popup-close-button" onClick={onClose}>
            {onConfirm ? 'Cancel' : 'Close'}
          </button>
          {onConfirm && (
            <button className="modal-popup-confirm-button" onClick={onConfirm}>
              {
                type === "view-offer"
                ? "Withdraw Offer"
                : "Confirm"
              }
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalPopup;