import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NavBar from './NavBar';
import LoadingSpinner from './LoadingSpinner';
import './ShipmentsPage.css';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';

function ShipmentsPage() {
  const location = useLocation();
  const [shipments, setShipments] = useState([]);
  const [selfCollections, setSelfCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(
    location.state?.activeTab || 'shipments' // Use incoming state or default to 'shipments'
  );
  const username = useSelector((state) => state.user.username);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [trackingStatuses, setTrackingStatuses] = useState({});
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [selectedCollectionId, setSelectedCollectionId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch shipments
        const shipmentsResponse = await fetch(`${backendUrl}/api/shipment/user/${username}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        // Fetch self-collections
        const collectionsResponse = await fetch(`${backendUrl}/api/self-collection/user`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (shipmentsResponse.ok && collectionsResponse.ok) {
          const shipmentsData = await shipmentsResponse.json();
          const collectionsData = await collectionsResponse.json();
          setShipments(shipmentsData.shipments);
          setSelfCollections(collectionsData);
        } else {
          console.error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (username) {
      fetchData();
    }
  }, [username, backendUrl]);

  useEffect(() => {
    const fetchTrackingStatus = async (orderNumber) => {
      try {
        const response = await fetch(`${backendUrl}/api/shipment/tracking`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({ orderNumber })
        });

        if (response.ok) {
          const data = await response.json();
          setTrackingStatuses(prev => ({
            ...prev,
            [orderNumber]: data.status
          }));
        }
      } catch (error) {
        console.error('Error fetching tracking status:', error);
      }
    };

    // Fetch tracking status for each shipment that has an EasyParcel order number
    shipments.forEach(shipment => {
      if (shipment.easyparcelOrderId) {
        fetchTrackingStatus(shipment.easyparcelOrderId);
      }
    });
  }, [shipments, backendUrl]);

  const formatParcelContent = (inventoryItems) => {
    const parcelContentMap = {};

    inventoryItems.forEach((item) => {
      const { setName, prizeName, quantity } = item;
      if (!parcelContentMap[setName]) {
        parcelContentMap[setName] = [];
      }

      for (let i = 0; i < quantity; i++) {
        parcelContentMap[setName].push(prizeName);
      }
    });

    return Object.entries(parcelContentMap).map(([setName, prizes]) => {
      const prizeCounts = {};

      prizes.forEach((prize) => {
        const shorthand = prize === 'Last Prize' ? 'LAST' : prize.split(' ')[1]; // Extract shorthand
        prizeCounts[shorthand] = (prizeCounts[shorthand] || 0) + 1;
      });

      const formattedPrizes = Object.entries(prizeCounts)
        .map(([prize, count]) => `${count}${prize}`)
        .sort((a, b) => {
          const aIsLast = a.includes('LAST');
          const bIsLast = b.includes('LAST');
          
          // If either is LAST, handle separately
          if (aIsLast || bIsLast) {
            if (aIsLast) return 1;  // LAST goes to the end
            if (bIsLast) return -1; // LAST goes to the end
            return 0;
          }
          
          // For non-LAST prizes, extract the letter and sort alphabetically
          const letterA = a.replace(/^\d+/, ''); // Remove leading numbers
          const letterB = b.replace(/^\d+/, ''); // Remove leading numbers
          return letterA.localeCompare(letterB);
        });

      return (
        <div key={setName} className="parcel-content-line">
          <span>{setName}</span>: {formattedPrizes.join(', ')}
        </div>
      );
    });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'status-pending';
      case 'completed':
        return 'status-completed';
      case 'cancelled':
        return 'status-cancelled';
      default:
        return '';
    }
  };

  const getTrackingUrl = (awbNumber) => {
    return `https://app.easyparcel.com/sg/en/track/details/?awb=${awbNumber}&courier=TracX%20Logis`;
  };

  const handleCancelClick = (collectionId) => {
    setSelectedCollectionId(collectionId);
    setShowCancelConfirmation(true);
  };

  const handleConfirmCancel = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/self-collection/${selectedCollectionId}/cancel`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        // Update the local state to reflect the cancellation
        setSelfCollections(prev => 
          prev.map(collection => 
            collection._id === selectedCollectionId 
              ? { ...collection, status: 'cancelled' } 
              : collection
          )
        );
      } else {
        console.error('Failed to cancel self-collection');
      }
    } catch (error) {
      console.error('Error cancelling self-collection:', error);
    } finally {
      setShowCancelConfirmation(false);
      setSelectedCollectionId(null);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <NavBar />
      <div className="gradient-background">
        <div className="shipments-container">
          <div className="delivery-tabs">
            <div className="tab-section">
              <span
                className={`tab-text ${activeTab === 'shipments' ? 'active' : ''}`}
                onClick={() => setActiveTab('shipments')}
              >
                Shipments
              </span>
              <div className="vertical-divider"></div>
              <span
                className={`tab-text ${activeTab === 'collections' ? 'active' : ''}`}
                onClick={() => setActiveTab('collections')}
              >
                Self-Collections
              </span>
            </div>
          </div>

          {activeTab === 'shipments' ? (
            <>
              <h2 className="shipments-title">Shipments</h2>
              {shipments.length === 0 ? (
                <p className="no-shipments-message">No shipments found.</p>
              ) : (
                <div className="shipments-list">
                  {shipments.map((shipment) => (
                    <div key={shipment._id} className="shipment-page-card">
                      <span className="shipment-page-id">
                        <span>Tracking ID: </span>
                        {shipment.awbNumber ? (
                          <a 
                            href={getTrackingUrl(shipment.awbNumber)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="tracking-link"
                          >
                            {shipment.awbNumber}
                          </a>
                        ) : (
                          'Pending'
                        )}
                      </span>
                      <span className="shipment-page-status">
                        <strong>Status: </strong>
                        {trackingStatuses[shipment.easyparcelOrderId] || 'Pending'}
                      </span>
                      <p className="shipment-page-fee">
                        <strong>Shipping Fee:</strong> ${parseFloat(shipment.shippingFee).toFixed(2)}
                      </p>
                      <div className="shipment-page-content">
                        <strong>Parcel Content:</strong>
                        {formatParcelContent(shipment.inventoryItems)}
                      </div>
                      <p className="shipment-page-receiver">
                        <strong>Receiver:</strong> {shipment.receiverName} ({shipment.receiverContact})
                      </p>
                      <p className="shipment-page-address">
                        <strong>Address:</strong> {shipment.receiverUnit}, {shipment.receiverFullAddress}, {shipment.receiverPostcode}, {shipment.receiverCountry}
                      </p>
                      <p className="shipment-page-created-at">
                        <strong>Created At:</strong> {new Date(shipment.createdAt).toLocaleString()}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <>
              <h2 className="shipments-title">Self-Collections</h2>
              {selfCollections.length === 0 ? (
                <p className="no-shipments-message">No self-collections found.</p>
              ) : (
                <div className="shipments-list">
                  {selfCollections
                    .sort((a, b) => new Date(b.collectionDate) - new Date(a.collectionDate))
                    .map((collection) => (
                      <div key={collection._id} className="shipment-page-card">
                        <span className="collection-id">Collection ID: {collection.collectionId}</span>
                        <span className={`shipment-page-status ${getStatusColor(collection.status)}`}>
                          <strong>Status: </strong>
                          {collection.status.charAt(0).toUpperCase() + collection.status.slice(1)}
                        </span>
                        <div className="shipment-page-content">
                          <strong>Items for Collection:</strong>
                          {formatParcelContent(collection.inventoryItems)}
                        </div>
                        <p className="shipment-page-collection-date">
                          <strong>Collection Date:</strong> {new Date(collection.collectionDate).toLocaleString()}
                        </p>
                        {collection.collectedAt && (
                          <p className="shipment-page-collected-at">
                            <strong>Collected At:</strong> {new Date(collection.collectedAt).toLocaleString()}
                          </p>
                        )}
                        {collection.status === 'pending' && (
                          <button 
                            className="cancel-collection-button"
                            onClick={() => handleCancelClick(collection._id)}
                          >
                            Cancel
                          </button>
                        )}
                      </div>
                    ))}
                </div>
              )}
            </>
          )}
        </div>
        <Footer />
      </div>

      {/* Cancel Confirmation Popup */}
      {showCancelConfirmation && (
        <div className="cancel-confirmation-popup popup-overlay">
          <div className="cancel-confirmation-content">
            <h3>Cancel Self-Collection</h3>
            <p>Are you sure you want to cancel this self-collection?</p>
            <div className="cancel-confirmation-buttons">
              <button 
                className="cancel-button"
                onClick={() => {
                  setShowCancelConfirmation(false);
                  setSelectedCollectionId(null);
                }}
              >
                No, Keep
              </button>
              <button 
                className="confirm-cancel-button"
                onClick={handleConfirmCancel}
              >
                Yes, Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ShipmentsPage;