// src/redux/slices/notificationSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Async thunk to fetch notifications
export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(`${backendUrl}/api/notification`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      if (!response.ok) throw new Error('Failed to fetch notifications');
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const removeNotification = createAsyncThunk(
    'notifications/removeNotification',
    async (id, { rejectWithValue }) => {
      try {
        const response = await fetch(`${backendUrl}/api/notification/${id}/remove`, {
          method: 'PATCH',
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        if (!response.ok) throw new Error('Failed to remove notification');
        const data = await response.json();
        return data._id; // Return the removed notification's ID
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
);

const notificationSlice = createSlice({
    name: 'notifications',
    initialState: {
      notifications: [],
      unreadCount: 0,
      status: 'idle',
      error: null,
    },
    reducers: {
      markAsRead: (state, action) => {
        const id = action.payload;
        const notification = state.notifications.find((notif) => notif._id === id);
        if (notification && !notification.isRead) {
          notification.isRead = true;
          state.unreadCount -= 1;
        }
      },
      clearNotifications: (state) => {
        state.notifications = [];
        state.unreadCount = 0;
      },
      markAllAsRead: (state) => {
        state.notifications.forEach((notif) => (notif.isRead = true));
        state.unreadCount = 0;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchNotifications.fulfilled, (state, action) => {
          state.notifications = action.payload.filter((notif) => !notif.isRemoved); // Exclude removed notifications
          state.unreadCount = state.notifications.filter((notif) => !notif.isRead).length;
        })
        .addCase(removeNotification.fulfilled, (state, action) => {
          state.notifications = state.notifications.filter((notif) => notif._id !== action.payload); // Remove notification
        });
    },
  });
  
  export const { markAsRead, clearNotifications, markAllAsRead } = notificationSlice.actions;

export default notificationSlice.reducer;