import React from 'react';
import './LoginPromptPopup.css'; // Style your popup accordingly

const LoginPromptPopup = ({ onClose }) => {
    return (
      <div className="login-prompt-popup-overlay popup-overlay">
        <div className="login-prompt-popup-content">
          <h2>Please login first!</h2>
          <button onClick={onClose}>OK</button>
        </div>
      </div>
    );
};

export default LoginPromptPopup;